import cx from 'classnames';
import * as React from 'react';

import * as s from './PopupPlayer.m.less';
import { CropBoxOverlayProps } from './types';

// Shorthand for document
const d = document;

const CropBoxOverlayRenderer: React.ForwardRefRenderFunction<
  HTMLDivElement, CropBoxOverlayProps
> = function CropBoxOverlay({
  children,
  cropBox: _cropBox,
  onChange,
}, ref) {
  // Crop box element, placed over video
  const cropboxRef = React.useRef<HTMLDivElement>();

  // Reference to keep initial position of crop box area (left-top) in pixels
  // relative to screen
  const cropposRef = React.useRef<[number, number]>(null);

  // Reference to the crop area selector
  const centerCropRef = React.useRef<HTMLDivElement>(null);

  const [isCropping, setIsCropping] = React.useState(false);

  React.useImperativeHandle(ref, () => cropboxRef.current);

  // Combined data for current crop settings
  const [cropBox, setCropBox] = React.useReducer((box, a) => {
    if (Array.isArray(a)) {
      return {
        ...box,
        x: box.width / 2 + a[0],
        y: box.height / 2 + a[1],
      };
    }
    if (typeof a === 'object') {
      return a;
    }

    return box;
  }, _cropBox);

  React.useEffect(() => {
    setCropBox(_cropBox);
  }, [_cropBox]);

  React.useEffect(() => {
    if (!isCropping) {
      onChange(cropBox);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCropping]);

  // Handles crop box move
  const handleCropMove = React.useCallback((e: MouseEvent) => {
    if (cropposRef.current) {
      const { left, width, top, height } = cropboxRef.current.getBoundingClientRect();
      const { width: cwidth, height: cheight } = centerCropRef.current.getBoundingClientRect();

      const [x, y] = cropposRef.current;
      const posX = Math.max(0, Math.min(width - cwidth, (e.clientX - x - left))) / width;
      const posY = Math.max(0, Math.min(height - cheight, (e.clientY - y - top))) / height;
      setCropBox([posX, posY]);
    }
  }, []);

  // Handles mouse pointer release after moving crop box
  const handleCropEnd = React.useCallback(() => {
    cropposRef.current = null;
    cropboxRef.current.style.removeProperty('--box-transition');
    d.removeEventListener('mousemove', handleCropMove);
    d.removeEventListener('mouseup', handleCropEnd);
    d.removeEventListener('mouseleave', handleCropEnd);

    setIsCropping(false);
  }, [handleCropMove]);

  // Handles mouse pointer grab to change crop area
  const handleCropStart = React.useCallback((e: React.MouseEvent<HTMLButtonElement>) => {
    setIsCropping(true);
    cropboxRef.current.style.setProperty('--box-transition', '0');
    const { x, y } = centerCropRef.current.getBoundingClientRect();
    cropposRef.current = [e.clientX - x, e.clientY - y];
    d.addEventListener('mousemove', handleCropMove);
    d.addEventListener('mouseup', handleCropEnd);
    d.addEventListener('mouseleave', handleCropEnd);
  }, [handleCropEnd, handleCropMove]);

  // Crop box and mask styles
  const [centerStyle, maskStyle]: [React.CSSProperties, React.CSSProperties] = React.useMemo(() => {
    if (!cropBox) return [null, null];

    const pcfy = (...arr: number[]): string => arr.map((m) => `${m * 100}%`).join(' ');
    // Mask position is pretty tricky to calculate
    // When mask position is 50%, mask coordinate relative to which positioning
    // happens is also 50% (center)
    // When mask position is set to 0, mask coordinate sits on the left border
    // Same for 100% — coordinate sits on the right border
    // So basically here is scaling position from [mask_width, 1 - mask_width] to [0, 1]
    const sp = (size, pos): number => (size === 1 ? pos : (pos - 0.5) / (1 - size) + 0.5);

    return [
      {
        inset: pcfy(
          cropBox.y - (cropBox.height / 2),
          1 - cropBox.x - (cropBox.width / 2),
          1 - cropBox.y - (cropBox.height / 2),
          cropBox.x - (cropBox.width / 2),
        ),
      },
      {
        maskPosition: pcfy(sp(cropBox.width, cropBox.x), sp(cropBox.height, cropBox.y)),
        maskSize: pcfy(cropBox.width, cropBox.height),
      },
    ];
  }, [cropBox]);

  return (
    <>
      <div
        className={cx(s.cropbox, !cropBox && s.hidden)}
        ref={cropboxRef}
        style={maskStyle}
      />
      <div
        className={s.cropCenter}
        ref={centerCropRef}
        style={centerStyle}
      >
        {children}
        <button
          className={s.cropButton}
          type="button"
          onMouseDown={handleCropStart}
        >
          Move Crop Area
        </button>
      </div>
    </>

  );
};

export const CropBoxOverlay = React.forwardRef<
  HTMLDivElement,
  React.PropsWithChildren<CropBoxOverlayProps>
>(CropBoxOverlayRenderer);
