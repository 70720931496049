import { AspectRatio, SessionSettings, Settings } from './types';

export const ACCESS_TOKEN = 'jwt_access';
export const CMS_CONTEXT_KEY = 'cms_context';
export const RECENT_TEAM_KEY = 'recent_team';
export const REFRESH_TOKEN = 'jwt_refresh';
export const SOCIAL_TOKEN = 'social_access';
export const SUBSCRIPTION_KEY = 'subscription';
export const SYSTEM_USERS = ['1a4266b2-af46-40b5-b755-2d29952b9e9c'];

export const VOLUME_KEY = 'volume';
export const SUBTITLES_KEY = 'subtitles';

export const DEFAULT_SETTINGS: Settings = Object.freeze({
  collection_play_repeat: false,
  game_moment_scores: false,
  game_moment_status: false,
  recent_activity_visible: true,
  send_download_email: true,
  staff_edit_warning: true,
});
export const SESSION_SETTINGS: SessionSettings = ['staff_edit_warning'];

/**
 * Real aspect ratio value per name
 */
export const AspectRatioProportions: Record<AspectRatio, number> = {
  [AspectRatio.AR9x16]: 9 / 16,
  [AspectRatio.AR10x16]: 10 / 16,
  [AspectRatio.AR4x3]: 4 / 3,
  [AspectRatio.AR1x1]: 1,
};
