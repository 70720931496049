import { isEqual } from 'lodash';
import * as React from 'react';
import { useQuery, useQueryCache } from 'react-query';
import { HttpError } from 'weplayed-typescript-api';

import { AspectRatioProportions } from 'common/constants';
import { usePrevious } from 'common/hooks/usePrevious';
import { Overlay, Template, TemplateProperty } from 'common/utils/overlay';

import { TemplateOverlayDescription, UseTemplateOverlayType } from './types';

export const useTemplateOverlay: UseTemplateOverlayType = function useTemplateOverlay({
  data,
  defaults,
  enabled = true,
  path = null,
  props,
  ratio,
  target,
  user,
}) {
  // session storage settings key
  const settingsKey = `overlay/${user.pk}${path}`;

  // Fetch overlay templates
  const {
    data: descriptions,
    isLoading: templatesLoading,
    error: templatesError,
  } = useQuery<TemplateOverlayDescription[], HttpError>(
    ['overlay-templates'],
    async () => (await fetch(`${process.env.URL_PREFIX}overlays.json`)).json(),
    {
      enabled: enabled && target && ratio,
      refetchOnWindowFocus: false,
    },
  );

  // react-query key for template fetch
  const getKey = (p: string): string[] => ['overlay-templates', p];

  // Fetch overlay template for provided `path`
  const {
    data: template,
    isLoading: templateLoading,
    error: templateError,
  } = useQuery<Template, HttpError>(
    getKey(path),
    async () => (await fetch(path)).json(),
    {
      enabled: enabled && path,
      refetchOnWindowFocus: false,
    },
  );

  // Returning property
  const [$props, $setProps] = React.useState<TemplateProperty[]>([]);

  // overlay object
  const overlay = React.useMemo(
    () => {
      if (template) {
        const $overlay = new Overlay(AspectRatioProportions[ratio], template, data, defaults);
        try {
          // load settings from session storage
          const settings = sessionStorage.getItem(settingsKey);
          if (settings) {
            $overlay.props(JSON.parse(settings));
          }
        } catch (e) {
          //
        }
        // set initial properties
        if (props) {
          $overlay.props(props);
        }
        $setProps($overlay.props());
        return $overlay;
      }
      return null;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [ratio, template, data, defaults],
  );

  const prevProps = usePrevious(props);

  React.useEffect(() => {
    // since we have a kind of transforming props, we need to update props
    // only if the content of property value has changed, not the reference
    // so we need to compare props with isEqual
    if (overlay && !isEqual(props, prevProps)) {
      overlay.props(props);
      $setProps(overlay.props());
    }
  }, [props, prevProps, overlay]);

  // the list of templates applicable for current ratio and target
  const applicable = React.useMemo(
    () => descriptions?.filter(({ ratios, targets }) => (
      ratios.includes(ratio) && targets.includes(target)
    )),
    [ratio, target, descriptions],
  );

  const cache = useQueryCache();

  // templates storage with images and editable flag
  const [templates, setTemplates] = React.useState<TemplateOverlayDescription[]>(null);

  // fetch and store applicable templates
  React.useEffect(() => {
    (async (): Promise<void> => {
      if (data && defaults && ratio && applicable?.length) {
        const info = await Promise.all(applicable.map(({ path: p }) => (
          cache.fetchQuery(
            getKey(p),
            () => fetch(p).then((r) => r.json()),
          ).then((t) => {
            const $overlay = new Overlay(AspectRatioProportions[ratio], t, data, defaults);
            return {
              image: $overlay.svg(),
              editable: $overlay.props().filter(({ name, type }) => name && type).length !== 0,
            };
          })
        )));

        setTemplates(applicable.map((description, idx) => ({
          ...description,
          ...info[idx],
        })));
      } else {
        setTemplates(null);
      }
    })();
  }, [applicable, cache, data, defaults, ratio]);

  // method to persist settings for the current template, data, defaults
  const persist: ReturnType<UseTemplateOverlayType>['persist'] = React.useCallback(
    () => {
      if (overlay) {
        sessionStorage.setItem(settingsKey, JSON.stringify(overlay.persist()));
      }
      return Promise.resolve();
    },
    [overlay, settingsKey],
  );

  // method to render overlay into image
  const render: ReturnType<UseTemplateOverlayType>['render'] = React.useCallback(
    (height, type = 'png') => (type === 'png'
        ? overlay.png(height * AspectRatioProportions[ratio], height)
        : Promise.resolve(overlay.svg(true))
    ),
    [overlay, ratio],
  );

  return {
    error: templateError || templatesError,
    jsx: overlay?.jsx(),
    loading: templatesLoading || templateLoading,
    persist,
    props: $props,
    render,
    template,
    templates,
  };
};
