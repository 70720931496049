import { ReactComponent as Close } from '@mdi/svg/svg/close.svg';
import { ReactComponent as Menu } from '@mdi/svg/svg/dots-horizontal.svg';
import { ReactComponent as CopyLink } from '@mdi/svg/svg/link.svg';
import { ReactComponent as Edit } from '@mdi/svg/svg/pencil.svg';
import { ReactComponent as RepeatOff } from '@mdi/svg/svg/repeat-off.svg';
import { ReactComponent as Repeat } from '@mdi/svg/svg/repeat.svg';
import * as cx from 'classnames';
import * as React from 'react';
import { Modal } from 'react-bootstrap';
import { useMutation } from 'react-query';
import { What } from 'weplayed-typescript-api';

import { Button } from 'common/components/Button';
import { AspectRatioProportions } from 'common/constants';
import { useHandleQuery } from 'common/hooks/useHandleQuery';
import { useProfile } from 'common/hooks/useProfile';
import { useTemplateOverlay } from 'common/hooks/useTemplateOverlay';
import { AspectRatio, CropBox } from 'common/types';
import { EventContext, EVENTS, weplayedEvent } from 'common/utils/events';
import {
  createMomentEventDispatcher, MomentEventDispatcher,
} from 'common/utils/moments';
import { TemplateProperty } from 'common/utils/overlay';
import { format } from 'common/utils/strings';

import { Avatar } from 'cms/components/Avatar';
import { ItemPreview } from 'cms/components/ItemPreview';
import { LikeButton } from 'cms/components/LikeButton';
import { Slider } from 'cms/components/Slider';
import { SliderRefType } from 'cms/components/Slider/types';
import { useCollection } from 'cms/hooks/useCollection';
import { useMoment } from 'cms/hooks/useMoment';

import { CropBoxOverlay } from './CropBoxOverlay';
import * as messages from './messages';
import { MomentPlayer } from './MomentPlayer';
import { OverlayProps } from './OverlayProps';
import * as s from './PopupPlayer.m.less';
import { OverlaySettings, PlayerMode, PopupPlayerProps } from './types';

export const PopupPlayer: React.FC<PopupPlayerProps> = function PopupPlayer({
  collectionId,
  exportSettings,
  disabled,
  mode,
  momentId,
  onCancel,
  onClose,
  onCollectionLike,
  onCollectionLink,
  onCollectionMenu,
  onExportSettings,
  onDone,
  onMomentEdit,
  onMomentLike,
  onMomentLink,
  onMomentMenu,
  onMomentSave,
}) {
  const hasIds = Boolean(momentId || collectionId);
  // eslint-disable-next-line no-underscore-dangle
  const _ratio = (hasIds && exportSettings?.ratio) || null;
  // eslint-disable-next-line no-underscore-dangle
  const _cropBoxes = (hasIds && exportSettings?.boxes) || null;
  // eslint-disable-next-line no-underscore-dangle
  const _overlay = (hasIds && exportSettings?.overlay) || null;

  // Crop box element, placed over video
  const cropboxRef = React.useRef<HTMLDivElement>(null);

  const { profile, settings: { collection_play_repeat }, saveSettings } = useProfile();

  const templateDefaults = React.useMemo(() => ({
    logo: profile.org?.logo,
    fill: profile.org?.primary_color || '#777777',
  }), [profile.org?.logo, profile.org?.primary_color]);

  const [useOverlay, setUseOverlay] = React.useState<boolean>(
    Boolean(exportSettings?.overlay?.template),
  );

  // collection data
  const {
    collection: { data: outerCollection, isLoading: isCollectionLoading },
  } = useCollection({ uid: collectionId });

  // moment index in collection
  const [momentIdx, setMomentIdx] = React.useState(0);

  // moment data
  const {
    moment: {
      // set by default collection member until data has arrived
      // this will prevent of hiding popup when moment edit mode
      // in collection view is triggered
      data: outerMoment = outerCollection?.moments?.[momentIdx],
      isLoading: isMomentLoading,
    },
  } = useMoment({ uid: momentId });

  // modal state
  const show = Boolean(outerMoment || outerCollection);

  // current aspect ratio
  const [ratio, setRatio] = React.useState(_ratio);

  // current crop boxes
  const [cropBoxes, setCropBoxes] = React.useState(_cropBoxes);

  const [overlay, setOverlay] = React.useReducer(
    (r: OverlaySettings, u: Partial<OverlaySettings>) => (u ? { ...r, ...u } : null),
    _overlay,
  );

  // update current aspect ratio and crop boxes
  // in case of incoming parameters change
  React.useEffect(() => {
    if (_ratio !== ratio || _cropBoxes !== cropBoxes || overlay !== _overlay) {
      setRatio(_ratio);
      setCropBoxes(_cropBoxes);
      setOverlay(_overlay);
      setUseOverlay(Boolean(_overlay?.template));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [_cropBoxes, _ratio, _overlay]);

  // outer moment
  const [moment, setMoment] = React.useState(outerMoment);

  // outer collection
  const [collection, setCollection] = React.useState(outerCollection);

  // cleanup everything on close
  React.useEffect(() => {
    if (!show) {
      setMoment(null);
      setCollection(null);
      setRatio(null);
      setCropBoxes(null);
      setOverlay(null);
      setUseOverlay(false);
    }
  }, [show]);

  const templateOverlayProps = React.useMemo(
    () => (overlay?.settings
      ? Object.fromEntries(overlay.settings.map(({ path, value }) => [path, value]))
      : null),
    [overlay?.settings],
  );

  const {
    jsx,
    loading: isTemplatesLoading,
    persist,
    props,
    render,
    template,
    templates,
  } = useTemplateOverlay({
    data: collection || moment,
    defaults: templateDefaults,
    enabled: sessionStorage.getItem('overlays') === '1',
    path: overlay?.template,
    props: templateOverlayProps,
    ratio,
    target: collectionId ? What.COLLECTIONS : What.MOMENTS,
    user: profile,
  });

  // trigger top loading indicator and handle errors
  useHandleQuery(
    { isLoading: isMomentLoading || isCollectionLoading || isTemplatesLoading },
  );

  // ref to slider with moments in collection mode
  const sliderRef = React.useRef<SliderRefType>();

  // video player events dispatcher
  const eventDispatcher: MomentEventDispatcher = React.useMemo(() => {
    if (moment) {
      return createMomentEventDispatcher(moment, EventContext.ADMIN);
    }
  }, [moment]);

  // disable overlay if there are no templates available
  React.useEffect(() => {
    if (templates?.length === 0 && useOverlay) {
      setUseOverlay(false);
    }
  }, [templates, useOverlay]);

  // handle clone mode
  React.useEffect(() => {
    if (momentId && outerMoment) {
      if (mode === PlayerMode.MOMENT_CLONE) {
        setMoment({ ...outerMoment, pk: undefined });
      } else {
        setMoment(outerMoment);
      }
    } else {
      setMoment(null);
    }
  }, [momentId, outerMoment, mode]);

  // handle collection mode
  React.useEffect(() => {
    if (collectionId && outerCollection) {
      setCollection(outerCollection);

      if (!mode) {
        weplayedEvent({
          context: EventContext.ADMIN,
          type: EVENTS.COLLECTION_VIEW,
          collection_id: outerCollection.pk,
        });
        setMomentIdx(0);
      }
    } else {
      setCollection(null);
    }
  // intentionally omit mode here to not trigger
  // moment index change on mode change
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [outerCollection, collectionId]);

  // modal title
  const title = (mode === PlayerMode.MOMENT_EDIT && 'Edit Moment')
    || (mode === PlayerMode.MOMENT_CLONE && 'Clone Moment')
    || (mode === PlayerMode.COLLECTION_EDIT && 'Edit Playlist')
    || (mode === PlayerMode.COLLECTION_CLONE && 'Clone Playlist')
    || (onExportSettings && 'Export settings')
    || null;

  // change currently selected moment on slider
  React.useEffect(() => {
    if (collection && momentIdx !== null) {
      setMoment(collection.moments[momentIdx]);
      sliderRef.current?.go(momentIdx, true);
    }
  }, [collection, momentIdx]);

  // hide player automatically when no moment and collection
  // provided
  React.useEffect(() => {
    if (!momentId && !collectionId) {
      setMoment(null);
      setCollection(null);
      if (show) {
        onClose();
      }
    }
  }, [onClose, show, momentId, collectionId]);

  // handle moment edit
  const handleMomentEdit = React.useCallback(() => {
    onMomentEdit?.(moment);
  }, [moment, onMomentEdit]);

  // handle moment menu
  const handleMomentMenu = React.useCallback(
    ({ currentTarget }: React.MouseEvent<HTMLElement>) => {
      onMomentMenu(moment, currentTarget);
    },
    [moment, onMomentMenu],
  );

  // handle collection menu
  const handleCollectionMenu = React.useCallback(
    ({ currentTarget }: React.MouseEvent<HTMLElement>) => {
      onCollectionMenu(collection, currentTarget);
    },
    [collection, onCollectionMenu],
  );

  // handle moment like
  const handleMomentLike = React.useCallback((like: boolean) => {
    onMomentLike(moment, like);
  }, [moment, onMomentLike]);

  // handle moment link copy request
  const handleMomentLink = React.useCallback(({ currentTarget }) => {
    onMomentLink(moment, currentTarget);
  }, [moment, onMomentLink]);

  // handle collection moment index change
  const handleCollectionMoment = React.useCallback(
    ({ currentTarget }: React.MouseEvent<HTMLButtonElement>) => {
      setMomentIdx(parseInt(currentTarget.getAttribute('data-idx'), 10));
      eventDispatcher({
        type: EVENTS.MOMENT_PICK,
      });
      // fix missed outline
      currentTarget.blur();
      if (mode && onCancel) {
        onCancel();
      }
    },
    [mode, onCancel, eventDispatcher],
  );

  // handle collection like
  const handleCollectionLike = React.useCallback((like: boolean) => {
    onCollectionLike(collection, like);
  }, [collection, onCollectionLike]);

  // handle collection link copy request
  const handleCollectionLink = React.useCallback(({ currentTarget }) => {
    onCollectionLink(collection, currentTarget);
  }, [collection, onCollectionLink]);

  // handle click on the next moment button
  const handleNext = React.useCallback((manual) => {
    setMomentIdx(momentIdx + 1);
    eventDispatcher({
      type: EVENTS.MOMENT_NEXT,
      manual,
    });
  }, [momentIdx, eventDispatcher]);

  // handle cancel edit mode
  const handleCancel = React.useCallback(() => {
    if (outerCollection) {
      setCollection(outerCollection);
    } else if (outerMoment) {
      setMoment(outerMoment);
    }
    onCancel?.();
  }, [onCancel, outerCollection, outerMoment]);

  // handle prev moment button click
  const handlePrev = React.useCallback(() => {
    setMomentIdx(momentIdx - 1);
    eventDispatcher({
      type: EVENTS.MOMENT_PREV,
      manual: true,
    });
  }, [momentIdx, eventDispatcher]);

  // handle crop box change for the moment
  const handleCropBox = React.useCallback(($ratio: AspectRatio, $cropBox: CropBox) => {
    // if ratio is not the same, we'll remove all entries from crop boxes
    // and fill them with the copy of a newly provided box

    let $$ratio = ratio;
    let $$cropBoxes = cropBoxes;

    if ($ratio !== ratio) {
      $$ratio = $ratio;

      $$cropBoxes = {};

      if (collection?.moments && $ratio) {
        $$cropBoxes = Object.fromEntries(
          collection.moments.map(({ pk }) => [pk, pk === moment.pk ? $cropBox : { ...$cropBox }]),
        );
      } else if ($ratio) {
        $$cropBoxes = { [moment.pk]: $cropBox };
      }
    } else if ($$cropBoxes?.[moment.pk] !== $cropBox) {
      $$cropBoxes = { ...cropBoxes, [moment.pk]: $cropBox };
    }

    if ($$cropBoxes !== cropBoxes || $$ratio !== ratio) {
      setRatio($$ratio);
      setCropBoxes($$cropBoxes);
    }
  }, [collection?.moments, cropBoxes, moment?.pk, ratio]);

  const handlePlayRepeat = React.useCallback(() => {
    saveSettings({ collection_play_repeat: !collection_play_repeat });
  }, [saveSettings, collection_play_repeat]);

  const handleEnd = React.useCallback(() => {
    if (!collection_play_repeat && !onExportSettings) {
      handleNext(false);
    }
  }, [collection_play_repeat, handleNext, onExportSettings]);

  // Handles setting a new crop size
  // Also moves crop box back to the center or area after change
  const handleSetAspectRatio = React.useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    const ar = e.currentTarget.value as AspectRatio;
    let $crop = null;
    let $ratio = null;

    if (ar) {
      $ratio = ar;
      const r = AspectRatioProportions[ar];
      const { width, height } = cropboxRef.current.getBoundingClientRect();
      const isVertical = (width / height) > r;
      const box_width = isVertical ? height * r : width;
      const box_height = isVertical ? height : width / r;
      $crop = {
        height: box_height / height,
        width: box_width / width,
        // always put initial area at center
        y: 0.5,
        x: 0.5,
      };
    }

    if (!template?.ratios.includes($ratio)) {
      // reset template if current one does not handle new ratio
      setOverlay(null);
    }

    handleCropBox($ratio, $crop);
  }, [handleCropBox, template?.ratios]);

  const handleTemplate = React.useCallback(
    ({ currentTarget: { value } }) => {
      setOverlay(overlay?.template === value ? null : { template: value });
    },
    [overlay?.template],
  );

  const [renderOverlay, renderOverlayResult] = useMutation<
    string, Error, { height: number, type: 'png' | 'svg' }
  >(({ height, type }) => render(height, type));

  const handleOverlayEnd = React.useCallback(({ target: { value } }) => {
    setOverlay({ image_end: value ? parseInt(value, 10) : null });
  }, []);

  React.useEffect(() => {
    if (renderOverlayResult.isSuccess) {
      onExportSettings?.({
        ratio,
        boxes: cropBoxes,
        overlay: { ...overlay, image: renderOverlayResult.data },
      });
      renderOverlayResult.reset();
      // fire and forget
      persist();
      onDone?.();
    }
  }, [cropBoxes, onDone, onExportSettings, overlay, persist, ratio, renderOverlayResult]);

  const handleDone = React.useCallback(() => {
    if (overlay) {
      renderOverlay({ height: 720, type: 'svg' });
    } else {
      onDone?.();
    }
  }, [onDone, overlay, renderOverlay]);

  React.useEffect(() => {
    onExportSettings?.({ ratio, boxes: cropBoxes, overlay: useOverlay ? overlay : null });
  }, [cropBoxes, onExportSettings, overlay, ratio, useOverlay]);

  React.useEffect(() => {
    setOverlay({ settings: props });
  }, [props]);

  const handleOverlayProps = React.useCallback((settings: TemplateProperty[]): void => {
    setOverlay({ settings });
  }, []);

  // moment is in edit/clone state flag
  const manageMoment = momentId
    && [PlayerMode.MOMENT_CLONE, PlayerMode.MOMENT_EDIT].includes(mode);

  // collection is in edit/clone state flag
  const manageCollection = collectionId
    && [PlayerMode.COLLECTION_CLONE, PlayerMode.COLLECTION_EDIT].includes(mode);

  const hasNextMoment = collection && momentIdx < collection.moments.length - 1;

  const templateEditable = templates?.find(({ path }) => path === overlay?.template)?.editable;

  const templateButtonsStyle = React.useMemo(
    () => (ratio
      ? { '--aspect-ratio': String(AspectRatioProportions[ratio]) } as React.CSSProperties
      : null),
    [ratio],
  );

  return (
    <Modal
      backdropClassName="modal-backdrop-blackout"
      keyboard
      onEscapeKeyDown={onClose}
      onHide={!manageCollection ? onClose : undefined}
      show={!manageCollection && show}
      size="lg"
    >
      {moment && (
        <Modal.Body>
          <div className={s.header}>
            <button type="button" onClick={onClose}>
              <Close />
            </button>
            <h4 className={s.title}>{title}</h4>
            {moment.pk && !mode && !onExportSettings && (
              <>
                {onMomentLike && (
                  <LikeButton
                    liked={moment.liked_by_user}
                    onChange={handleMomentLike}
                    className={cx(s.like, moment.liked_by_user && s.liked)}
                  />
                )}
                {!collection && onMomentLink && (
                  <button type="button" onClick={handleMomentLink}>
                    <CopyLink />
                  </button>
                )}
                {moment.can_manage && onMomentEdit && (
                  <button type="button" onClick={handleMomentEdit}>
                    <Edit />
                  </button>
                )}
                {onMomentMenu && (
                  <button type="button" onClick={handleMomentMenu}>
                    <Menu />
                  </button>
                )}
              </>
            )}
          </div>
          <div className={s.root}>
            <MomentPlayer
              disabled={disabled}
              info={!onExportSettings}
              edit={manageMoment && !onExportSettings}
              moment={moment}
              onEnd={hasNextMoment && handleEnd}
              onCancel={onCancel && handleCancel}
              onNext={hasNextMoment && handleNext}
              onPrevious={collection && momentIdx > 0 && handlePrev}
              onSave={onMomentSave}
              overlay={onExportSettings && (
                <CropBoxOverlay
                  ref={cropboxRef}
                  cropBox={cropBoxes?.[moment.pk]}
                  ratio={ratio}
                  onChange={(c): void => handleCropBox(ratio, c)}
                >
                  {useOverlay && template && (
                    <div className={s.overlayBlock}>
                      {jsx}
                    </div>
                  )}
                </CropBoxOverlay>
              )}
              paused={Boolean(mode)}
            >
              {onExportSettings && onCancel ? (
                <div className={s.crop}>
                  <div>
                    <ul>
                      <li>
                        <label>
                          <input
                            checked={!ratio}
                            name="aspect_ratio"
                            onChange={handleSetAspectRatio}
                            type="radio"
                            value=""
                          />
                          <div
                            className={cx(s.ratio, !ratio)}
                            style={{ aspectRatio: '16/9' }}
                            title="Original size"
                          />
                        </label>
                      </li>
                      {Object.entries(AspectRatio).map(([k, v]) => (
                        <li key={k}>
                          <label>
                            <input
                              checked={ratio === v}
                              name="aspect_ratio"
                              onChange={handleSetAspectRatio}
                              type="radio"
                              value={v}
                            />
                            <div
                              className={cx(s.ratio, ratio === v)}
                              style={{ aspectRatio: String(AspectRatioProportions[v]) }}
                              title={v}
                            />
                          </label>
                        </li>
                      ))}
                    </ul>
                  </div>
                  {templates?.length ? (
                    <div className={s.overlay}>
                      <label>
                        <input
                          checked={useOverlay}
                          onChange={(e): void => setUseOverlay(e.target.checked)}
                          type="checkbox"
                          value="1"
                        />
                        Add overlay
                      </label>
                      <select
                        disabled={!useOverlay}
                        onChange={handleOverlayEnd}
                        value={overlay?.image_end || ''}
                      >
                        <option value="">show always</option>
                        <option value="2">2 seconds</option>
                        <option value="5">5 seconds</option>
                      </select>
                    </div>
                  ) : null}
                  <div className={s.buttons}>
                    <Button
                      disabled={renderOverlayResult.isLoading}
                      loading={renderOverlayResult.isLoading}
                      onClick={handleDone}
                      variant="primary"
                    >
                      Done
                    </Button>
                    <Button
                      disabled={renderOverlayResult.isLoading}
                      onClick={onCancel}
                      variant="secondary"
                    >
                      Cancel
                    </Button>
                  </div>
                </div>
              ) : null}
            </MomentPlayer>
            {onExportSettings && (
              <div className={s.help}>
                {'Click on desired aspect ratio box above. '}
                {collectionId && `You can have different positions for every moment in collection,
                    but after changing aspect ratio they all will be reset to default position.`}
              </div>
            )}
            {useOverlay && (
              <div
                className={cx(s.overlayPreviews, templateEditable && s.folded)}
                style={templateButtonsStyle}
              >
                {templates?.filter(({ path }) => (
                    !templateEditable || path === overlay?.template
                  ))
                  .map(({ image, path, name }) => (
                    // eslint-disable-next-line jsx-a11y/control-has-associated-label
                    <button
                      className={cx(overlay?.template === path && s.selected)}
                      dangerouslySetInnerHTML={{ __html: image }}
                      key={path}
                      title={name}
                      type="button"
                      onClick={handleTemplate}
                      value={path}
                    />
                  ))}
                {templateEditable && (
                  <OverlayProps
                    props={overlay?.settings}
                    onChange={handleOverlayProps}
                  />
                )}
              </div>
            )}
            {collection && (
              <div
                className={
                  cx(
                    s.collection,
                    [PlayerMode.MOMENT_EDIT, PlayerMode.MOMENT_CLONE].includes(mode) && s.disabled,
                  )
                }
              >
                <div className={cx(s.header, s.collectionHeader)}>
                  <Avatar className={s.avatar} user={collection.created_by} />
                  <h4 className={s.title}>{collection.name}</h4>
                  <span className={s.extra}>
                    {format(messages.MOMENTS, collection.moment_count)}
                    {collection.can_see_duration
                      && format(messages.MOMENTS_DURATION, collection.duration.toFixed(2))}
                  </span>
                  {onCollectionLike && (
                    <LikeButton
                      liked={collection.liked_by_user}
                      onChange={handleCollectionLike}
                      className={cx(s.like, collection.liked_by_user && s.liked)}
                    />
                  )}
                  {onCollectionLink && (
                    <button
                      onClick={handleCollectionLink}
                      title="Copy link to playlist"
                      type="button"
                    >
                      <CopyLink />
                    </button>
                  )}
                  {!onExportSettings && (
                    <button
                      onClick={handlePlayRepeat}
                      title={collection_play_repeat
                              ? 'Play moment in loop'
                              : 'Play moments one by one'}
                      type="button"
                    >
                      {collection_play_repeat ? <Repeat /> : <RepeatOff /> }
                    </button>
                  )}
                  {onCollectionMenu && (
                    <button type="button" onClick={handleCollectionMenu}>
                      <Menu />
                    </button>
                  )}
                </div>
                <div className={s.moments}>
                  <Slider ref={sliderRef}>
                    {collection.moments.map((m, idx) => (
                      <button
                        className={cx(
                          s.moment,
                          moment?.pk === m.pk && s.active,
                          cropBoxes?.[m.pk] && s.own,
                        )}
                        data-idx={idx}
                        key={m.pk}
                        onClick={handleCollectionMoment}
                        type="button"
                      >
                        <ItemPreview
                          alt={m.name}
                          highlight={cropBoxes?.[m.pk]}
                          animated={m.preview_webp}
                          className={s.thumbnail}
                          src={m.thumbnail}
                        >
                          <div className={s.momentIdx}>
                            {idx + 1}
                          </div>
                        </ItemPreview>
                      </button>
                    ))}
                  </Slider>
                </div>
              </div>
            )}
          </div>
        </Modal.Body>
      )}
    </Modal>
  );
};
