import * as React from 'react';
import { Helmet } from 'react-helmet';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';

import { Button } from 'common/components/Button';
import { useProfile } from 'common/hooks/useProfile';
import { PasswordUpdate } from 'common/hooks/useProfile/types';

import FormField from 'cms/components/Field/FormField';
import NonFieldErrors from 'cms/components/Field/NonFieldErrors';
import { CLIENT_URLS } from 'cms/routes';

import { Form } from './types';

export const ChangePassword: React.FunctionComponent = function ChangePassword() {
  const history = useHistory();
  const { change_password: [update, { isLoading, error }] } = useProfile();

  const handleSubmit = React.useCallback((e: React.FormEvent<Form>) => {
    e.preventDefault();

    const attributes = ['password', 'new_password', 'repeat_password'].reduce(
      (acc, key) => ({ ...acc, [key]: e.currentTarget.elements[key].value }),
      {} as PasswordUpdate,
    );

    update(attributes, {
      onSuccess: () => {
        toast.success('Password successfully changed!');
        history.push(CLIENT_URLS.USER.MY_PROFILE.toPath());
      },
    });
  }, [update]);

  const errors = error?.json;

  return (
    <div className="container">
      <Helmet>
        <title>WePlayed: Change Your Password</title>
      </Helmet>
      <div className="row">
        <div className="col-lg-6 offset-lg-3">
          <h1>Change Password</h1>
          <form onSubmit={handleSubmit} method="post">
            <NonFieldErrors errors={errors} />
            <FormField
              label="Old password"
              name="password"
              errors={errors}
            >
              <input
                type="password"
                name="password"
                className="form-control"
              />
            </FormField>
            <FormField
              label="New password"
              name="new_password"
              errors={errors}
              required
            >
              <input
                type="password"
                name="new_password"
                className="form-control"
                required
              />
            </FormField>
            <FormField
              label="Repeat new password"
              name="repeat_password"
              errors={errors}
              required
            >
              <input
                type="password"
                name="repeat_password"
                className="form-control"
                required
              />
            </FormField>
            <Button type="submit" size="lg" disabled={isLoading} loading={isLoading}>
              Change
            </Button>
          </form>
        </div>
      </div>
    </div>
  );
};
