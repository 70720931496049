import { debounce, memoize } from 'lodash/fp';
import * as React from 'react';
import { Col, Container, Form, Row } from 'react-bootstrap';
import { ID, What } from 'weplayed-typescript-api';

import { AspectRatioProportions } from 'common/constants';
import { AspectRatio, EmbedParams } from 'common/types';

import {
  embedParamsDefault, embedParamsDescription,
} from 'cms/containers/EmbedRouter/constants';
import { CLIENT_URLS } from 'cms/routes';

import { DefaultWidth, MaxWidth, MinWidth, WidthStep } from './constants';
import * as s from './EmbedForm.m.less';
import { EmbedFormProps } from './types';

const { MOMENT: { EMBED } } = CLIENT_URLS;

const debouncedWidthHandler = memoize((handler: (n: number) => void) => {
  const h = debounce(500, handler);

  return (
    { currentTarget: { value } }: React.ChangeEvent<HTMLInputElement>,
  ): void => h(parseInt(value, 10));
});

const getIframeCode = (
  uid: ID,
  width: number,
  height: number,
  type: EmbedFormProps['type'],
  params: EmbedParams,
): [string, string] => {
  const queryParams = Object.fromEntries(
    Object.entries(params)
      .filter(([key, value]): boolean => embedParamsDefault[key] !== value),
  ) as { [key: string]: string };

  queryParams.utm_source = 'weplayed';
  queryParams.utm_medium = 'embed';

  if (type === What.MOMENTS) {
    queryParams.utm_content = 'mo-00';
    queryParams.m = uid;
  } else if (type === What.COLLECTIONS) {
    queryParams.utm_content = 'pl-00';
    queryParams.c = uid;
  }

  const src = `https://${process.env.APP_SITE_NAME}${EMBED.buildPath({ queryParams })}`;

  const iframe = `<iframe ${
    Object.entries({
      src,
      width,
      height,
      frameborder: 'no',
      allowtransparency: true,
      scrolling: 'no',
      allow: 'autoplay;fullscreen',
    }).map(([k, v]) => `${k}="${encodeURI(String(v))}"`).join(' ')
  }></iframe>`;

  return [iframe, src];
};

export const EmbedForm: React.FC<EmbedFormProps> = function EmbedForm({
  onCode,
  type,
  uid,
}) {
  const [width, setWidth] = React.useState(DefaultWidth);
  const [params, setParams] = React.useState(embedParamsDefault);
  const [iframe, setIframe] = React.useState<string>();
  const text = React.useRef<HTMLTextAreaElement>();
  const height = React.useMemo(() => width * AspectRatioProportions[AspectRatio.AR9x16], [width]);

  React.useEffect(() => {
    if (type === What.COLLECTIONS) {
      setParams((p) => ({ ...p, infoframe: true }));
    }
  }, [type]);

  const descPairs = React.useMemo(() => (
    Object.entries(embedParamsDescription)
    .filter(([t]) => t !== 'infoframe' || type !== What.COLLECTIONS) as [
      [ keyof EmbedParams, string ]
    ]
  ), [type]);

  React.useEffect(() => {
    const [code, url] = getIframeCode(uid, width, height, type, params);
    setIframe(code);
    onCode({ code, url, width, height });
  }, [onCode, params, uid, width, height, type]);

  const handleChangeParam = React.useCallback(
    (param: keyof EmbedParams) => (
      ({ currentTarget: { checked } }: React.ChangeEvent<HTMLInputElement>): void => {
        setParams({ ...params, [param]: checked });
      }
    ),
    [params],
  );

  return (
    <Container>
      <Row>
        <Col xs="12" sm="3">
          <Form.Group controlId="iframeWidth">
            <Form.Label>Width</Form.Label>
            <Form.Control
              as="input"
              className={s.input}
              defaultValue={width}
              max={MaxWidth}
              min={MinWidth}
              onChange={debouncedWidthHandler(setWidth)}
              placeholder="width"
              step={WidthStep}
              type="number"
            />
          </Form.Group>
        </Col>
        <Col xs="12" sm="9">
          {descPairs.map(([key, desc]) => (
            <Form.Check
              checked={Boolean(params[key])}
              id={`share-param-${uid}-${key}`}
              key={key}
              label={desc}
              onChange={handleChangeParam(key)}
              type="checkbox"
            />
          ))}
        </Col>
      </Row>
      <Row>
        <Col className={s.textarea}>
          <Form.Control
            as="textarea"
            ref={text}
            rows={4}
            readOnly
            value={iframe}
          />
        </Col>
      </Row>
    </Container>
  );
};
