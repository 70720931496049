import { EmbedParams } from 'common/types';

/**
 * Default embed parameters
 */
export const embedParamsDefault: EmbedParams = {
  ads: true,
  autoplay: false,
  collectionId: '',
  disableLinks: false,
  features: {},
  infoframe: false,
  moments: [],
  orgId: '',
  parent: '',
  t: '',
  thumb: false,
};

/**
 * Key/description used to show in EmbedForm
 */
export const embedParamsDescription: Partial<{ [K in keyof EmbedParams]: string }> = {
  // fullscreen: 'Show fullscreen button',
  // autoplay: 'Enable video autoplay',
  thumb: 'Show thumbnail instead of player',
  infoframe: 'Show info frame before video play',
};
