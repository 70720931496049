import 'consumer/styles/less/style.less';
import './App.m.less';

import * as React from 'react';
import { Helmet } from 'react-helmet';
import { Switch } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

import * as Analytics from 'common/utils/analytics';
import { getVisitorId } from 'common/utils/visitorId';

import { ConfirmationModal } from 'consumer/components/ConfirmationModal';
import { Loading } from 'consumer/components/Loading';
import { Route } from 'consumer/components/Route';
import { RouteGroup } from 'consumer/components/Route/types';
import { Login } from 'consumer/containers/Authentication/Login';
import { Registration } from 'consumer/containers/Authentication/Registration';
import {
  RegistrationConfirm,
} from 'consumer/containers/Authentication/RegistrationConfirm';
import {
  ResetPassword,
} from 'consumer/containers/Authentication/ResetPassword';
import {
  ResetPasswordConfirm,
} from 'consumer/containers/Authentication/ResetPasswordConfirm';
import { Channel } from 'consumer/containers/Channel';
import { ClipperView } from 'consumer/containers/ClipperView';
import { Collection } from 'consumer/containers/Collection';
import { EmailNagModal } from 'consumer/containers/EmailNagModal';
import { Errors, Page404 } from 'consumer/containers/Errors';
import { Footer } from 'consumer/containers/Footer';
import { Game } from 'consumer/containers/Game';
import { Header } from 'consumer/containers/Header';
import { Home } from 'consumer/containers/Home';
import { Moment } from 'consumer/containers/Moment';
import { Player } from 'consumer/containers/Player';
import { SchoolsView } from 'consumer/containers/SchoolsView';
import { Search } from 'consumer/containers/Search';
import { ChangePassword } from 'consumer/containers/User/ChangePassword';
import { Profile } from 'consumer/containers/User/Profile';
import { ProfileUpdate } from 'consumer/containers/User/ProfileUpdate';
import { UpdateEmail } from 'consumer/containers/User/UpdateEmail';
import { CLIENT_URLS } from 'consumer/routes';

import { Props, State } from './types';

const ignoreNagModalUrls = [
  CLIENT_URLS.AUTH.LOGIN.buildPath(),
  CLIENT_URLS.AUTH.REGISTRATION.buildPath(),
  CLIENT_URLS.HOME.INTRO.buildPath(),
];

export class App extends React.Component<Props, State> {
  public state: State = {};

  constructor(props: Props) {
    super(props);
    Analytics.initFullStory();
  }

  async componentDidMount(): Promise<void> {
    this.identify();
    Analytics.pageView();

    if (window.Appcues) {
      window.Appcues.on('flow_started', this.onAppCuesFlowShowed);
      window.Appcues.on('flow_completed', this.onAppCuesFlowHid);
      window.Appcues.on('flow_skipped', this.onAppCuesFlowHid);
      window.Appcues.on('flow_aborted', this.onAppCuesFlowHid);
    }
  }

  componentDidUpdate(prevProps): void {
    if (this.props.user !== prevProps.user) {
      this.identify();
    }

    if (this.props.location !== prevProps.location) {
      window.scrollTo({ top: 0, left: 0, behavior: 'instant' as unknown as 'smooth' });
    }

    const { location: { pathname } } = this.props;
    const previousLocation = prevProps.location.pathname;

    if (pathname !== previousLocation) {
      if (window.Appcues && this.props.user) {
        window.Appcues.page();
      }
      Analytics.pageView();
    }
  }

  getUserAnalyticsProfile = (visitorId: string): Analytics.UserAnalyticsProfile => {
    const { subscription, user } = this.props;

    if (!user) {
      return {
        visitor_id: visitorId,
        email: subscription && subscription.email,
      };
    }
    const userProfile: Analytics.UserAnalyticsProfile = {
      visitor_id: visitorId,
      db_id: user.pk,
      email: user.email,
      username: user.username,
      full_name: user.full_name,
      is_staff: user.is_staff,
      is_cms_user: user.is_cms_user,
      is_curator: user.is_curator,
    };
    return userProfile;
  };

  identify = (): void => {
    const { user } = this.props;
    const visitorId = getVisitorId();
    if (visitorId) {
      if (
        visitorId !== this.state.userAnalyticsIdentity
        || (user && !this.state.userAnalyticsProfile)
      ) {
        const userProfile = this.getUserAnalyticsProfile(visitorId);

        Analytics.identify(visitorId, userProfile);

        if (window.Appcues) {
          if (user) {
            window.Appcues.identify(visitorId, userProfile);
          }
        }
        this.setState({
          userAnalyticsIdentity: visitorId,
          userAnalyticsProfile: user,
        });
      }
    }

    if (window.Appcues && !user) {
      window.Appcues.reset();
    }

    if (window.zE) {
      if (user) {
        window.zE('webWidget', 'prefill', {
          name: { value: user.full_name },
          email: { value: user.email },
        });
      } else {
        window.zE('webWidget', 'reset');
      }
    }
  };

  onAppCuesFlowShowed = (): void => {
    this.props.setAppCues(true);
  };

  onAppCuesFlowHid = (): void => {
    this.props.setAppCues(false);
  };

  handleDenyLogin = (): void => {
    this.props.resetAuthenticate();
  };

  handleBeginLogin = (): void => {
    this.props.resetAuthenticate();
    const next = `${this.props.location.pathname}${this.props.location.search}`;
    const url = CLIENT_URLS.AUTH.REGISTRATION.buildPath({ queryParams: { next } });
    this.props.history.push(url);
  };

  // eslint-disable-next-line class-methods-use-this
  renderApp = (): JSX.Element => (
    <React.Suspense fallback={<Loading />}>
      <ConfirmationModal
        show={this.props.authenticate}
        id="modal-login-required"
        onConfirm={this.handleBeginLogin}
        onCancel={this.handleDenyLogin}
        title="Login Required"
        message={
          'You need to login or to create a user account to access this feature. '
          + 'We will walk you through it and come back to this screen. '
        }
      />
      <Switch>
        <Route
          exact
          path={CLIENT_URLS.HOME.INTRO.route}
          component={Home}
        />
        <Route
          exact
          path={CLIENT_URLS.HOME.DISCOVER.route}
          component={Home}
        />
        <Route
          exact
          path={CLIENT_URLS.HOME.DISCOVER_DEFAULT.route}
          component={Home}
        />
        <Route
          path={CLIENT_URLS.COLLECTIONS.VIEW_COLLECTION.route}
          component={Collection}
        />
        <Route
          component={Player}
          exact
          path={CLIENT_URLS.PLAYER.VIEW.route}
        />
        <Route
          exact
          path={CLIENT_URLS.AUTH.LOGIN.route}
          component={Login}
          groups={[RouteGroup.UNAUTHENTICATED]}
        />
        <Route
          exact
          path={CLIENT_URLS.AUTH.REGISTRATION_CONFIRM.route}
          component={RegistrationConfirm}
          groups={[RouteGroup.UNAUTHENTICATED]}
        />
        <Route
          exact
          path={CLIENT_URLS.AUTH.REGISTRATION.route}
          component={Registration}
          groups={[RouteGroup.UNAUTHENTICATED]}
        />
        <Route
          exact
          path={CLIENT_URLS.AUTH.RESET_PASSWORD.route}
          component={ResetPassword}
          groups={[RouteGroup.UNAUTHENTICATED]}
        />
        <Route
          exact
          path={CLIENT_URLS.AUTH.RESET_PASSWORD_CONFIRM.route}
          component={ResetPasswordConfirm}
          groups={[RouteGroup.UNAUTHENTICATED]}
        />
        <Route
          exact
          path={CLIENT_URLS.USER.MY_CHANNEL.route}
          component={Channel}
          groups={[RouteGroup.AUTHENTICATED]}
        />
        <Route
          exact
          path={CLIENT_URLS.USER.CHANNEL.route}
          component={Channel}
        />
        <Route
          exact
          path={CLIENT_URLS.USER.MY_PROFILE.route}
          component={Profile}
          groups={[RouteGroup.AUTHENTICATED]}
        />
        <Route
          exact
          path={CLIENT_URLS.USER.MY_PROFILE_UPDATE.route}
          component={ProfileUpdate}
          groups={[RouteGroup.AUTHENTICATED]}
        />
        <Route
          exact
          path={CLIENT_URLS.USER.UPDATE_EMAIL.route}
          component={UpdateEmail}
          groups={[RouteGroup.AUTHENTICATED]}
        />
        <Route
          exact
          path={CLIENT_URLS.USER.CHANGE_PASSWORD.route}
          component={ChangePassword}
          groups={[RouteGroup.AUTHENTICATED]}
        />
        <Route
          path={CLIENT_URLS.MOMENT.VIEW_MOMENT.route}
          component={Moment}
        />
        <Route
          path={CLIENT_URLS.GAME.VIEW.route}
          component={Game}
        />
        <Route
          path={CLIENT_URLS.SEARCH.PAGE.route}
          component={Search}
        />
        <Route
          path={CLIENT_URLS.CLIPPER.CLIPPER_VIEW.route}
          component={ClipperView}
        />
        <Route
          path={CLIENT_URLS.SCHOOLS.LIST_VIEW.route}
          component={SchoolsView}
        />
        <Route exact path="/" component={Home} />
        <Route path="*" component={Page404} />
      </Switch>
    </React.Suspense>
  );

  render(): JSX.Element {
    const { error, wlOrgId } = this.props;

    return (
      <>
        <Helmet titleTemplate="%s" defaultTitle="WePlayed: When the Game Ends, the Story Begins">
          <meta property="fb:app_id" content={process.env.FACEBOOK_APP_ID} />
          <link
            rel="icon"
            type="image/png"
            href="https://media.weplayed.com/favico/favico.png"
          />
        </Helmet>
        <ToastContainer hideProgressBar />
        {!wlOrgId && <Header />}
        <EmailNagModal
          ignore={ignoreNagModalUrls}
          // schedule
        >
          <div className="app-page">
            <Errors error={error} render={this.renderApp} />
          </div>
        </EmailNagModal>

        {!wlOrgId && <Footer />}
      </>
    );
  }
}
