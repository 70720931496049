import * as cx from 'classnames';
import { ReactComponent as Spinner } from 'images/spinner.svg';
import * as React from 'react';
import videojs from 'video.js';

import { PREVIEW_WIDTH } from './constants';
import { Props } from './types';
import * as s from './VideoPreviewBar.m.less';

export const VideoPreviewBar: React.FC<Props> = function VideoPreviewBar({
  end, start, videoUrl, position, className, keepInside, ...params
}) {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const videoElementRef = React.useRef<any>();
  const videoNodeRef = React.useRef<HTMLVideoElement>();

  const containerRef = React.useRef<HTMLDivElement>();
  const videoRef = React.useRef<HTMLDivElement>();

  React.useEffect(() => {
    if (videoUrl && videoNodeRef.current) {
      videoElementRef.current = videojs(videoNodeRef.current, {
        autoplay: false,
        fluid: true,
        playsinline: true,
        controls: false,
        preload: 'auto',
        muted: true,
        children: {
          mediaLoader: {},
          loadingSpinner: {},
          resizeManager: {},
          liveTracker: true,
        },
        vhs: {
          withCredentials: true,
          handleManifestRedirects: true,
          overrideNative: true,
        },
      });
      videoElementRef.current.on('durationchange', () => {
        videoElementRef.current.play();
      });
      videoElementRef.current.on('playing', () => {
        videoElementRef.current.pause();
      });
      videoElementRef.current.src({
        src: videoUrl,
        type: 'application/x-mpegURL',
      });

      videoElementRef.current.currentTime(start);

      return (): void => {
        // eslint-disable-next-line no-unused-expressions
        videoElementRef.current?.dispose();
        videoElementRef.current = null;
      };
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [videoUrl]);

  React.useEffect(() => {
    if (videoElementRef.current && typeof position === 'number') {
      videoElementRef.current.currentTime(position);
      const percent = (position - start) / (end - start);
      const { width } = containerRef.current.getBoundingClientRect();
      const left = keepInside
        ? Math.max(0, Math.min(width - PREVIEW_WIDTH, width * percent - PREVIEW_WIDTH / 2))
        : width * percent - PREVIEW_WIDTH / 2;
      videoRef.current.style.left = `${left.toFixed(2)}px`;
    }
  }, [end, keepInside, position, start]);

  return (
    <div
      {...params}
      className={cx(s.root, typeof position === 'number' && s.visible, className)}
      ref={containerRef}
    >
      <div
        className={s.video}
        ref={videoRef}
      >
        <Spinner />
        {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
        <video
          crossOrigin="anonymous"
          ref={videoNodeRef}
          className="video-js vjs-default-skin"
          disablePictureInPicture
        />
      </div>
    </div>
  );
};
