import { get, uniq } from 'lodash';
import { CSSProperties } from 'react';
import { renderToStaticMarkup } from 'react-dom/server';

import { logger } from '../logger';
import { Block } from './block';
import * as constants from './constants';
import {
  Data, ItemBox, OverlayProps, Template, TemplateDefaults, TemplateProp,
  TemplatePropColor, TemplateProperty, TemplatePropImage, TemplatePropList,
  TemplatePropRange, TemplatePropText,
} from './types';
import { imageToDataURL } from './utils';

/**
 * CSS definitions of classes used in SVG
 */
const CSS = `
/* editable element class */
.wpovrleditable {
  cursor: pointer;
  transform-box: fill-box;
  transform-origin: center;
  transition: transform .1s ease-in;
}
.wpovrleditable:hover {
  transform: scale(1.03);
  transition: transform .1s ease-out;
}
.wpovrlinactive {
  pointer-events: none;
  touch-action: none;
}
`;

/**
 * Root SVG style
 */
const ROOT: CSSProperties = {
  userSelect: 'none',
};

export class Overlay implements OverlayProps {
  /**
   * Data object
   */
  public data: Data;

  /**
   * Calculated editable properties list with values, where
   * key is JSON path to property to change in template
   */
  protected $props: TemplateProperty[];

  /**
   * Root block
   */
  protected $block: Block;

  /**
   * Root bounding box in absolute values
   */
  protected $box: ItemBox;

  /**
   * Defaults dictionary
   */
  protected $defaults: TemplateDefaults;

  /**
   * A list of template properties which could persist
   * in settings
   */
  protected $persist: string[];

  /**
   * Method used by children items to expose own editable
   * properties on selection
   */
  public onSelect?: (props: TemplateProperty[]) => void;

  /**
   * Overlay constructor
   * @param ratio width/height ratio
   * @param template Template
   * @param data Data source
   * @param defaults Defaults dict
   * @param onSelect Callback to be called when selection is made
   */
  constructor(
    ratio: number,
    template: Template,
    data: Data,
    defaults: TemplateDefaults,
    onSelect?: (props: TemplateProperty[]) => void,
  ) {
    this.$defaults = defaults;
    this.onSelect = onSelect;
    this.$box = { x: 0, y: 0, w: 100, h: 100 / ratio };
    this.data = data;
    this.$props = [];
    this.$persist = [];
    this.$block = new Block(this, '', {
      ...JSON.parse(JSON.stringify(template)),
      w: '100w',
      h: '100h',
      position: 'lt',
    });
  }

  /**
   * Current bounding box getter
   */
  get bbox(): ItemBox {
    return this.$box;
  }

  /**
   * Default values accessor
   * @param key Default property key
   * @returns Value of default property
   */
  public defaults(key: string): string {
    return get(this.$defaults || {}, key);
  }

  public props(values?: Record<string, string>): TemplateProperty[] {
    if (values) {
      this.$props = this.$props.map((prop) => {
        if (prop.path in values) {
          return { ...prop, value: values[prop.path] };
        }
        return prop;
      });
    }

    return this.$props;
  }

  /**
   * Return property paths and values which can be persisted in the external storage
   * @returns path -> value dictionary
   */
  public persist(): Record<string, string> {
    return Object.fromEntries(
      this.$props
        .filter(({ path }) => this.$persist.includes(path))
        .map(({ path, value }) => [path, value]),
    );
  }

  /**
   * Register property in the overlay
   * @param property
   * @param value
   */
  public register({ source, ...prop }: TemplateProp, value?: string): void {
    let v: string;

    if (source) {
      const src = source.split('|');
      while (!v && src.length) {
        const pp = src.shift();
        if (pp.startsWith('$')) {
          v = this.defaults(pp.substring(1));
        } else {
          v = get(this.data, pp);
        }
      }
    }

    v = v || value;

    if (prop.type === 'text') {
      const config: TemplateProperty<'text'> = { ...(prop as TemplatePropText), value: v };
      config.maxLength = config.maxLength || 100;
      this.$props.push(config);
    } else if (prop.type === 'range') {
      // strip postfix, and append it later in Base.prop
      const config: TemplateProperty<'range'> = { ...(prop as TemplatePropRange), value: v };
      config.value = config.value.replace(/[wh]/, '');
      this.$props.push(config);
    } else if (prop.type === 'color') {
      const config: TemplateProperty<'color'> = { ...(prop as TemplatePropColor), value: v };
      const m = config.value?.match(/^(#?)([0-9a-f]{3})([0-9a-f]{3})?$/i);
      if (m) {
        // fix color format
        config.value = `${
          m[1] || '#'
        }${
          m[3]
            ? m[2] + m[3]
            : m[2][0] + m[2][0] + m[2][1] + m[2][1] + m[2][2] + m[2][2]
        }`;
      } else {
        logger.warn(`Color is in not suitable format: ${JSON.stringify(config)}`);
        config.value = '#000000';
      }
      this.$props.push(config);
    } else if (prop.type === 'list') {
      const config: TemplateProperty<'list'> = {
        ...(prop as TemplatePropList),
        value: v,
        options: [],
      };

      const { options } = (prop as TemplatePropList);
      if (options === 'text-align') {
        config.options = constants.textAlign;
      } else if (options === 'font-family') {
        config.options = constants.fontFamily;
      } else if (options === 'font-style') {
        config.options = constants.fontStyle;
      } else if (options === 'position') {
        config.options = constants.blockPosition;
      } else if (Array.isArray(options)) {
        config.options = options;
      } else {
        config.options = [];
      }
      this.$props.push(config);
    } else if (prop.type === 'image') {
      const config: TemplateProperty<'image'> = { ...(prop as TemplatePropImage), value: v };
      this.$props.push(config);
    }

    if (prop.name && (!source || prop.persist)) {
      this.$persist.push(prop.path);
    }
  }

  /**
   * Return SVG as react node
   * @returns SVG node
   */
  public jsx(): React.ReactElement {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox={`0 0 ${this.$box.w} ${this.$box.h}`}
        style={ROOT}
      >
        <style>{CSS}</style>
        {this.$block.render(this.$box)}
      </svg>
    );
  }

  /**
   * Export as text svg file
   * @param dataUri Encode svg as data-URI
   * @returns content of SVG
   */
  public svg(dataUri = false): string {
    const svg = renderToStaticMarkup(this.jsx());
    return dataUri ? `data:image/svg+xml;base64,${btoa(svg)}` : svg;
  }

  /**
   * Renders current SVG as PNG image of the specified height
   * @param width Width of target image in pixels
   * @param height Height of target image in pixels
   * @returns PNG image in base64 encoding
   */
  public async png(width: number, height: number): Promise<string> {
    const svg = this.svg();
    const re = /(<image[^<]+href=")(https?:\/\/[^"]+)(")/ig;
    const hrefs = uniq([...svg.matchAll(re)].map(([, , href]) => href));
    const imgs = await Promise.all(hrefs.map((href) => imageToDataURL(href)));
    const staticSvg = svg.replace(
      re,
      (_, pre, href, post) => `${pre}${imgs[hrefs.indexOf(href)]}${post}`,
    );

    return new Promise((ok, ko) => {
      const img = document.createElement('img') as HTMLImageElement;

      img.onerror = ko;

      img.onload = (): void => {
        const canvas = document.createElement('canvas');
        canvas.width = width;
        canvas.height = height;

        const ctx = canvas.getContext('2d');
        ctx.clearRect(0, 0, width, height);
        ctx.drawImage(img, 0, 0);
        ok(canvas.toDataURL('image/png'));
      };

      img.src = `data:image/svg+xml;base64,${btoa(staticSvg)}`;
    });
  }
}
