import { Alignment, FontStyle, Position, TemplateOption } from './types';

export const fontFamily: Array<TemplateOption> = [
  { name: 'Arial' },
  { name: 'Arial Black' },
  { name: 'Courier New' },
  { name: 'Tahoma' },
  { name: 'Times New Roman' },
  { name: 'Trebuchet MS' },
];

export const textAlign: Array<TemplateOption<Alignment>> = [
  { name: 'left', label: 'Left' },
  { name: 'right', label: 'Right' },
  { name: 'center', label: 'Center' },
];

export const fontStyle: Array<TemplateOption<FontStyle>> = [
  { name: 'normal', label: 'Normal' },
  { name: 'bold', label: 'Bold' },
  { name: 'italic', label: 'Italic' },
];

export const blockPosition: Array<TemplateOption<Position>> = [
  { name: 'c', label: 'Center' },
  { name: 'lt', label: 'Left-Top' },
  { name: 't', label: 'Top' },
  { name: 'rt', label: 'Right Top' },
  { name: 'r', label: 'Right' },
  { name: 'rb', label: 'Right Bottom' },
  { name: 'b', label: 'Bottom' },
  { name: 'lb', label: 'Left Bottom' },
  { name: 'l', label: 'Left' },
];
