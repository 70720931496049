import { SVGAttributes } from 'react';

import {
  ItemBounds, ItemBox, ItemCoordinate, ItemSize, OverlayProps, TemplateItemBase,
} from './types';
import { getLength, getPadding } from './utils';

/**
 * Base class for all template items
 */
export abstract class Base<T extends TemplateItemBase> {
  /**
   * Item with relative parameters
   */
  protected readonly item: T;

  /**
   * Overlay class (root)
   */
  protected readonly root: OverlayProps;

  /**
   * Current item JSON path
   */
  protected $path: string;

  /**
   * Callback to provide for `onClick` event in SVG element
   */
  protected onSelect?: () => void;

  /**
   * Constructor
   * @param root Overlay reference (root)
   * @param path Current item JSON path
   * @param item Template item
   */
  constructor(
    root: OverlayProps,
    path: string,
    item: T,
  ) {
    this.root = root;
    this.$path = path;
    this.item = item;

    item.props?.forEach(({ path: p, ...rest }) => root.register(
      { ...rest, path: this.path(p) },
      this.item[p],
    ));

    if (this.root.onSelect) {
      const p = this.path();

      const props = this.root.props()
        .filter(({ path: pp, name }) => (
          name && pp.startsWith(p) && !pp.substring(p.length + 1).includes('.')
        ));

      if (props.length) {
        this.onSelect = (): void => {
          this.root.onSelect(props);
        };
      }
    }
  }

  /**
   * Get/set property value
   * @param path JSON path to property value relative to current template item
   * @returns Current property value
   */
  protected prop(path: string): string {
    const props = this.root.props();

    const full_path = this.path(path);
    const prop = props.find(({ path: p }) => full_path === p);

    let val = this.item[path];

    if (prop) {
      val = prop.value;

      if (prop?.type === 'range') {
        const m = this.item[path].match(/^-?\d+(?:\.\d+)?([wh])$/);
        if (m) {
          val = `${prop.value}${m[1]}`;
        }
      }
    }

    return val;
  }

  /**
   * Calculates absolute JSON path to the inner property
   * @param path Relative to item path to property
   * @returns Absolute JSON path
   */
  protected path(path?: string): string {
    return [this.$path, path].filter(Boolean).join('.');
  }

  /**
   * Renders and returns basic SVG attributes for rendering SVG elements
   * @param _box Bounding box
   * @returns SVG attributes
   */
  protected svgAttrs(_box: ItemBox): SVGAttributes<SVGElement> {
    const classNames = this.onSelect ? ['wpovrleditable'] : ['wpovrlinactive'];

    const attrs: SVGAttributes<SVGElement> = {
      onClick: this.onSelect,
      className: classNames.join(' '),
    };

    if ('fill' in this.item) {
      attrs.fill = this.prop('fill');
    }

    if (this.item.strokeWidth) {
      attrs.strokeWidth = getLength(this.prop('strokeWidth'), this.root.bbox) ?? '0';
      attrs.stroke = this.prop('stroke') ?? 'black';
    }

    if (this.item.opacity) {
      const opacity = this.prop('opacity');

      if (opacity) {
        attrs.fillOpacity = opacity;
        if (attrs.stroke) {
          attrs.strokeOpacity = opacity;
        }
      }
    }

    return attrs;
  }

  /**
   * Calculates padded-box for bounding box
   * @param parent Parent bbox
   * @param bbox Current bbox
   * @returns Padded-box
   */
  protected pbox(parent: ItemBox, bbox: ItemBox): ItemBox {
    const padding = getPadding(this.item, parent);
    return {
      w: bbox.w - (padding[1] + padding[3]),
      h: bbox.h - (padding[0] + padding[2]),
      x: bbox.x + padding[3],
      y: bbox.y + padding[0],
    };
  }

  /**
   * Calculates position of current item assuming its size passed in `box`
   * @param parent Parent bbox
   * @param box Current item size
   * @returns Absolute x/y coordinates
   */
  protected position(parent: ItemBox, box: ItemSize): ItemCoordinate {
    const x = this.prop('x') ?? '0w';
    const y = this.prop('y') ?? '0h';
    const position = this.prop('position') ?? 'c';
    const bx = parent.x + getLength(x, parent);
    const by = parent.y + getLength(y, parent);

    switch (position) {
      // left top
      case 'lt': return { x: bx, y: by };
      // center top
      case 't': return { x: parent.w / 2 + bx - box.w / 2, y: by };
      // right top
      case 'rt': return { x: parent.w + bx - box.w, y: by };
      // right middle
      case 'r': return { x: parent.w + bx - box.w, y: parent.h / 2 + by - box.h / 2 };
      // right bottom
      case 'rb': return { x: parent.w + bx - box.w, y: parent.h + by - box.h };
      // center bottom
      case 'b': return { x: parent.w / 2 + bx - box.w / 2, y: parent.h + by - box.h };
      // left bottom
      case 'lb': return { x: bx, y: parent.h + by - box.h };
      // left middle
      case 'l': return { x: bx, y: parent.h / 2 + by - box.h / 2 };
      // center middle
      default: return { x: parent.w / 2 + bx - box.w / 2, y: parent.h / 2 + by - box.h / 2 };
    }
  }

  /**
   * Abstract method to measure item size with regards of all children
   * @param parent Parent bbox
   * @returns Both bounding box and padded box
   */
  public abstract measure(parent: ItemBox): ItemBounds;

  /**
   * Abstract method which should return SVG element
   * @param parent Parent box
   */
  public abstract render(parent: ItemBox): React.ReactNode;
}
