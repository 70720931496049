import { ReactComponent as Fullscreen } from '@mdi/svg/svg/fullscreen.svg';
import cx from 'classnames';
import * as React from 'react';

import * as s from './FullscreenControlButton.m.less';

export const FullscreenControlButton: React.FC<
  React.HTMLAttributes<HTMLButtonElement>
> = function FullscreenControlButton({ className, ...props }) {
  return (
    <button
      aria-label="Toggle Fullscreen"
      className={cx(s.root, className)}
      type="button"
      {...props}
    >
      <Fullscreen />
    </button>
  );
};
