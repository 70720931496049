import { SVGAttributes } from 'react';

import { Base } from './base';
import { ItemBounds, ItemBox, TemplateItemText } from './types';
import { getLength, getPadding } from './utils';

/**
 * Default line height
 */
const lineHeight = 1.3;

/**
 * Breaks text into lines to fit into provided width with specified font
 * @param text Text to break
 * @param maxWidth Width to fit
 * @param font Font to measure
 * @returns Split lines as well as line width
 */
const breakText = (text: string, maxWidth: number, font: string): string[] => {
  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');

  const measureWidth = (txt: string): number => {
    ctx.font = font;
    return ctx.measureText(txt).width;
  };

  const lines: string[] = [];

  const blocks = text.split(/\s{2,}/);

  blocks.forEach((block) => {
    let line = '';

    const words = block.split(/\s/);
    words.forEach((word) => {
      const next = `${line ? `${line} ` : line}${word}`;
      const nw = measureWidth(next);
      if (nw > maxWidth) {
        lines.push(line);
        line = word;
      } else {
        line = next;
      }
    });

    if (line) {
      lines.push(line);
    }
  });

  return lines;
};

/**
 * Text template item class
 */
export class Text extends Base<TemplateItemText> {
  /**
   * Text split into lines
   */
  protected lines: string[];

  /**
   * Calculated font height
   */
  protected fontHeight: number;

/**
 * Returns font style for the given text item
 * @param item Text item
 * @returns style
 */
  protected getFontStyle(): string {
    return this.prop('style') ?? 'normal';
  }

  /**
   * Split text into lines and measure block size
   * @param parent Parent box
   * @returns Box dimensions
   */
  public measure(parent: ItemBox): ItemBounds {
    this.fontHeight = getLength(this.prop('size'), this.root.bbox);
    const font = [
      this.getFontStyle(),
      `${this.fontHeight}px`,
      this.item.font,
    ].join(' ');

    const w = getLength(this.item.w ?? '100w', parent);

    const padding = getPadding(this.item, parent);
    const pw = w - (padding[1] + padding[3]);
    const lines = breakText(this.prop('text'), pw, font);

    this.lines = lines;

    const ph = lines.length * this.fontHeight
      + this.fontHeight * (lineHeight - 1);

    const h = ph + (padding[0] + padding[2]);

    const bbox = {
      w, h, ...this.position(parent, { w, h }),
    };

    return { bbox, pbox: this.pbox(parent, bbox) };
  }

  /**
   * Calculates SVG attributes for <text> node
   * @param box Box to fit
   * @returns SVG <text> attributes
   */
  protected svgAttrs(box: ItemBox): SVGAttributes<SVGElement> {
    const attrs = super.svgAttrs(box);

    attrs.fontFamily = this.prop('font');
    attrs.fontSize = `${this.fontHeight}px`;

    const align = this.prop('align') ?? 'center';
    attrs.textAnchor = (align === 'left' && 'start')
                    || (align === 'right' && 'end')
                    || 'middle';

    attrs.x = (align === 'left' && box.x)
           || (align === 'right' && box.x + box.w)
           || (box.x + box.w / 2);
    attrs.y = box.y;

    const style = this.getFontStyle();
    if (style === 'bold') {
      attrs.fontWeight = 'bold';
    } else if (style === 'italic') {
      attrs.fontStyle = 'italic';
    }

    return attrs;
  }

  /**
   * Renter text SVG entry
   * @param parent Box to fit
   * @returns <text> node
   */
  render(parent: ItemBox): React.ReactNode {
    const { pbox } = this.measure(parent);
    const { y } = pbox;
    const attrs = this.svgAttrs(pbox);

    return (
      <text {...attrs}>
        {this.lines.map((t, idx) => (
          <tspan
            // eslint-disable-next-line react/no-array-index-key
            key={`${idx}-${t}`}
            x={attrs.x}
            y={y + (idx + 1) * this.fontHeight}
          >
            {t}
          </tspan>
        ))}
      </text>
    );
  }
}
