import * as cx from 'classnames';
import * as React from 'react';
import { Form } from 'react-bootstrap';
import { useMutation } from 'react-query';
import { Link } from 'react-router-dom';
import { exprts, HttpError, JSONError, What } from 'weplayed-typescript-api';

import { Button } from 'common/components/Button';
import { SocialIcon } from 'common/components/SocialIcon';
import { AspectRatioProportions } from 'common/constants';
import { useHandleQuery } from 'common/hooks/useHandleQuery';
import { formatDuration } from 'common/utils/time';

import { PopupPlayer } from 'cms/components/PopupPlayer';
import { ExportSettings } from 'cms/components/PopupPlayer/types';
import { useExports } from 'cms/hooks/useExports';
import { SETTINGS_DIRECT_SHARE$ } from 'cms/routes';

import { Limitations } from './constants';
import * as s from './ShareSidebar.m.less';
import { DirectShareProps, ShareData } from './types';
import { isTypeOf } from './utils';

/**
 * Return entity name depending on entity typr
 * @param context Share context
 * @returns Entitiy name or empty string
 */
const getText = <T extends What>(context: ShareData<T>): string => {
  if (isTypeOf(context, What.MOMENTS) || isTypeOf(context, What.COLLECTIONS)) {
    return context.items[0].name;
  }

  return '';
};

/**
 * Direct Share component
 * @param param Properties
 * @returns JSX
 */
export const DirectShare = function DirectShare<T extends What>({
  context,
  onClose,
  user,
}: DirectShareProps<T>): JSX.Element {
  // targets (social networks) where to share the video
  const targets = user.direct_share_destinations || [];

  // just a flag that user has direct share targets registered
  const hasSocialNetworks = targets.length !== 0;

  // share call
  const { share } = useExports();

  // Text message to add with the video
  const [text, setText] = React.useState(() => getText(context));

  // toggle player to change aspect ratio
  const [showPlayer, setShowPlayer] = React.useState(false);

  const [exportSettings, setExportSettings] = React.useState<ExportSettings>(null);

  // temporary storage for aspect ratio/crop boxes until user presses
  // Done or Cancel button in Popup Player
  const temp = React.useRef<ExportSettings>(null);

  // handle selected share targets
  const [selected, toggleTarget] = React.useReducer(
    (state, t: string) => (state.includes(t) ? state.filter((tt) => tt !== t) : [...state, t]),
    [] as string[],
  );

  // share call state and invoker
  const [direct, result] = useMutation<void, HttpError, void, void>(async () => {
    await share(
      context.type as exprts.WhatExport,
      [context.items[0].pk],
      selected,
      text,
      exportSettings?.ratio
        ? {
          aspect_ratio: AspectRatioProportions[exportSettings.ratio],
          boxes: exportSettings.boxes,
          overlay: exportSettings.overlay?.image,
          overlay_end: exportSettings.overlay?.image_end,
        }
        : null,
    );
  });

  // nice progress bar at the top and error handling
  useHandleQuery(result);

  // calculate resulting video length
  const videoLength = React.useMemo(() => (
    isTypeOf(context, What.MOMENTS) && (context.items[0].end - context.items[0].start)
  ) || (
    isTypeOf(context, What.COLLECTIONS) && context.items[0].duration
  ), [context]);

  // a list of allowed targets depending on video length
  const allowedTargets = React.useMemo(() => {
    if (
      !context?.items?.[0]
      || (!isTypeOf(context, What.MOMENTS) && !isTypeOf(context, What.COLLECTIONS))
    ) {
      return [];
    }

    return Object.entries(Limitations)
      .filter(([, v]) => videoLength < v.videoLength)
      .map(([k]) => k);
    }, [context, videoLength]);

  // max length of text depending on share targets
  const maxLength = React.useMemo(() => {
    const max = Object.entries(Limitations)
      .filter(([k]) => selected.includes(k))
      .map(([, v]) => v.textLength);

    return max.length ? Math.min(...max) : 0;
  }, [selected]);

  // handle text change
  const handleText = React.useCallback((e) => setText(e.target.value), []);

  // handle crop boxes change from Popup Player
  const handleExportSettings = React.useCallback((settings) => {
    temp.current = settings;
  }, []);

  // handle accept of picked crop boxes
  const handleDone = React.useCallback(() => {
    setShowPlayer(false);
    setExportSettings(temp.current);
    temp.current = null;
  }, []);

  // drop off selected crop boxes
  const handleCancel = React.useCallback(() => {
    setShowPlayer(false);
    temp.current = null;
  }, []);

  // handle button click to select crop box(es)
  const handleAspectRatios = React.useCallback(() => {
    setShowPlayer(true);
  }, []);

  // close share sidebar on success
  React.useEffect(() => {
    if (result.isSuccess) {
      onClose();
      result.reset();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onClose, result.isSuccess]);

  // render nothing if no items or entity type is not supported
  if (
    !context?.items?.length
    || (!isTypeOf(context, What.MOMENTS) && !isTypeOf(context, What.COLLECTIONS))
  ) {
    return null;
  }

  // error message if text is too long to fin into one (or all) selected
  // share targets
  const textError = !text || text.length > maxLength;

  const momentId = (showPlayer && isTypeOf(context, What.MOMENTS) && context.items.length === 1)
    ? context.items[0].pk
    : null;

  const collectionId = (showPlayer
        && isTypeOf(context, What.COLLECTIONS) && context.items.length === 1)
    ? context.items[0].pk
    : null;

  return (
    <div>
      {hasSocialNetworks && (
        <div>Click on Social Network logos to select destinations:</div>
      )}
      <div className={s.destinations}>
        <div className={s.target}>
          {!hasSocialNetworks && (
            'You do not have social networks attached to your organization.'
          )}
          {hasSocialNetworks && (
            <ul className={s.list}>
              {targets.map((target) => {
                const unavail = !allowedTargets.includes(target);
                return (
                  <button
                    className={cx(selected.includes(target) && s.selected)}
                    disabled={unavail}
                    key={target}
                    onClick={(): void => toggleTarget(target)}
                    title={unavail ? 'Not available because of video conditions' : null}
                    type="button"
                  >
                    <SocialIcon target={target} className={s.icon} />
                  </button>
                );
              })}
            </ul>
          )}
        </div>
        <div className={s.manage}>
          <Button
            as={Link}
            onClick={onClose}
            size="sm"
            to={SETTINGS_DIRECT_SHARE$.buildPath()}
          >
            Manage Social Networks
          </Button>
          &nbsp; | &nbsp;
          <Button
            disabled={!hasSocialNetworks}
            onClick={handleAspectRatios}
            size="sm"
          >
            Export parameters
          </Button>
        </div>
      </div>
      {hasSocialNetworks && (
        <>
          <Form.Control as="textarea" rows={3} value={text} onChange={handleText} />
          <div className={s.stats}>
            <div>
              {`Video length: ${formatDuration(videoLength)}`}
            </div>
            {maxLength ? (
              <div className={cx(textError && s.error)}>
                {`${text.length} of ${maxLength} characters`}
              </div>
            ) : ''}
          </div>
          {result?.isError && (
            <div className={s.error}>
              {(result.error?.json?.errors as JSONError)?.detail}
            </div>
          )}
          <div className={s.buttons}>
            <Button
              disabled={textError || selected.length === 0 || result.isLoading}
              loading={result.isLoading}
              onClick={direct}
              size="lg"
            >
              Share
            </Button>
          </div>
        </>
      )}
      <PopupPlayer
        exportSettings={exportSettings}
        momentId={momentId}
        collectionId={collectionId}
        user={user}
        onClose={handleCancel}
        onDone={handleDone}
        onCancel={handleCancel}
        onExportSettings={handleExportSettings}
      />
    </div>
  );
};
