import * as React from 'react';
import { Helmet } from 'react-helmet';
import { Link, useLocation, useParams } from 'react-router-dom';
import { JSONError } from 'weplayed-typescript-api';

import { Button } from 'common/components/Button';
import { useHandleQuery } from 'common/hooks/useHandleQuery';
import { useLoadOrg } from 'common/hooks/useLoadOrg';
import { useProfile } from 'common/hooks/useProfile';
import {
  Registration as RegistrationPayload,
} from 'common/hooks/useProfile/types';
import { useUserInvite } from 'common/hooks/useUserInvite';
import { CMS_CONTEXT } from 'common/utils/cms';
import { fromBackendResponse, MappedFieldErrors } from 'common/utils/errors';
import { getCmsRedirect } from 'common/utils/redirects';

import { RegistrationForm } from 'cms/components/RegistrationForm';
import { Stage } from 'cms/components/RegistrationForm/types';
import { TeamImage } from 'cms/components/TeamImage';
import { CLIENT_URLS } from 'cms/routes';

import * as s from './Registration.m.less';
import { UrlProps } from './types';

export const Registration: React.FC = function Registration() {
  const [loadInvitation, setLoadInvitation] = React.useState(true);
  const location = useLocation();
  const { org_slug, invitation_pk } = useParams<UrlProps>();

  const namespace = org_slug || CMS_CONTEXT();
  const data = useLoadOrg(namespace);
  const org = data.data;

  const {
    subscription, saveSubscription: subscribe, register,
  } = useProfile();

  const { invitation, accept } = useUserInvite(invitation_pk, loadInvitation);

  React.useEffect(() => {
    if (accept[1].isSuccess) {
      setLoadInvitation(false);
    }
  }, [accept[1].isSuccess]);

  useHandleQuery(data, invitation, subscribe[1], register[1], accept[1]);

  const [errors, setErrors] = React.useState<MappedFieldErrors>(null);

  const registration: RegistrationPayload = React.useMemo(() => ({
    first_name: subscription.firstName || '',
    last_name: subscription.lastName || '',
    email: invitation.data?.user_email || subscription.email || '',
    password1: '',
    password2: '',
    username: invitation.data?.username || '',
  }), [
    invitation.data,
  ]);

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [
    register[1].isLoading,
    subscribe[1].isLoading,
    accept[1].isLoading,
  ]);

  React.useEffect(() => {
    const json = register[1].error?.json || accept[1].error?.json;

    if (json) {
      const err = fromBackendResponse(json.errors as JSONError[] || []);
      setErrors(err);
    } else {
      setErrors(null);
    }
  }, [
    register[1].error?.json,
    accept[1].error?.json,
  ]);

  const handleSubmit = React.useCallback((stage: Stage, payload: RegistrationPayload) => {
    if (stage === Stage.general) {
      subscribe[0]({
        ...subscription,
        firstName: payload.first_name,
        lastName: payload.last_name,
        email: payload.email,
      });
    } else
    /* istanbul ignore else */
    if (stage === Stage.access) {
      if (invitation_pk) {
        accept[0](payload);
      } else {
        register[0](payload);
      }
    }
  }, [
    invitation_pk,
    subscription,
    subscribe[0],
    register[0],
    accept[0],
  ]);

  const logo = org && (
    <div className={s.org} data-testid="registration-org-logo">
      <h1>{`${org.name} Athletics Registration`}</h1>
      <TeamImage team={org} size="x-large" className={s.logo} />
      {invitation.data && !invitation.data.error_message && !accept[1].isSuccess && (
        <p data-testid="invitation-text">
          You&apos;re about to accept invitation for «
          {invitation.data.role_name}
          » role
          {invitation.data.sports.length ? (
            <>
              {' '}
              for
              {' '}
              {invitation.data.sports.map((sport_name, idx, arr) => {
                let str = sport_name;
                if (idx < arr.length - 2) {
                  str += ', ';
                } else if (idx < arr.length - 1) {
                  str += ' and ';
                }
                return str;
              }).join('')}
              {` sport${invitation.data.sports.length > 1 ? 's' : ''}`}
            </>
          ) : null}
        </p>
      )}
    </div>
  );

  if (data.isLoading
    || (namespace && !org)
    || (org
        && invitation.data
        && !invitation.data.error_message
        && namespace !== invitation.data.organization_slug
    )
  ) {
    return null;
  }

  const redirect = namespace
    ? getCmsRedirect({ cms_org_namespace: namespace }, CLIENT_URLS.AUTH.LOGIN.toPath())
    : undefined;

  return (
    <div className="container" data-testid="container">
      <Helmet>
        <title>WePlayed: Sign Up</title>
      </Helmet>
      <div className="row">
        <div className="col-lg-6 offset-lg-3">
          {(register[1].isSuccess || accept[1].isSuccess) ? (
            <>
              {logo || <h1>ACCOUNT CREATED</h1>}
              <p>Thank you for registering with us!</p>
              <p>Please proceed to the Sign-In page to continue</p>
              {redirect ? (
                <Button
                  as="a"
                  data-testid="button-reg-done"
                  href={redirect}
                  id="button-reg-done"
                  size="lg"
                >
                  Sign In to Continue
                </Button>

              ) : (
                <Button
                  as={Link}
                  data-testid="button-reg-done"
                  id="button-reg-done"
                  size="lg"
                  to={{
                    pathname: CLIENT_URLS.AUTH.LOGIN.toPath({ org_slug }),
                    search: location.search || '',
                  }}
                >
                  Sign In to Continue
                </Button>
              )}
            </>
          ) : (
            <>
              <div className={s.header}>
                {logo || (
                  <>
                    <h1>SIGN UP</h1>
                    <p>
                      Become part of the most exciting community in college
                      sports, with full access to game video, athletes, and
                      teams. All for free.
                    </p>
                  </>
                )}
              </div>
              {(!invitation_pk && (
                <p data-testid="invitation-no-registration">
                  Please contact your Athletic Director to receive
                  an invite to the WePlayed Sports CMS
                </p>
              )) || ((invitation.data?.error_message || invitation.error) && (
                <h4 className={s.invitationError} data-testid="invitation-error-message">
                  {invitation.data?.error_message || 'Invitation not found'}
                </h4>
              )) || (
                <RegistrationForm
                  readonly={invitation_pk ? ['email'] : undefined}
                  org_slug={org?.slug}
                  registration={registration}
                  onSubmit={handleSubmit}
                  errors={errors || null}
                  avatar={!invitation_pk}
                />
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
};
