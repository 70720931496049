import * as cx from 'classnames';
import { curry, fromPairs, memoize, toPairs } from 'lodash/fp';
import * as React from 'react';
import { Form, Modal } from 'react-bootstrap';
import { toast } from 'react-toastify';

import { Button } from 'common/components/Button';
import { EmbedParams } from 'common/types';
import { EventContext, EVENTS, weplayedEvent } from 'common/utils/events';
import { logger } from 'common/utils/logger';

import {
  embedParamsDefault, embedParamsDescription,
} from 'cms/containers/EmbedRouter/constants';
import { CLIENT_URLS } from 'cms/routes';

import {
  approxCoef, defaultWidth, maxWidth, minWidth, widthStep,
} from './contants';
import * as s from './EmbedModal.m.less';
import { Props, State } from './types';

const descPairs = toPairs(embedParamsDescription) as [[ keyof EmbedParams, string ]];

const toHeight = (width: number): number => Math.round(width * approxCoef);

export class EmbedModal extends React.PureComponent<Props, State> {
  private codeTextArea: React.RefObject<HTMLTextAreaElement> = React.createRef();

  public state: State = {
    embed: '',
    iframe: '',
    params: { ...embedParamsDefault },
    width: defaultWidth,
    enteredWidth: defaultWidth,
    height: toHeight(minWidth),
    loading: true,
  };

  protected handleParamsChange = memoize(curry(
    (key: keyof EmbedParams, _e): void => {
      this.setState((state: State): State => ({
        ...state,
        params: {
          ...state.params,
          [key]: !state.params[key],
        },
      }));
    },
  ));

  public componentDidMount(): void {
    this.setEmbed();
    window.addEventListener('message', this.handleMessage);
  }

  public componentDidUpdate(_prevProps, prevState): void {
    if (this.state.params !== prevState.params
        || this.state.width !== prevState.width
        || this.state.height !== prevState.height
    ) {
      this.setEmbed();
    }
  }

  public componentWillUnmount(): void {
    window.removeEventListener('message', this.handleMessage);
  }

  protected updateWidthInput = (event): void => {
    const width = parseInt((event.target as HTMLInputElement).value, 10);
    if (width !== this.state.enteredWidth) {
      this.setState({
        enteredWidth: width,
      });
    }
  };

  protected handleSetWidth = (): void => {
    if (this.state.width !== this.state.enteredWidth) {
      const stateUpdate = {
        width: this.state.enteredWidth,
        height: toHeight(this.state.enteredWidth),
      };
      this.setState(stateUpdate);
    }
  };

  protected handleCopyClick = (): void => {
    this.codeTextArea.current.select();
    document.execCommand('copy');
    toast.success('Embed code was copied to clipboard');

    weplayedEvent({
      context: EventContext.ADMIN,
      moment_id: this.props.momentId,
      type: EVENTS.MOMENT_EMBED,
    });

    if (this.props.onClose) {
      this.props.onClose();
    }
  };

  protected handleMessage = ({ data, origin }: MessageEvent): void => {
    if (typeof data === 'string' && Math.max(
      origin.indexOf('weplayed.com'),
      origin.indexOf('localhost'),
    ) > 0) {
      try {
        const { height } = JSON.parse(data);
        this.setState({ height, loading: false });
      } catch (_e) {
        logger.error('Got MessageEvent that could not be parsed to new height', { data, origin });
      }
    }
  };

  protected getIframeCode = (
    width: number,
    height: number,
    params: EmbedParams,
  ): string => {
    const queryParams = fromPairs(
      toPairs(params)
        .filter(([key, value]): boolean => embedParamsDefault[key] !== value),
    ) as unknown as { [key: string]: string };
    queryParams.utm_source = 'weplayed';
    queryParams.utm_medium = 'embed';
    queryParams.utm_content = 'mo-00';

    queryParams.m = this.props.momentId;
    const path = CLIENT_URLS.MOMENT.EMBED.buildPath({ queryParams });
    const iframe = {
      src: new URL(path, window.location.toString()).toString(),
      width,
      height,
      frameborder: 'no',
      allowtransparency: true,
      scrolling: 'no',
      allow: 'autoplay;fullscreen',
    };

    return `<iframe ${
      Object.entries(iframe).map(([k, v]) => `${k}="${encodeURI(String(v))}"`).join(' ')
    }></iframe>`;
  };

  protected setEmbed = (): void => {
    this.setState((state: State): State => {
      const iframe = this.getIframeCode(state.width, 100, state.params);

      return {
        ...state,
        // set loading state when iframe code changed only
        loading: state.iframe !== iframe,
        embed: this.getIframeCode(state.width, state.height, state.params),
        iframe,
      };
    });
  };

  public render(): JSX.Element {
    return (
      <Modal
        show
        keyboard
        onHide={this.props.onClose}
        onEscapeKeyDown={this.props.onClose}
        backdropClassName="modal-backdrop-blackout"
      >
        <Modal.Header>
          <Modal.Title>Moment embed code</Modal.Title>
        </Modal.Header>
        <Modal.Body className={this.state.loading && s.loading}>
          <div className="row">
            <div className="col-12 col-sm-4">
              <Form.Group controlId="iframeWidth">
                <Form.Label>Width</Form.Label>
                <div className={s.widthContainer}>
                  <Form.Control
                    className={s.widthInput}
                    as="input"
                    placeholder="width"
                    // disabled={this.state.loading}
                    min={minWidth}
                    max={maxWidth}
                    step={widthStep}
                    type="number"
                    defaultValue={`${this.state.width}`}
                    onChange={this.updateWidthInput}
                  />
                  <button
                    type="button"
                    className={cx('btn', 'btn-primary', s.widthSetButton)}
                    onClick={this.handleSetWidth}
                    disabled={this.state.width === this.state.enteredWidth}
                  >
                    Set
                  </button>
                </div>
              </Form.Group>
            </div>
            <div className="col-12 col-sm-8">
              {descPairs.map(([key, desc]): JSX.Element => (
                <Form.Check
                  key={key}
                  type="checkbox"
                  disabled={this.state.loading}
                  checked={Boolean(this.state.params[key])}
                  onChange={this.handleParamsChange(key)}
                  label={desc}
                />
              ))}
            </div>
          </div>
          <textarea
            className={s.textArea}
            ref={this.codeTextArea}
            value={this.state.embed}
            rows={4}
            readOnly
          />
          {this.state.loading && (
            <div
              // hide iframe
              className={s.iframe}
              dangerouslySetInnerHTML={{
                __html: this.state.iframe,
              }}
            />
          )}
        </Modal.Body>

        <Modal.Footer>
          <Button
            variant="primary"
            type="button"
            disabled={this.state.loading}
            loading={this.state.loading}
            onClick={this.handleCopyClick}
          >
            Copy Code
          </Button>

          <button
            type="button"
            className="btn btn-default"
            onClick={this.props.onClose}
          >
            Close
          </button>
        </Modal.Footer>
      </Modal>
    );
  }
}
