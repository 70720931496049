import {
  endOfMonth, endOfWeek, format as formatFns, startOfMonth, startOfWeek,
} from 'date-fns';
import { isNumber } from 'lodash/fp';

export const dateFormat = 'MMM d, yyyy';
export const dateFormatTZ = `${dateFormat} OO`;
export const timeFormat = 'h:mm aa zzz';
export const dateTimeFormat = `${dateFormat} ${timeFormat}`;
const weekMs = 7 * 24 * 60 * 60 * 1000;

export const formatDate = (
  date: Date | number | string,
  format = dateFormat,
  text = '—',
): string => {
  if (!date) {
    return text;
  }

  let parsedDate;
  if (typeof date === 'string') {
    parsedDate = new Date(date);
    parsedDate = new Date(parsedDate.valueOf() + parsedDate.getTimezoneOffset() * 60 * 1000);
  } else {
    parsedDate = date;
  }
  return formatFns(parsedDate, format);
};

export const expandSeason = (season: string | number): string => `${season}-${String(+season + 1).substring(2)}`;

interface DateValues {
  da?: number;
  ho?: number;
  mi?: number;
  mo?: number;
  ms?: number;
  se?: number;
  ye?: number;
  time?: 'b' | 'e';
}

export const setDateTime = (
  date: Date,
  { ye, mo, da, ho, mi, se, ms, time }: DateValues,
): Date => {
  const newDate = new Date(date);

  if (isNumber(ye)) { newDate.setFullYear(ye); }
  if (isNumber(mo)) { newDate.setMonth(mo); }
  if (isNumber(da)) { newDate.setDate(da); }
  if (time === 'b') {
    newDate.setHours(0, 0, 0, 0);
  }
  if (time === 'e') {
    newDate.setHours(23, 59, 59, 999);
  }
  if (isNumber(ho)) { newDate.setHours(ho); }
  if (isNumber(mi)) { newDate.setMinutes(mi); }
  if (isNumber(se)) { newDate.setSeconds(se); }
  if (isNumber(ms)) { newDate.setMilliseconds(ms); }

  return newDate;
};

type DatePair = [Date, Date];

const week = (
  date: Date,
): DatePair => [startOfWeek(date), endOfWeek(date)];

const month = (
  date: Date,
): DatePair => [startOfMonth(date), endOfMonth(date)];

export const lastMonth = (
  date?: Date,
): DatePair => {
  const now = new Date(date || new Date());
  now.setDate(0);
  return month(now);
};

export const lastWeek = (
  date?: Date,
): DatePair => week(new Date((date || new Date()).getTime() - weekMs));

export const thisMonth = (
  date?: Date,
): DatePair => month(date || new Date());

export const thisWeek = (
  date?: Date,
): DatePair => week(date || new Date());

export const today = (
  date?: Date,
): DatePair => {
  const now = date || new Date();
  return [
    setDateTime(now, { time: 'b' }),
    setDateTime(now, { time: 'e' }),
  ];
};

export const nextWeek = (
  date?: Date,
): DatePair => week(new Date((date || new Date()).getTime() + weekMs));

export const nextMonth = (
  date?: Date,
): DatePair => {
  const now = date || new Date();
  if (now.getMonth() === 11) {
      return month(new Date(now.getFullYear() + 1, 0, 5));
  }

  return month(new Date(now.getFullYear(), now.getMonth() + 1, 5));
};
