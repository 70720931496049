import * as cx from 'classnames';
import { groupBy, memoize } from 'lodash/fp';
import * as React from 'react';
import { Collection, Moment, What, WhatType } from 'weplayed-typescript-api';

import { Button } from 'common/components/Button';

import * as s from './SelectPanel.m.less';
import { SelectPanelProps } from './types';

export const SelectPanel = function SelectPanel<
T extends What
>({
  deleting, items, selection, children, type,
  onSelect, onLike, onDelete, onDownload, onCollection,
}: React.PropsWithChildren<SelectPanelProps<T>>): JSX.Element {
  const handleSelectAll = React.useCallback(() => onSelect(items), [onSelect, items]);
  const handleSelectNone = React.useCallback(() => onSelect([]), [onSelect]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleAction = React.useCallback(
    memoize((cb: (items: Array<WhatType<T>>) => void) => (): void => {
      cb(selection);
      onSelect([]);
    }),
    [selection],
  );

  const disabled = !selection || selection.length === 0 || deleting;

  const isDeleteAllowed = selection && ((
    type === What.MOMENTS
      && (selection as Moment[]).every((m) => m.can_manage)
  ) || (
    type === What.COLLECTIONS && selection
      && (selection as Collection[]).every((m) => m.can_manage)
  ));

  const isDownloadAllowed = selection && ((
    type === What.MOMENTS
      && (selection as Moment[]).every((m) => m.can_download)
  ) || (
    type === What.COLLECTIONS
      && (selection as Collection[]).every((m) => m.can_download)
  ));

  const liked = groupBy(
    ({ followed_by_user, liked_by_user }) => (type === What.PLAYERS
      ? followed_by_user
      : liked_by_user),
    selection || [],
  );

  return (
    <div className={cx(s.root, (!selection || !selection.length || !items) && s.hidden)}>
      <div className={s.content}>
        <span>
          {`${selection?.length || 0} selected`}
        </span>
        <Button
          disabled={selection?.length === items?.length || disabled}
          onClick={handleSelectAll}
        >
          Select All
        </Button>
        <Button
          disabled={selection?.length === 0 || disabled}
          onClick={handleSelectNone}
        >
          Deselect All
        </Button>
        {onLike && (
          <button
            className={cx(
              s.like,
              liked.true && !liked.false && s.all,
              liked.false && !liked.true && s.none,
            )}
            disabled={disabled}
            onClick={handleAction(onLike)}
            title="Toggle Favorites"
            type="button"
          >
            Toggle Favorites
          </button>
        )}
        {onCollection && type === What.MOMENTS && (
          <button
            className={s.collection}
            disabled={disabled}
            onClick={handleAction(onCollection)}
            title="Add to Playlist"
            type="button"
          >
            Add to Playlist
          </button>
        )}
        {onDownload && [What.MOMENTS, What.COLLECTIONS].includes(type) && (
          <button
            className={s.download}
            disabled={disabled || !isDownloadAllowed}
            onClick={handleAction(onDownload)}
            title="Download"
            type="button"
          >
            Download
          </button>
        )}
        {onDelete && [What.MOMENTS, What.COLLECTIONS].includes(type) && (
          <button
            className={cx(s.remove, deleting && s.loading)}
            disabled={disabled || !isDeleteAllowed}
            onClick={handleAction(onDelete)}
            title="Delete"
            type="button"
          >
            Delete
          </button>
        )}
        {children}
      </div>
    </div>
  );
};
