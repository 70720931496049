import document from 'global/document';
import KeyArrowDownImg from 'images/key_arrow_down.png';
import KeyArrowLeftImg from 'images/key_arrow_left.png';
import KeyArrowRightImg from 'images/key_arrow_right.png';
import KeyArrowUpImg from 'images/key_arrow_up.png';
import KeyEnterImg from 'images/key_enter.png';
import KeySpaceImg from 'images/key_space.png';
import * as queryString from 'query-string';
import * as React from 'react';
import { Helmet } from 'react-helmet';

import { VideoPlayer } from 'common/components/VideoPlayer';

import { Props, State } from './types';

export class ClipperView extends React.Component<Props, State> {
  private positionTextbox: React.RefObject<HTMLInputElement> = React.createRef();

  private goToTextbox: React.RefObject<HTMLInputElement> = React.createRef();

  static getDerivedStateFromProps(props, state): State | null {
    if (props.data?.video && !state.video.streaming_url) {
      return {
        ...props.data,
        currentPosition: 0,
      };
    }
    return null;
  }

  constructor(props) {
    super(props);

    let gotoValue;
    let stopAtPositionValue;
    if (this.props.location.search) {
      const queryStringParams = queryString.parse(this.props.location.search);
      if (queryStringParams.goto) {
        gotoValue = Number((queryStringParams.goto as string).replace(',', ''));
      }
      if (queryStringParams.stop) {
        stopAtPositionValue = Number((queryStringParams.stop as string).replace(',', ''));
      }
    }

    this.state = {
      video: {},
      game: {},
      currentPosition: 0,
      onLoadJumpToPosition: gotoValue,
      stopAtPosition: stopAtPositionValue,
    } as State;
  }

  componentDidMount(): void {
    document.addEventListener('keydown', this.handleKeyDownEvent);

    if (this.state.onLoadJumpToPosition) {
      this.setState((state: State): State => ({
        ...state,
        goToPosition: state.onLoadJumpToPosition,
        onLoadJumpToPosition: undefined,
      }));
    }
  }

  componentWillUnmount(): void {
    document.removeEventListener('keydown', this.handleKeyDownEvent);
  }

  onPositionChanged = (position): void => {
    this.setState({ currentPosition: position, goToPosition: undefined });
  };

  onCopyPosition = (): void => {
    this.positionTextbox.current.select();
    document.execCommand('copy');
    this.setState({ recentlyCopied: +this.positionTextbox.current.value });
  };

  onGoToPosition = (): void => {
    const position = Number(this.goToTextbox.current.value.replace(',', ''));
    if (position >= 0) {
      this.setState({
        goToPosition: position,
      });
    }
  };

  handleKeyDownEvent = (event): void => {
    if (event.key === 'Enter') {
      this.onCopyPosition();
    }
  };

  render(): JSX.Element {
    if (!this.props.data) {
      return null;
    }

    return (
      <>
        <Helmet>
          <title>{`WePlayed: Clip Video: ${this.state.video.name}`}</title>
        </Helmet>
        <div className="container">
          <div className="row">
            <div className="col col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
              <h3>
                {this.state.video.name}
                {' '}
              </h3>
            </div>
          </div>
          <div className="row">
            <div
              className="col col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12"
            >
              <div className="row">
                <div className="col">
                  <VideoPlayer
                    end={this.state.stopAtPosition ? this.state.stopAtPosition : undefined}
                    loop={this.state.goToPosition > 0 && this.state.stopAtPosition > 0}
                    onPosition={this.onPositionChanged}
                    position={this.state.goToPosition}
                    start={this.state.goToPosition ? this.state.goToPosition : undefined}
                    url={this.state.video.streaming_url}
                  />
                </div>
              </div>
              <div className="row">
                <div
                  className="col"
                  style={{ textAlign: 'center' }}
                >
                  go to time:
                  <input
                    ref={this.goToTextbox}
                    type="textbox"
                    size={10}
                    style={{
                      backgroundColor: 'white',
                      borderStyle: 'solid',
                      borderColor: 'gray',
                      color: 'black',
                      borderRadius: 5,
                      textAlign: 'center',
                      marginLeft: 10,
                      marginRight: 10,
                    }}
                  />
                  <button
                    className="btn btn-primary"
                    onClick={this.onGoToPosition}
                    type="button"
                  >
                    go
                  </button>
                </div>
              </div>
            </div>
            <div
              className="col col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12"
            >
              <div className="row">
                <div
                  className="col"
                  style={{ textAlign: 'center' }}
                >
                  current time:
                  <input
                    ref={this.positionTextbox}
                    type="textbox"
                    size={10}
                    onClick={this.onCopyPosition}
                    value={Number(this.state.currentPosition).toFixed(1)}
                    readOnly
                    style={{
                      backgroundColor: 'gray',
                      borderStyle: 'solid',
                      borderColor: 'red',
                      color: 'black',
                      borderRadius: 5,
                      textAlign: 'center',
                      marginLeft: 10,
                      marginRight: 10,
                    }}
                  />
                  <button
                    className="btn btn-primary"
                    onClick={this.onCopyPosition}
                    type="button"
                  >
                    copy
                  </button>
                </div>
              </div>
              <div className="row">
                <div
                  className="col"
                  style={{ textAlign: 'center' }}
                >
                  {this.state.recentlyCopied
                    ? (
                      <>
                      recently copied:
                        {' '}
                        {this.state.recentlyCopied}
                      </>
                    )
                    : <>&nbsp;</>}
                </div>
              </div>
              <div
                className="row"
                style={{ marginLeft: 30 }}
              >
                <div className="col">
                  <hr />
                  <h4>keyboard shortcuts: </h4>
                  <img
                    src={KeyArrowUpImg}
                    width={30}
                    alt="up"
                    style={{
                      marginLeft: 30,
                      marginRight: 10,
                      marginBottom: 5,
                    }}
                  />
                  speed up
                  {' '}
                  <br />
                  <img
                    src={KeyArrowDownImg}
                    width={30}
                    alt="down"
                    style={{
                      marginLeft: 30,
                      marginRight: 10,
                      marginBottom: 5,
                    }}
                  />
                  slow down
                  {' '}
                  <br />
                  <img
                    src={KeyArrowLeftImg}
                    width={30}
                    alt="left"
                    style={{
                      marginLeft: 30,
                      marginRight: 10,
                      marginBottom: 5,
                    }}
                  />
                  jump back 3 seconds
                  {' '}
                  <br />
                  <img
                    src={KeyArrowRightImg}
                    width={30}
                    alt="right"
                    style={{
                      marginLeft: 30,
                      marginRight: 10,
                      marginBottom: 5,
                    }}
                  />
                  jump forward 3 seconds
                  {' '}
                  <br />
                  <img
                    src={KeySpaceImg}
                    width={60}
                    alt="space"
                    style={{
                      marginLeft: 30,
                      marginRight: 10,
                      marginBottom: 5,
                    }}
                  />
                  pause / resume
                  {' '}
                  <br />
                  <img
                    src={KeyEnterImg}
                    width={60}
                    alt="enter"
                    style={{
                      marginLeft: 30,
                      marginRight: 10,
                      marginBottom: 5,
                    }}
                  />
                  copy current time
                  {' '}
                  <br />
                </div>
              </div>
              <div
                className="row"
                style={{
                  marginLeft: 30,
                  marginTop: 10,
                  marginBottom: 5,
                }}
              >
                <hr />
                <h4>instructional video:</h4>
                <div
                  className="col"
                  style={{ textAlign: 'center' }}
                >
                  <a
                    href="https://www.youtube.com/watch?v=xwz7dTBi0qY"
                    target="blank"
                  >
                    (open in new window)
                  </a>
                  <br />
                  <iframe
                    title="youtube"
                    style={{
                      borderColor: 'gray',
                      borderStyle: 'solid',
                      borderWidth: 1,
                      width: 400,
                      height: 225,
                    }}
                    src="https://www.youtube.com/embed/xwz7dTBi0qY"
                    allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
