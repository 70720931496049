/**
 * For the logic behind drag and drop @see {@link EntitiesList} for details
 */
import cx from 'classnames';
import * as React from 'react';
import { PublishDestination } from 'weplayed-typescript-api';

import { Button } from 'common/components/Button';
import {
  DragData, getDataAttributes, getDataPk, getDragItemType, parseDragItemType,
} from 'common/utils/drag';

import { FadeInImage } from '../FadeInImage';
import { HTMLSwitch } from '../HTMLSwitch';
import * as s from './PublishSidebar.m.less';
import { PublishableType, PublishSidebarEntryProps } from './types';

const dragName = 'publish-sidebar';

export const PublishSidebarEntry = function PublishSidebarEntry<
  T extends PublishableType,
  W extends PublishDestination = PublishDestination
>({
  blocking,
  entry,
  item,
  names: [singular],
  onBlock,
  onPin,
  onPinEdit,
  where,
}: PublishSidebarEntryProps<T, W>): JSX.Element {
  const { name, pk, /* pinned, */ blocked, top } = entry;

  const domLevel = React.useRef(0);

  const [dragPosition, setDragPosition] = React.useState(-1);
  const [dragData, setDragData] = React.useState<DragData>(null);
  const [dropAllowed, setDropAllowed] = React.useState(false);

  const [isBlocking, setIsBlocking] = React.useState(false);

  React.useEffect(() => {
    if (!blocking) {
      setIsBlocking(false);
    }
  }, [blocking]);

  const dragIndex = React.useMemo(
    () => top?.data?.findIndex(({ pk: id }) => id === dragData?.pk),
    [dragData?.pk, top?.data],
  );

  const dragReset = React.useCallback((resetData = true) => {
    domLevel.current = 0;
    if (resetData) {
      setDragData(null);
    }
    setDragPosition(-1);
    setDropAllowed(false);
  }, []);

  const togglePin = React.useCallback(() => {
    if (top) {
      onPinEdit(null, null);
    } else {
      onPinEdit(where, pk);
    }
  }, [onPinEdit, pk, top, where]);

  const handleBlock = React.useCallback(
    () => {
      setIsBlocking(true);
      onBlock(where, pk, !blocked);
    },
    [onBlock, where, pk, blocked],
  );

  const handleDragStart = React.useCallback(
    (e: React.DragEvent): void => {
      const $id = getDataPk(e);
      const obj = top.data.find(({ pk: id }) => id === $id) ?? ($id === item?.pk && item);
      if (obj) {
        const data: DragData = { name: dragName, pk: obj.pk, what: where };
        const key = getDragItemType(data);

        e.dataTransfer.setData(key, JSON.stringify(obj));
        e.dataTransfer.dropEffect = 'move';
      }
    },
    [item, top?.data, where],
  );

  const handleDragEnd = React.useCallback((e: React.DragEvent): void => {
    e.preventDefault();
    dragReset();
  }, [dragReset]);

  const handleDrag = React.useCallback((e: React.DragEvent) => {
    if (!top?.data) {
      return;
    }

    if (e.type === 'dragover') {
      e.preventDefault();
      e.dataTransfer.dropEffect = 'move';

      if (!dropAllowed) {
        return;
      }

      const obj = top.data.find(({ pk: id }) => id === getDataPk(e));

      if (obj) {
        let pos = top.data.indexOf(obj);

        if (pos === dragPosition) {
          pos += 1;
        }

        if (pos === dragIndex) {
          pos += 1;
        }

        if (pos <= top.data.length - (dragIndex === -1 ? 1 : 0)) {
          setDragPosition(pos);
        }
      }
    } else if (e.type === 'drop') {
      e.preventDefault();

      if (dropAllowed) {
        const data: T = JSON.parse(e.dataTransfer.getData(getDragItemType(dragData)));
        onPin(
          data,
          {
            expires: 'generate',
            position: dragPosition - (dragIndex !== -1 && dragPosition > dragIndex ? 1 : 0),
          },
        );
      }

      dragReset();
    } else {
      domLevel.current += e.type === 'dragenter' ? 1 : -1;

      if (e.type === 'dragenter' && domLevel.current === 1) {
        e.preventDefault();

        const data: DragData | void = e.dataTransfer.types
          .map(parseDragItemType)
          .filter(Boolean)
          .shift();

        if (data) {
          setDragData(data);
          setDropAllowed(data.what === where && data.name === dragName);
          setDragPosition(top.data.findIndex(({ pk: id }) => id === data.pk));
        }
      } else if (e.type === 'dragleave' && domLevel.current === 0) {
        dragReset(dragIndex === -1);
      }
    }
  }, [dragData, dragIndex, dragPosition, dragReset, dropAllowed, onPin, top?.data, where]);

  const handlePin = React.useCallback((e: React.MouseEvent): void => {
    const id = getDataPk(e);
    const idx = top.data.findIndex(({ pk: $id }) => $id === id);
    if (idx !== -1) {
      const obj = top.data[idx];
      onPin(obj, !obj.pin || obj.pin.expires ? { position: idx } : false);
    }
  }, [onPin, top?.data]);

  const inTop = top?.data?.some(({ pk: id }) => id === item?.pk);
  const dragOver = dragPosition !== -1;

  return (
    <>
      <tr>
        <td className={s.tdName}>{name}</td>
        <td className={s.tdPin}>
          {!blocked && (
            <Button
              className={cx(s.edit, top?.data && s.open)}
              loading={top?.isLoading}
              onClick={togglePin}
              over
              type="button"
              value={pk}
            >
              Manage
              <span />
            </Button>
          )}
        </td>
        <td className={s.tdPublish}>
          <HTMLSwitch
            checked={!blocked}
            disabled={isBlocking && blocking}
            label={!blocked && 'Published'}
            loading={isBlocking && blocking}
            onChange={handleBlock}
          />
        </td>
      </tr>
      {top?.data && !blocked && (
        <tr>
          <td colSpan={3}>
            <div className={s.holder}>
              <div className={cx(s.source, inTop && s.disabled)}>
                <div
                  className={s.item}
                  draggable
                  onDragEnd={handleDragEnd}
                  onDragStart={handleDragStart}
                  {...getDataAttributes(item)}
                >
                  {!inTop && <FadeInImage src={item?.thumbnail} />}
                </div>
                <p className={s.help}>{`Drag this ${singular} to the right and drop to desired position`}</p>
              </div>
              <div
                className={s.items}
                onDragEnter={handleDrag}
                onDragLeave={handleDrag}
                onDragOver={handleDrag}
                onDrop={handleDrag}
              >
                {top.data?.map(({ pk: id, thumbnail, pin }, pos) => {
                  const visible = (dragOver && dragPosition === pos);
                  const hidden = dragOver && dragIndex === pos;

                  return (
                    <React.Fragment key={id}>
                      <div className={cx(s.item, !visible && s.hidden)} />
                      <div
                        className={cx(
                          s.item,
                          pin && !pin.expires && s.pinned,
                          hidden && s.hidden,
                        )}
                        draggable={id === item?.pk}
                        key={id}
                        onDragEnd={handleDragEnd}
                        onDragStart={handleDragStart}
                        {...getDataAttributes({ pk: id })}
                      >
                        <FadeInImage src={thumbnail} />
                        <button
                          disabled={id !== item?.pk}
                          onClick={handlePin}
                          type="button"
                        >
                          {pos + 1}
                        </button>
                      </div>
                    </React.Fragment>
                  );
              })}
              </div>
            </div>
          </td>
        </tr>
      )}
    </>
  );
};
