import * as React from 'react';
import { BaseEntityWithPk } from 'weplayed-typescript-api';

import { EntitiesListContext } from './constants';
import { EntitiesListContextType, PinViewType } from './types';

type UseControlsArgs<T> = {
  onLink?(item: T, target: HTMLElement): void;
  onMenu?(item: T, target: HTMLElement): void;
} & ({
  getLike(item: T): boolean;
  onLike(item: T, like: boolean): void
} | {
  getLike?: never;
  onLike?: never;
}) & ({
  getBlock(item: T): boolean;
  getPin(item: T): PinViewType;
  onBlock(item: T, block: boolean): void;
  onPin(item: T, pin: boolean): void;
} | {
  getBlock?: never;
  getPin?: never;
  onBlock?: never;
  onPin?: never;
});

interface UseControlsReturn {
  onBlock: React.MouseEventHandler | undefined;
  onLike: React.MouseEventHandler | undefined;
  onLink: React.MouseEventHandler | undefined;
  onMenu: React.MouseEventHandler | undefined;
  onPin: React.MouseEventHandler | undefined;
}

export const useControls = function useControls<T extends BaseEntityWithPk>({
  onBlock, getBlock,
  onLike, getLike,
  onLink,
  onMenu,
  onPin, getPin,
}: UseControlsArgs<T>): UseControlsReturn {
  const { findItem } = React.useContext(EntitiesListContext) as EntitiesListContextType<T>;

  const handleLike = React.useCallback<React.MouseEventHandler>((e) => {
    e.stopPropagation();
    const item = findItem(e);
    onLike(item, !getLike(item));
  }, [findItem, getLike, onLike]);

  const handleLink = React.useCallback<React.MouseEventHandler>((e) => {
    e.stopPropagation();
    const item = findItem(e);
    onLink(item, e.currentTarget as HTMLElement);
  }, [findItem, onLink]);

  const handleMenu = React.useCallback<React.MouseEventHandler>((e) => {
    e.stopPropagation();
    const item = findItem(e);
    onMenu(item, e.currentTarget as HTMLElement);
  }, [findItem, onMenu]);

  const handleBlock = React.useCallback<React.MouseEventHandler>((e) => {
    e.stopPropagation();
    const item = findItem(e);
    onBlock(item, !getBlock(item));
  }, [findItem, getBlock, onBlock]);

  const handlePin = React.useCallback<React.MouseEventHandler>((e) => {
    e.stopPropagation();
    const item = findItem(e);
    onPin(item, !getPin(item));
  }, [findItem, getPin, onPin]);

  return {
    onBlock: onBlock && getBlock && handleBlock,
    onLike: onLike && getLike && handleLike,
    onLink: onLink && handleLink,
    onMenu: onMenu && handleMenu,
    onPin: onPin && getPin && handlePin,
  };
};
