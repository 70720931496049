import { SVGAttributes } from 'react';

import { Base } from './base';
import { ItemBounds, ItemBox, OverlayProps, TemplateItemImage } from './types';
import { getLength } from './utils';

const empty = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII=';

/**
 * Image template item rendering class
 */

export class Image extends Base<TemplateItemImage> {
  constructor(
    root: OverlayProps,
    path: string,
    item: TemplateItemImage,
  ) {
    super(root, path, { ...item, url: item.url || empty });
  }

  /**
   * Measure image boxes
   * @param parent Parent box
   * @returns Box dimensions
   */
  public measure(parent: ItemBox): ItemBounds {
    const $w = this.prop('w');
    const $h = this.prop('h');

    const w = getLength($w ?? $h ?? '100w', parent);
    const h = getLength($h ?? $w ?? '100h', parent);

    const bbox = {
      w, h, ...this.position(parent, { w, h }),
    };

    return { bbox, pbox: this.pbox(parent, bbox) };
  }

  /**
   * Calculates SVG attributes for <image> SVG tag
   * @param box Box to fit
   * @returns <image> attributes
   */
  protected svgAttrs(box: ItemBox): SVGAttributes<SVGElement> {
    const attrs = super.svgAttrs(box);
    attrs.href = this.prop('url');
    attrs.x = box.x;
    attrs.y = box.y;
    attrs.width = box.w;
    attrs.height = box.h;
    attrs.preserveAspectRatio = 'xMidYMid';

    return attrs;
  }

  /**
   * Renders <image> SVG tag
   * @param parent Box to fit
   * @returns <image>
   */
  render(parent: ItemBox): React.ReactNode {
    const { pbox } = this.measure(parent);
    const attrs = this.svgAttrs(pbox);

    return <image {...attrs} />;
  }
}
