import * as React from 'react';
import { Form } from 'react-bootstrap';

import { AvatarUpload } from 'common/components/AvatarUpload';
import { Button } from 'common/components/Button';
import { Registration } from 'common/hooks/useProfile/types';
import {
  ERROR_EMAIL, ERROR_NAME, ERROR_PASSWORD, ERROR_PASSWORD_MATCH, ERROR_USERNAME,
  MappedFieldErrors,
} from 'common/utils/errors';
import { trimObject } from 'common/utils/helpers';
import {
  isEmailValid, isValidPassword, isValidUsername,
} from 'common/utils/validations';

import { Avatar } from 'cms/components/Avatar';

import * as s from './RegistrationForm.m.less';
import { Props, Stage } from './types';

export const RegistrationForm: React.FC<Props> = function RegistrationForm({
  avatar = true, errors: outerErrors, loading, onSubmit,
  org_slug, registration: outerRegistration, readonly = [],
}) {
  // const location = useLocation();
  const [stage, setStage] = React.useState<Stage>(org_slug ? Stage.general : Stage.general);
  const [registration, setRegistration] = React.useState<Registration>(outerRegistration);
  const [fullName, setFullName] = React.useState(
    registration?.first_name && registration?.last_name
      ? `${registration.first_name} ${registration.last_name}`
      : '',
  );
  const [errors, setErrors] = React.useState<MappedFieldErrors>(outerErrors || {});

  React.useEffect(() => {
    setRegistration(outerRegistration);
  }, [outerRegistration]);

  React.useEffect(() => {
    setErrors(outerErrors || {});

    if (outerErrors?.full_name || outerErrors?.email) {
      setStage(Stage.general);
    }
  }, [outerErrors]);

  const handleChange = React.useCallback((e: React.ChangeEvent<HTMLInputElement>): void => {
    const { name, value } = e.target;

    if (name === 'full_name') {
      const [first_name = '', last_name = ''] = value
        .trim()
        .split(/\s+/, 2);
      setFullName(value);
      setRegistration({ ...registration, first_name, last_name });
    } else {
      setRegistration({ ...registration, [name]: value });
    }

    setErrors({ ...errors, [name]: undefined });
  }, [registration, errors]);

  const handleAvatar = React.useCallback((avatar_url): void => {
    setRegistration({ ...registration, avatar_url });
  }, [registration]);

  const handleSubmit = React.useCallback((e: React.FormEvent): void => {
    e.preventDefault();

    let payload: Partial<Registration> = { };

    if (stage === Stage.general) {
      payload = trimObject(['last_name', 'first_name', 'email'], registration);

      if (!payload.last_name) {
        setErrors({ full_name: ERROR_NAME });
        return;
      }

      if (!isEmailValid(payload.email)) {
        setErrors({ email: ERROR_EMAIL });
        return;
      }

      setStage(Stage.access);
    } else /* istanbul ignore else */ if (stage === Stage.access) {
      payload = trimObject(['username'], registration);

      if (!isValidUsername(payload.username)) {
        setErrors({ username: ERROR_USERNAME });
        return;
      }

      if (!isValidPassword(payload.password1)) {
        setErrors({ password1: ERROR_PASSWORD });
        return;
      }

      if (payload.password1 !== payload.password2) {
        setErrors({ password2: ERROR_PASSWORD_MATCH });
        return;
      }
    }

    setErrors({});

    payload = { ...registration, ...payload };
    setRegistration(payload as Registration);

    onSubmit(stage, payload as Registration);
  }, [registration, stage, onSubmit]);

  if (stage === Stage.general) {
    return (
      <Form onSubmit={handleSubmit}>
        {errors?.non_field_errors && (
          <p className="text-danger">{errors.non_field_errors}</p>
        )}
        <Form.Group controlId="input-reg-name">
          <Form.Label>Full Name</Form.Label>
          <Form.Control
            data-testid="input-reg-name"
            isInvalid={Boolean(errors.full_name)}
            name="full_name"
            onChange={handleChange}
            type="text"
            value={fullName}
          />
          <Form.Control.Feedback type="invalid">
            {errors.full_name}
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group controlId="input-reg-email">
          <Form.Label>Email</Form.Label>
          <Form.Control
            autoCapitalize="none"
            autoCorrect="off"
            data-testid="input-reg-email"
            disabled={readonly.includes('email')}
            isInvalid={Boolean(errors.email)}
            name="email"
            onChange={handleChange}
            readOnly={readonly.includes('email')}
            type="email"
            value={registration?.email || ''}
          />
          <Form.Control.Feedback type="invalid">
            {errors.email}
          </Form.Control.Feedback>
        </Form.Group>

        <div className="profile-form-save-button-container">
          <Button
            data-testid="button-reg-general-next"
            disabled={loading}
            id="button-reg-general-next"
            loading={loading}
            size="lg"
            type="submit"
          >
            Next &gt;
          </Button>
        </div>
      </Form>
    );
  }

  return (
    <Form onSubmit={handleSubmit}>
      {errors?.non_field_errors && (
        <p className="text-danger">{errors.non_field_errors}</p>
      )}
      <Form.Group controlId="input-reg-username">
        <Form.Label>Username (3-15 letters, numbers and underscores)</Form.Label>
        <Form.Control
          autoComplete="username"
          autoCapitalize="none"
          autoCorrect="off"
          data-testid="input-reg-username"
          disabled={readonly.includes('username')}
          isInvalid={Boolean(errors.username)}
          name="username"
          onChange={handleChange}
          readOnly={readonly.includes('username')}
          type="text"
          value={registration?.username || ''}
        />
        <Form.Control.Feedback type="invalid">
          {errors.username}
        </Form.Control.Feedback>
      </Form.Group>

      <Form.Group controlId="input-reg-password">
        <Form.Label>Password (8+ characters)</Form.Label>
        <Form.Control
          autoComplete="new-password"
          data-testid="input-reg-password"
          isInvalid={Boolean(errors.password1)}
          name="password1"
          onChange={handleChange}
          type="password"
          value={registration?.password1 || ''}
        />
        <Form.Control.Feedback type="invalid">
          {errors.password1}
        </Form.Control.Feedback>
      </Form.Group>

      <Form.Group controlId="input-reg-password2">
        <Form.Label>Repeat password</Form.Label>
        <Form.Control
          autoComplete="new-password"
          data-testid="input-reg-password2"
          isInvalid={Boolean(errors.password2)}
          name="password2"
          onChange={handleChange}
          type="password"
          value={registration?.password2 || ''}
        />
        <Form.Control.Feedback type="invalid">
          {errors.password2}
        </Form.Control.Feedback>
      </Form.Group>

      {avatar && (
        <div className={s.avatar}>
          <Avatar
            size="large"
            user={{ ...registration, full_name: `${registration?.first_name} ${registration?.last_name}` }}
            avatarUrl={registration?.avatar_url}
          />
          <AvatarUpload onSuccess={handleAvatar} />
        </div>
      )}

      <div className="profile-form-save-button-container">
        <Button
          data-testid="button-reg-access-back"
          disabled={loading}
          id="button-reg-access-back"
          onClick={(): void => setStage(Stage.general)}
          size="lg"
          type="button"
        >
          &lt; Back
        </Button>
        <Button
          data-testid="button-reg-access-next"
          disabled={loading}
          id="button-reg-access-next"
          loading={loading}
          size="lg"
          type="submit"
        >
          Sign Up!
        </Button>
      </div>
    </Form>
  );
};
