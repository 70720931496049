import cx from 'classnames';
import { GroupBase } from 'react-select';
import ReactSelectCreatableSelect from 'react-select/creatable';

import { CreatableSelectProps, Option } from './types';

export const CreatableSelect = function CreatableSelect<
  T = Option,
  IsMulti extends boolean = false,
  Group extends GroupBase<T> = GroupBase<T>
>({
  className,
  ...rest
}: CreatableSelectProps<T, IsMulti, Group>): React.ReactElement {
  return (
    <ReactSelectCreatableSelect<T, IsMulti, Group>
      className={cx('react-select__container', className)}
      classNamePrefix="react-select"
      {...rest}
    />
  );
};
