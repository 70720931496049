import cx from 'classnames';
import * as React from 'react';
import { Link, useLocation } from 'react-router-dom';

import { Button } from 'common/components/Button';
import { useApplication } from 'common/hooks/useApplication';
import { useProfile } from 'common/hooks/useProfile';

import FormField from 'cms/components/Field/FormField';
import { CLIENT_URLS } from 'cms/routes';

import * as s from './LoginForm.m.less';
import { Props } from './types';

export const LoginForm: React.FC<Props> = function LoginForm({
  className,
  showRegister = true,
  showForgot = true,
  usernameLabel = 'username',
  passwordLabel = 'password',
}) {
  const { login: [login, { error, isLoading }] } = useProfile();
  const location = useLocation();
  const { broadcast } = useApplication();

  const [username, setUsername] = React.useState('');
  const [password, setPassword] = React.useState('');

  const handleUsernameChange = React.useCallback(
    ({ target: { value } }) => setUsername(value),
    [setUsername],
  );

  const handlePasswordChange = React.useCallback(
    ({ target: { value } }) => setPassword(value),
    [setPassword],
  );

  const handleSubmit = React.useCallback((e) => {
    e.preventDefault();

    login({ username, password });
  }, [username, password]);

  React.useEffect(() => {
    if (error) {
      const message = error?.json?.errors?.[0]?.detail || 'Sorry can\'t login you right now';
      broadcast('error', message);
    }
  }, [error]);

  return (
    <form onSubmit={handleSubmit} className={cx(s.root, className)}>
      <FormField
        label={usernameLabel}
        name="username"
        required
      >
        <input
          // dot remove - for testing purposes
          id="input-login-username"
          autoCapitalize="none"
          autoCorrect="off"
          className="form-control"
          name="username"
          required
          type="text"
          value={username}
          onChange={handleUsernameChange}
        />
      </FormField>
      <FormField
        label={passwordLabel}
        name="password"
        required
      >
        <input
          // dot remove - for testing purposes
          id="input-login-password"
          className="form-control"
          name="password"
          required
          type="password"
          value={password}
          onChange={handlePasswordChange}
        />
      </FormField>
      <div className={s.buttonContainer}>
        <Button
          disabled={isLoading || !username || !password}
          loading={isLoading}
          size="lg"
          // dot remove - for testing purposes
          id="button-login-submit"
          type="submit"
        >
          Log In
        </Button>
      </div>
      {(showRegister || showForgot) && (
        <div className={s.links}>
          {showRegister && (
            <Link
              to={{
                pathname: CLIENT_URLS.AUTH.REGISTRATION.toPath(),
                search: location.search,
              }}
            >
              Don&apos;t have an account?
            </Link>
          )}
          {showForgot && (
            <Link
              to={CLIENT_URLS.AUTH.RESET_PASSWORD.toPath()}
            >
              Forgot password?
            </Link>
          )}
        </div>
      )}
    </form>
  );
};
