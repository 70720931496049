import * as cx from 'classnames';
import { sortBy } from 'lodash/fp';
import * as React from 'react';
import VisibilitySensor from 'react-visibility-sensor';
import { EmbedAdsSpec, Moment, What } from 'weplayed-typescript-api';

import { MomentPlayer } from 'common/components/MomentPlayer';
import { MomentTrimmer } from 'common/components/MomentTrimmer';
import { RefType } from 'common/components/MomentTrimmer/types';
import { ShareMeta } from 'common/types';
import {
  createMomentEventDispatcher, MomentEventDispatcher,
} from 'common/utils/moments';
import { getMomentShareMeta } from 'common/utils/share';

import { Button } from 'consumer/components/Button';
import { GameHeadline } from 'consumer/components/GameHeadline';
import { MomentActionBar } from 'consumer/components/MomentActionBar';
import {
  MomentDescriptionReadonly,
} from 'consumer/components/MomentDescription';
import { LinkGenerator } from 'consumer/components/MomentDescription/types';
import { UserHighlight } from 'consumer/components/UserHighlight';
import { isMomentCuratedByUser } from 'consumer/hooks/useMoments';
import { CLIENT_URLS } from 'consumer/routes';

import { Share } from '../Share';
import * as s from './MomentPresenter.m.less';
import { MomentPresenterInstance, Props, State } from './types';

const linkGenerator: LinkGenerator = (kind, uid) => {
  switch (kind) {
    case 'team': return CLIENT_URLS.HOME.DISCOVER.buildPath({ uid });
    case 'player': return CLIENT_URLS.PLAYER.VIEW.toPath({ uid });
    case 'tag': return CLIENT_URLS.SEARCH.PAGE.toPath({ queryParams: {
      q: uid.replace('#', ''),
    } });

    default: throw new Error(`Unknown kind ${kind}`);
  }
};

const instanceSortingFunc: (instances: MomentPresenterInstance[]) => MomentPresenterInstance[] = (
  sortBy(
    // eslint-disable-next-line no-use-before-define
    (instance: MomentPresenter) => (instance.visible ? instance.getOffset() : Number.MAX_VALUE),
  )
);

export class MomentPresenter extends React.Component<Props, State>
  implements MomentPresenterInstance {
  static fullscreen = false;

  protected trimmerRef: React.RefObject<RefType> = React.createRef();

  static defaultProps: Pick<Props, OptionalKeys<Props>> = {
    withLinks: true,
  };

  static getDerivedStateFromProps(props: Props, state: State): State | null {
    if (!state.moment && props.mode) {
      return {
        ...state,
        moment: props.moment,
        paused: true,
        trimmerPosition: props.moment.start,
      };
    }

    if (state.moment && !props.mode) {
      return {
        ...state,
        moment: undefined,
        paused: undefined,
        trimmerPosition: undefined,
      };
    }

    return null;
  }

  // unless refactored
  public readonly ref: React.RefObject<HTMLDivElement> = React.createRef();

  protected eventsDispatcher: MomentEventDispatcher;

  // eslint-disable-next-line react/no-unused-class-component-methods
  public visible = false;

  protected timer: number;

  constructor(props) {
    super(props);

    this.state = {
      active: false,
      share: this.getShareMeta(),
    };
  }

  public componentDidMount(): void {
    if (this.props.instances.indexOf(this) === -1) {
      this.props.instances.push(this);
    }
    this.eventsDispatcher = createMomentEventDispatcher(this.props.moment, this.props.context);
    this.toggle();
  }

  public componentDidUpdate(
    prevProps: Readonly<Props>,
    prevState: Readonly<State>,
  ): void {
    if (this.state.moment !== prevState.moment
        || this.props.share !== prevProps.share
        || this.props.wlOrgId !== prevProps.wlOrgId) {
      this.setState({ share: this.getShareMeta() });
    }
  }

  public componentWillUnmount(): void {
    const idx = this.props.instances.indexOf(this);
    if (idx !== -1) {
      this.props.instances.splice(idx, 1);
    }
    window.clearTimeout(this.timer);
  }

  // eslint-disable-next-line react/no-unused-class-component-methods
  public getOffset = (): number => (
    this.ref.current
      ? this.ref.current.getBoundingClientRect().top
        + (window.pageYOffset || document.documentElement.scrollTop)
      : Number.MAX_VALUE);

  // eslint-disable-next-line react/no-unused-class-component-methods
  public setActive = (active: boolean): void => {
    this.setState({ active });
    if (!active && this.state.moment && this.props.onCancel) {
      this.props.onCancel(this.props.moment);
    }
  };

  protected getShareMeta = (): ShareMeta | null => {
    const moment = this.state?.moment ?? this.props.moment;

    return this.props.share && moment ? {
      pk: moment.pk,
      type: What.MOMENTS,
      meta: getMomentShareMeta(moment, {
        wl_org_id: this.props.wlOrgId,
        namespace: this.props.wlOrgId ? '1' : undefined,
      }),
    } : null;
  };

  toggle = (): void => instanceSortingFunc(this.props.instances).forEach(
    (instance, idx) => instance.setActive(instance.visible && idx === 0),
  );

  handleFullscreen = (fullscreen: boolean): void => {
    if (fullscreen) {
      MomentPresenter.fullscreen = true;
    } else {
      this.timer = window.setTimeout(() => {
        if (this.state.active) {
          this.ref.current.scrollIntoView();
          this.timer = window.setTimeout(() => {
            MomentPresenter.fullscreen = false;
            this.toggle();
          }, 1000);
        }
      }, 200);
    }
  };

  handleVisibility = (visible: boolean): void => {
    // eslint-disable-next-line react/no-unused-class-component-methods
    this.visible = visible;
    if (visible && this.props.onPlayStarted) {
      this.props.onPlayStarted(this.props.moment);
    }

    if (!MomentPresenter.fullscreen) {
      this.toggle();
    }
  };

  handlePlayerPosition = (playerPosition: number): void => {
    this.trimmerRef.current?.setTime(playerPosition);
  };

  handleTrimmerPosition = (trimmerPosition: number): void => this.setState({ trimmerPosition });

  handleMoment = (moment: Moment<EmbedAdsSpec>): void => this.setState({ moment });

  handleSaveMoment = (): void => {
    if (this.props.onSave) {
      this.props.onSave(this.state.moment);
    }
  };

  render(): JSX.Element {
    const {
      mode, hideActionBar, hideCuratorHighlight, hideGameBar, user, context,
      withLinks, collection, saving, hideAdjust, className,
    } = this.props;
    const { active, trimmerPosition, share } = this.state;

    const moment = this.state.moment || this.props.moment;
    const paused = this.state.paused ?? this.props.paused ?? false;

    const ownMoment = isMomentCuratedByUser(moment, user);
    const adjust = mode === 'adjust' && active;

    return (
      <div className={cx(s.root, className)} ref={this.ref}>
        <VisibilitySensor
          onChange={this.handleVisibility}
          partialVisibility
          minTopValue={50}
          offset={{ top: 100 }}
        >
          {moment.thumbnail
            ? <img alt="" className={s.poster} src={moment.thumbnail} />
            : <div className={cx(s.placeholder, moment.game?.sport?.slug)} />}
        </VisibilitySensor>
        {active && (
          <div className={s.player}>
            <MomentPlayer
              moment={moment}
              onEvent={this.eventsDispatcher}
              onFullscreen={this.handleFullscreen}
              onPosition={this.handlePlayerPosition}
              playing={!paused}
              position={trimmerPosition}
            >
              {share && (
                <div className={s.controls}>
                  <Share
                    context={context}
                    meta={share}
                    placement="right"
                  />
                </div>
              )}
            </MomentPlayer>
          </div>
        )}

        {!hideGameBar && !adjust && (
          <div className={s.headline}>
            <GameHeadline game={moment.game} disableLinks={!withLinks} />
          </div>
        )}

        <div>
          {!adjust && (
            <div className={s.description}>
              {!hideCuratorHighlight && (
                <UserHighlight
                  user={moment.curator}
                  semicolon
                  disableLink={!withLinks}
                />
              )}
              <MomentDescriptionReadonly
                inline
                moment={moment}
                link={withLinks && linkGenerator}
                withDuration={this.props.withDuration}
              />
            </div>
          )}
          {!hideActionBar && !adjust && (
            <>
              <hr className={s.divider} />
              <MomentActionBar
                context={this.props.context}
                moment={moment}
                collection={collection}
                showLikingAction={!ownMoment}
                showDeleteAction={moment.can_manage}
                showRemoveAction={Boolean(collection)}
                showCollectionAction
                showGameAction
                showFlagAction={!ownMoment}
                showDownloadAction={moment.can_download}
                showEmbed={moment.can_embed}
                showAdjustTime={!hideAdjust && moment.can_manage}
              />
              <hr className={s.divider} />
            </>
          )}
          {adjust && (
            <div className={s.adjustContainer}>
              <div className={s.videoTrimmer}>
                <MomentTrimmer
                  length={moment.video.duration}
                  moment={moment}
                  onChange={this.handleMoment}
                  onPosition={this.handleTrimmerPosition}
                  ref={this.trimmerRef}
                  videoUrl={moment.video.streaming_url}
                />
              </div>
              <div className={s.buttons}>
                <Button
                  className={s.button}
                  disabled={saving}
                  loading={saving}
                  onClick={this.handleSaveMoment}
                  over
                  variant="primary"
                >
                  Save
                </Button>
                <Button
                  className={s.button}
                  disabled={saving}
                  variant="default"
                  onClick={(): void => this.props.onCancel && this.props.onCancel(moment)}
                >
                  Cancel
                </Button>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}
