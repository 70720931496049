import { HttpError, JSONError } from 'weplayed-typescript-api';

export const ERROR_NAME = 'Please enter both your first and last name';
export const ERROR_EMAIL = 'Please enter valid email';
export const ERROR_USERNAME = 'Please follow user name guidelines';
export const ERROR_PASSWORD = 'Password must have at least 8 characters';
export const ERROR_PASSWORD_MATCH = 'Passwords must match';

export interface MappedFieldErrors {
  [key: string]: string;
}

const nfe_name = 'non_field_errors';

export function getError(
  error: HttpError,
  field?: string,
): string | null {
  const errors = error?.json?.errors;

  if (Array.isArray(errors)) {
    const key = field ? [`/data/attributes/${field}`] : [
      '/data',
      `/data/attributes/${nfe_name}`,
    ];
    return errors.find(({ source: { pointer } }) => key.includes(pointer))?.detail;
  }

  return null;
}

export const fromBackendResponse: (errors: JSONError[]) => MappedFieldErrors = (
  errors: JSONError[] = [],
) => Object.fromEntries(errors.map((error): [string, string] => {
  let key = error.source?.pointer.replace('/data/attributes/', '') || nfe_name;
  let value = error.detail;

  // compose name error
  if (key === 'last_name') {
    key = 'full_name';
    value = ERROR_NAME;
  } else if (key === 'first_name') {
    key = 'full_name';
  }

  return [key, value];
}));
