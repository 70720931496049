import { isArray } from 'lodash';

import {
  ItemPropType, ItemSize, TemplateItemBase, TemplateProperty,
} from './types';

/**
 * Get absolute value for relative size
 * @param length Relative to box value, e.g. `14w` or `100h`
 * @param box Box size
 * @returns Absolute value for relative size
 */
export const getLength = (
  length: string,
  box: ItemSize,
): number => {
  const m = length.match(/^(-?\d+(?:\.\d+)?)([wh])$/);
  if (!m) {
    throw new Error(`Length must have format of float following by w or h: ${length}`);
  }
  return (parseFloat(m[1]) * (m[2] === 'w' ? box.w : box.h)) / 100;
};

/**
 * Expands padding shortcuts and returns full padding array of absolute values
 * @param item Item with `padding` property
 * @param box Parent box
 * @returns number[4] with absolute padding values
 */
export const getPadding = (
  item: TemplateItemBase,
  box: ItemSize,
): [number, number, number, number] => {
  const p = item.padding;
  let padding = ['0w', '0w', '0w', '0w'];

  if (typeof p === 'string') {
    padding = [p, p, p, p];
  }

  if (isArray(p)) {
    if (p.length === 1) {
      padding = [p[0], p[0], p[0], p[0]];
    }

    if (p.length === 2) {
      padding = [p[0], p[1], p[0], p[1]];
    }

    if (p.length === 3) {
      padding = [p[0], p[1], p[2], p[1]];
    }
  }

  return padding.map((pp) => getLength(pp, box)) as [number, number, number, number];
};

export function isTypeOf<T extends ItemPropType>(
  type: T,
  item: unknown,
): item is TemplateProperty<T> {
  return (item as TemplateProperty).type === type;
}

/**
 * Export image to data URL
 * @param url URL to image
 * @returns Data URL
 */
export const imageToDataURL = async (url: string): Promise<string> => {
  const blob = await (await fetch(url)).blob();
  return new Promise((ok, ko) => {
    const reader = new FileReader();
    reader.onloadend = (): void => ok(reader.result as string);
    reader.onerror = ko;
    reader.readAsDataURL(blob);
  });
};
