import { sortBy } from 'lodash';
import * as React from 'react';

import { isTypeOf, TemplateProperty } from 'common/utils/overlay';

import * as s from './OverlayProps.m.less';
import { OverlayPropsProps } from './types';

const getRangeValue = (prop: TemplateProperty<'range'>, value: string): string => {
  if (prop.invert) {
    return String(parseFloat(prop.max) - parseFloat(value) + parseFloat(prop.min));
  }

  return value;
};

export const OverlayProps: React.FC<OverlayPropsProps> = function OverlayProps({
  props,
  onChange,
}) {
  const properties = sortBy(
    props?.filter(({ name }) => Boolean(name)) || [],
    ({ order }) => order ?? Number.MAX_VALUE,
  );

  const handleChange = React.useCallback(({ target: { name, value } }) => {
    onChange(props?.map((prop) => {
      let v = value;

      if (prop.path === name) {
        if (isTypeOf('range', prop)) {
          v = getRangeValue(prop, value);
        }

        return { ...prop, value: v };
      }

      return prop;
    }));
  }, [onChange, props]);

  return (
    <div className={s.root}>
      {properties.map((prop) => {
        const { path, name, value } = prop;

        let render: JSX.Element = null;

        if (isTypeOf('text', prop)) {
          render = (
            <input
              type="text"
              name={path}
              value={value}
              maxLength={prop.maxLength}
              onChange={handleChange}
            />
          );
        }

        if (isTypeOf('color', prop)) {
          render = (
            <input
              type="color"
              name={path}
              value={value}
              onChange={handleChange}
            />
          );
        }

        if (isTypeOf('range', prop)) {
          render = (
            <input
              max={prop.max}
              min={prop.min}
              name={path}
              onChange={handleChange}
              step={prop.step}
              type="range"
              value={getRangeValue(prop, value)}
            />
          );
        }

        if (isTypeOf('list', prop)) {
          render = (
            <select name={path} onChange={handleChange} value={value}>
              {prop.options.map(({ name: n, label }) => (
                <option value={n} key={n}>{label || n}</option>
              ))}
            </select>
          );
        }

        if (render) {
          return (
            // eslint-disable-next-line jsx-a11y/label-has-for
            <label key={path}>
              <span>{name}</span>
              {render}
            </label>
          );
        }

        return null;
      })}
    </div>
  );
};
