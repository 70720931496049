import * as cx from 'classnames';
import * as React from 'react';
import { Modal } from 'react-bootstrap';
import { What } from 'weplayed-typescript-api';

import { Button } from 'common/components/Button';
import { formatDate } from 'common/utils/dates';

import { Avatar } from 'cms/components/Avatar';
import { Loading } from 'cms/components/Loading';
import { useCollection } from 'cms/hooks/useCollection';
import { useMoment } from 'cms/hooks/useMoment';

import { THEME } from './constants';
import * as s from './EntityInfo.m.less';
import { EntityInfoProps } from './types';

export const EntityInfo: React.FC<EntityInfoProps> = function EntityInfo({
  onClose,
  type,
  uid,
}) {
  const { moment } = useMoment({ uid, enabled: type === What.MOMENTS });
  const { collection } = useCollection({
    uid,
    enabled: type === What.COLLECTIONS,
    summary: true, // avoid moments loading
  });

  const isLoading = moment.isLoading || collection.isLoading;
  const data = type === What.MOMENTS ? moment.data : collection.data;
  const creator = type === What.MOMENTS ? moment.data?.curator : collection.data?.created_by;

  const algoText = collection.data?.algo_created
      && `Algo Playlist${collection.data?.algo_maintained ? ' (updated automatically)' : ''}`;
  const algo = algoText && (
    <span className={cx(s.algo, collection.data?.algo_maintained && s.auto)} title={algoText}>
      {algoText}
    </span>
  );

  return (
    <Modal
      backdropClassName="modal-backdrop-blackout"
      keyboard
      onEscapeKeyDown={onClose}
      onHide={onClose}
      show
      size="lg"
    >
      <Modal.Header>
        <Modal.Title>Info</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {type === What.COLLECTIONS && collection.data?.theme && (
          <div className={s.theme}>
            {THEME[collection.data?.theme]}
          </div>
        )}
        {isLoading ? <Loading loading /> : (data && (
          <div className={s.root}>
            <dl>
              <dt>Created By</dt>
              <dd>
                <Avatar className={s.avatar} user={creator} />
                {creator?.full_name}
              </dd>
            </dl>
            <dl>
              <dt>Created Date</dt>
              <dd>
                {formatDate(data.ctime)}
              </dd>
            </dl>
            <dl>
              <dt>Last Edit By</dt>
              <dd>
                {data?.modified_by ? (
                  <>
                    <Avatar className={s.avatar} user={creator} />
                    {creator?.full_name}
                  </>
                ) : '—'}
              </dd>
            </dl>
            <dl>
              <dt>Last Edit Date</dt>
              <dd>
                {formatDate(data.mtime)}
              </dd>
            </dl>
            {algo && (
              <dl>
                <dt>&nbsp;</dt>
                <dd>{algo}</dd>
              </dl>
            )}
          </div>
        ))}
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={onClose}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>

  );
};
