import * as React from 'react';
import { What, WhatType } from 'weplayed-typescript-api';

import { pkify } from 'common/utils/helpers';

import {
  SelectionAction, UseSelectionReturnType, UseSelectionReturnTypeData,
} from './types';

export const useSelection = function useSelection<
  T extends What
>(): UseSelectionReturnType<T> {
  const [type, setType] = React.useState<T>(null);
  const [items, setItems] = React.useState<Array<WhatType<T>>>([]);

  const [
    selection,
    set,
  ] = React.useReducer(
    (
      $state: Array<WhatType<T>>,
      action: SelectionAction<T> | null,
    ): Array<WhatType<T>> => {
      if (!action) {
        setType(null);
        return [];
      }

      let state = $state;

      const { item: $item, items: $items, select: $select } = action;

      if (type) {
        if ($items) {
          state = $items;
        } else if ($item) {
          const idx = state.findIndex(({ pk }) => pk === $item.pk);
          if (idx !== -1) {
            state = [...state.slice(0, idx), ...state.slice(idx + 1)];
          }

          if (typeof $select === 'boolean' ? $select : idx === -1) {
            state = [...state, $item];
          }
        }
      }

      return state;
    },
    [],
  );

  // remove item from selected if it has gone from items
  React.useEffect(() => {
    // find all pk from selection and filter
    // out those are in items already
    const pks = selection.map(pkify);
    items.forEach(({ pk }) => {
      const idx = pks.indexOf(pk);
      if (idx !== -1) {
        pks.splice(idx, 1);
      }
    });
    // filter selection
    if (pks.length) {
      set({ items: selection.filter(({ pk }) => !pks.includes(pk)) });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [items]);

  const clear = React.useCallback(() => setItems([]), []);

  const empty = React.useCallback(
    (): boolean => selection.length === 0,
    [selection?.length],
  );

  const is = React.useCallback(<R extends T>(
    ...$types: R[]
    // ts compiler for no reason prohibits usage of this for object methods
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
  ): this is UseSelectionReturnTypeData<R> => $types.some((t) => t === type), [type]);

  const load = React.useCallback(($type: T, $items: Array<WhatType<T>>): void => {
    if (type !== $type) {
      set({ items: [] });
    }
    setType($type);
    setItems($items);
  }, [type]);

  return {
    clear,
    empty,
    is,
    load,
    ...{ items, selection, set, type },
  };
};
