import * as React from 'react';
import { EmbedAdsSpec, Moment } from 'weplayed-typescript-api';

import { Button } from 'common/components/Button';
import {
  MomentPlayer as BaseMomentPlayer,
} from 'common/components/MomentPlayer';
import { MomentTrimmer } from 'common/components/MomentTrimmer';
import {
  RefType as MomentTrimmerRef,
} from 'common/components/MomentTrimmer/types';
import { EventContext } from 'common/utils/events';
import {
  createMomentEventDispatcher, MomentEventDispatcher, searchPlayer, searchTag,
  searchTeam,
} from 'common/utils/moments';

import { Avatar } from 'cms/components/Avatar';
import { GameHeadline } from 'cms/components/GameHeadline';
import {
  MomentDescription, MomentDescriptionReadonly,
} from 'cms/components/MomentDescription';

import * as s from './PopupPlayer.m.less';
import { MomentPlayerProps } from './types';

export const MomentPlayer: React.FC<MomentPlayerProps> = function MomentPlayer({
  children,
  disabled,
  edit,
  info = true,
  moment: outerMoment,
  onCancel,
  onEnd,
  onMomentUpdate,
  onNext,
  onPrevious,
  onSave,
  overlay,
  paused,
}) {
  // Moment trimmer reference
  const trimmerRef = React.useRef<MomentTrimmerRef>(null);
  // instead of using state here we'll use ref to decrease number of re-renders
  const position = React.useRef<number>(outerMoment?.start);

  // Moment to play/edit
  const [moment, setMoment] = React.useState<Moment<EmbedAdsSpec>>(outerMoment);

  // Trimmer position
  const [trimmerPosition, setTrimmerPosition] = React.useState<number>(moment?.start);

  // Moment playback events dispatcher
  const eventDispatcher: MomentEventDispatcher = React.useMemo(
    () => (moment?.pk ? createMomentEventDispatcher(moment, EventContext.ADMIN) : undefined),
    [moment],
  );

  // Moment start/stop change by trimmer listener
  const handleChangeMomentBoundaries = React.useCallback((m: Moment<EmbedAdsSpec>) => {
    setMoment(m);
    if (onMomentUpdate) {
      onMomentUpdate(m);
    }
  }, [onMomentUpdate]);

  // Handle moment save button press
  const handleSaveMoment = React.useCallback(() => {
    onSave(moment);
  }, [moment, onSave]);

  // Player search proxy function
  const handleSearchPlayer = React.useCallback(
    (term: string, transformFunc, callbackFunc): void => {
      searchPlayer(term, moment.game, transformFunc, callbackFunc);
    },
    [moment?.game],
  );

  // Tag search proxy function
  const handleSearchTag = React.useCallback(
    (term: string, transformFunc, callbackFunc): void => {
      searchTag(term, moment.game.sport.pk, transformFunc, callbackFunc);
    },
    [moment?.game],
  );

  // Team search proxy function
  const handleSearchTeam = React.useCallback(
    (term: string, transformFunc, callbackFunc): void => {
      searchTeam(term, moment.game, transformFunc, callbackFunc);
    },
    [moment?.game],
  );

  // Moment description change callback
  const handleChangeMomentDescription = React.useCallback(
    (description: string, name: string): void => {
      const m = { ...moment, description, name };
      setMoment(m);
      if (onMomentUpdate) {
        onMomentUpdate(m);
      }
    },
    [moment, onMomentUpdate],
  );

  const handlePosition = React.useCallback((pos) => {
    position.current = pos;
    trimmerRef.current?.setTime(pos);
  }, []);

  // Set moment when external one changed
  React.useEffect(() => {
    setMoment(outerMoment);
  }, [outerMoment]);

  React.useEffect(() => {
    if (edit) {
      trimmerRef.current?.setTime(position.current);
    }
  }, [edit]);

  return (
    <>
      <div className={s.video}>
        <BaseMomentPlayer
          moment={moment}
          ads={false}
          bar={!edit}
          fullscreen={!edit}
          onEnd={!edit && onEnd}
          onEvent={!edit && eventDispatcher}
          onNext={!edit && onNext}
          onPosition={handlePosition}
          onPrevious={!edit && onPrevious}
          playing={!(edit || paused)}
          position={trimmerPosition}
          overlay={overlay}
        />
        {info && (
          <GameHeadline
            className={s.headline}
            disableLinks
            game={moment.game}
          />
        )}
      </div>
      {edit && onCancel ? (
        <>
          <div className={s.adjust}>
            <MomentTrimmer
              disabled={disabled}
              moment={moment}
              // position={playerPosition}
              length={moment.video.duration}
              onPosition={setTrimmerPosition}
              onChange={handleChangeMomentBoundaries}
              ref={trimmerRef}
              videoUrl={moment.video.streaming_url}
            />
            <Button
              className={s.button}
              disabled={disabled}
              loading={disabled}
              onClick={handleSaveMoment}
              over
              variant="primary"
            >
              Save
            </Button>
            <Button
              className={s.button}
              disabled={disabled}
              variant="default"
              onClick={onCancel}
            >
              Cancel
            </Button>
          </div>
          <MomentDescription
            className={s.description}
            disabled={disabled}
            inEditMode
            moment={moment}
            onChanged={handleChangeMomentDescription}
            onSearchPlayer={handleSearchPlayer}
            onSearchTag={handleSearchTag}
            onSearchTeam={handleSearchTeam}
            textareaPlaceholder="Create a custom moment with #tags and @athlete mentions."
          />
        </>
      ) : (
        <div className={s.description}>
          {children || (
            <>
              <Avatar className={s.avatar} user={moment.curator} />
              <MomentDescriptionReadonly
                inline
                moment={moment}
                withDuration
              />
            </>
          )}
        </div>
      )}

    </>
  );
};
