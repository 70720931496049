import { omit } from 'lodash/fp';
import * as React from 'react';
import { useQueryCache } from 'react-query';
import * as api from 'weplayed-typescript-api';

import {
  ApplicationContext,
} from 'common/components/ApplicationProvider/ApplicationContext';
import {
  ACCESS_TOKEN, RECENT_TEAM_KEY, REFRESH_TOKEN, SOCIAL_TOKEN, SUBSCRIPTION_KEY,
} from 'common/constants';
import { event } from 'common/utils/analytics';
import { persistent } from 'common/utils/persistent';
import { loadSettings, saveSettings } from 'common/utils/settings';
import { getVisitorId, setVisitorId } from 'common/utils/visitorId';

import { TIME_TO_UPDATE } from './constants';
import { TokenRefreshData, UseAuthReturnType, UseAuthType } from './types';

const storeTokens = (data: TokenRefreshData): void => {
  persistent.set(REFRESH_TOKEN, data.refresh);
  persistent.set(ACCESS_TOKEN, data.access);
};

export const useAuth: UseAuthType = function useAuth() {
  const refreshTimer = React.useRef<ReturnType<typeof setTimeout>>();

  const {
    broadcast, events, setSubscription, setProfile,
    profile, subscription, setSettings,
  } = React.useContext(ApplicationContext);

  const cache = useQueryCache();

  const loadSubscription: UseAuthReturnType['loadSubscription'] = React.useCallback(async () => {
    const pk = getVisitorId();

    const defaults: Pick<api.Subscription, api.FollowFieldNames> = {
      following_teams: [],
      following_channels: [],
      following_players: [],
    };

    let subs: api.Subscription;
    const json = persistent.get(SUBSCRIPTION_KEY);

    let recent_team;

    try {
      recent_team = JSON.parse(persistent.get(RECENT_TEAM_KEY));
    } catch (e) {
      //
    }

    try {
      subs = {
        ...defaults,
        ...JSON.parse(json),
        recent_team,
        pk,
      };
    } catch (e) {
      subs = {
        ...defaults,
        recent_team,
        pk,
      };
      persistent.set(SUBSCRIPTION_KEY, JSON.stringify(subs));
    }

    setSubscription(subs);

    return subs;
  }, [setSubscription]);

  const logout: UseAuthReturnType['logout'] = async () => {
    events.dispatchTypedEvent('logout', new Event('logout'));

    clearTimeout(refreshTimer.current);
    persistent.set(ACCESS_TOKEN);
    persistent.set(REFRESH_TOKEN);
    persistent.set(SOCIAL_TOKEN);

    cache.clear();

    // order matters!
    setVisitorId();
    persistent.set(SUBSCRIPTION_KEY);

    // new subscription
    loadSubscription();

    // drop profile
    setProfile(null);
    // end

    event({
      category: 'Account',
      action: 'Logout',
    });

    return Promise.resolve();
  };

  const refresh: UseAuthReturnType['refresh'] = async () => {
    if (persistent.get(SOCIAL_TOKEN)) {
      return true;
    }

    const token = persistent.get(REFRESH_TOKEN);

    if (!token) {
      return false;
    }

    clearTimeout(refreshTimer.current);

    try {
      storeTokens(await api.authentication.refresh({ refresh: token, type: 'jwt' }));

      refreshTimer.current = setTimeout(refresh, TIME_TO_UPDATE);

      return true;
    } catch (e) {
      if (e.status === 401) {
        broadcast('error', 'Session is expired', 5000);
        await logout();
      }

      return false;
    }
  };

  /**
   * Load profile from backend and returns user and subscription objects
   */
  const loadProfile: UseAuthReturnType['loadProfile'] = async () => {
    let user: api.User;

    try {
      user = await api.profile.get();
    } catch (e) {
      if (e.status === 401) {
        broadcast('error', 'Problem with loading your account, please sign in again', 5000);
        await logout();
        return;
      }

      throw e;
    }

    if (user.is_cms_user && user.cms_level === api.CMSLevel.LEAGUE) {
      user.conference = await api.org.conference();
      user.conference.pk = user.cms_org_id;
    } else if (user.is_cms_user && user.cms_org_namespace) {
      user.org = await api.org.bySlug({ slug: user.cms_org_namespace });
    } else {
      user.org = null;
    }

    if (!user.cms_limited_sports || user.cms_limited_sports.length === 0) {
      delete user.cms_limited_sports;
    }

    let recent_team;

    try {
      recent_team = JSON.parse(persistent.get(RECENT_TEAM_KEY));
    } catch (e) {
      //
    }

    const subs: api.Subscription = {
      ...subscription,
      pk: user.visitor_id,
      firstName: user.first_name,
      lastName: user.last_name,
      following_teams: user.following_teams,
      following_channels: user.following_channels,
      following_players: user.following_players,
      recent_team,
    };

    persistent.set(SUBSCRIPTION_KEY, JSON.stringify(subs));
    setSubscription(subs);
    setVisitorId(user.visitor_id);
    setProfile(user);

    return { user, subscription: subs };
  };

  const login: UseAuthReturnType['login'] = async (attributes) => {
    storeTokens(await api.authentication.login(attributes));

    const { user } = await loadProfile();

    events.dispatchTypedEvent('login', new Event('login'));

    // schedule refresh
    refreshTimer.current = setTimeout(refresh, TIME_TO_UPDATE);

    // reset session settings
    const settings = loadSettings(user.pk, true);
    saveSettings(user.pk, settings);
    setSettings(settings);

    return user;
  };

  const loginSocial: UseAuthReturnType['loginSocial'] = async (token) => {
    persistent.set(SOCIAL_TOKEN, token);

    const { user } = await loadProfile();
    return user;
  };

  const saveSubscription: UseAuthReturnType['saveSubscription'] = async (subs) => {
    const {
      email, firstName, lastName, following_channels,
      following_players, following_teams,
      recent_team,
    } = subs;

    persistent.set(SUBSCRIPTION_KEY, JSON.stringify(omit('recent_team', subs)));
    setSubscription(subs);

    if (email) {
      await api.visitor.upsert({
        ...subscription,
        pk: getVisitorId(),
        email,
        first_name: firstName,
        last_name: lastName,
        origin: 'web',
        following_channels,
        following_players,
        following_teams,
        recent_team,
        ...persistent.startsWith('utm_'),
      });
    }

    if (recent_team) {
      persistent.set(RECENT_TEAM_KEY, JSON.stringify(recent_team));
    } else {
      persistent.set(RECENT_TEAM_KEY);
    }

    return subs;
  };

  const saveProfile: UseAuthReturnType['saveProfile'] = async (user) => {
    await api.profile.update({ ...user, pk: profile.pk });

    const data = await loadProfile();
    let newUser = data.user;

    // since misc avatar sizes regeneration is async process,
    // let's set source URL to all avatar URLs, which will be
    // updated on next reload
    if (user.new_avatar_url) {
      newUser = {
        ...newUser,
        avatar_url: user.new_avatar_url,
        avatar_64x64_url: user.new_avatar_url,
        avatar_480_480_url: user.new_avatar_url,
      };

      setProfile(newUser);
    }

    return newUser;
  };

  return {
    subscription,
    loadSubscription,
    saveSubscription,

    profile,
    loadProfile,
    saveProfile,

    refresh,

    login,
    loginSocial,
    logout,
  };
};
