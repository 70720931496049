import {
  ReactComponent as AddButton,
} from '@mdi/svg/svg/plus-circle-outline.svg';
import * as cx from 'classnames';
import { curry, omit, throttle } from 'lodash/fp';
import * as React from 'react';
import { ButtonGroup, Form } from 'react-bootstrap';
import { Moment, Team } from 'weplayed-typescript-api';

import { useProfile } from 'common/hooks/useProfile';
import { EventContext } from 'common/utils/events';
import { searchPlayer, searchTag } from 'common/utils/moments';

import { Button } from 'consumer/components/Button';
import { MomentActionBarProvider } from 'consumer/components/MomentActionBar';
import {
  MomentActionDropdown,
} from 'consumer/components/MomentActionBar/MomentActionDropdown';
import {
  Action, MomentActionAcceptCallback,
} from 'consumer/components/MomentActionBar/types';
import { MomentDescription } from 'consumer/components/MomentDescription';
import { GameAction } from 'consumer/hooks/useGame/types';
import { useMoments } from 'consumer/hooks/useMoments';

import { GameProviderContext } from '../GameProvider/constants';
import { HubEntries, HubEntry, MODE } from '../GameProvider/types';
import { GameControlMomentsHubEntry } from './GameControlMomentsHubEntry';
import * as s from './GameControlsMomentsHub.m.less';
import { EntriesSortOrder, MenuEntry, Props } from './types';
import { getSegmentName, getSegmentNameShort } from './utils';

const pivotalMomentTag = '#pivotalMoment';
const pivotalMomentTagRe = new RegExp(
  `\\s?(\\{\\{#\\|[a-f0-9-]+\\|${pivotalMomentTag}\\}\\}|${pivotalMomentTag})`,
  'g',
);

export const GameControlsMomentsHub: React.FC<Props> = function GameControlsMomentsHub({
  className, headerClassName, entryHeaderClassName, ...props
}) {
  const {
    user, moment, hub: outerhub, moments: outermoments, onJumpTo, onUnauthorized,
    stateTo, onEditMoment, game, highlighted, mode, saving, onMoment,
    onMomentCommit, onMomentDelete, momentsAction, onCreateMoment, segments, atLiveEdge,
  } = React.useContext(GameProviderContext);
  const [sort, setSort] = React.useState(EntriesSortOrder.CHRONOLOGICAL);
  const { settings: {
    game_moment_scores: withScore,
    game_moment_status: withStatus,
  } } = useProfile();
  const [menu, setMenu] = React.useState<MenuEntry>();
  const { review: [saveReview] } = useMoments();
  const [autoscroll, setAutoscroll] = React.useState(true);
  const ref = React.useRef<HTMLDivElement>();
  const [segment, setSegment] = React.useState(0);

  React.useEffect(() => {
    if (atLiveEdge) {
      setAutoscroll(true);
    }
  }, [atLiveEdge]);

  React.useEffect(() => {
    const listener = (): void => setAutoscroll(false);
    const div = ref.current;

    div.addEventListener('touchmove', listener);
    div.addEventListener('mousewheel', listener);

    return (): void => {
      div.removeEventListener('touchmove', listener);
      div.removeEventListener('mousewheel', listener);
    };
  }, []);

  React.useEffect(() => {
    if (mode === MODE.EDIT) {
      setSort(EntriesSortOrder.CHRONOLOGICAL);
    }
  }, [mode]);

  const [hub, moments] = React.useMemo(() => {
    if (sort === EntriesSortOrder.CHRONOLOGICAL) {
      return [outerhub, outermoments];
    }

    return [
      null,
      outermoments?.slice()
        .sort((a, b) => {
          const as = a.score?.score || 0;
          const bs = b.score?.score || 0;
          return sort === EntriesSortOrder.HIGH ? bs - as : as - bs;
        }),
    ];
  }, [outermoments, outerhub, sort]) as [HubEntries, Moment[]];

  const handleSort = React.useCallback(
    ({ currentTarget }: React.ChangeEvent<HTMLSelectElement>) => {
      setSort(currentTarget.value as EntriesSortOrder);
    },
    [],
  );

  const handleClose = React.useCallback(() => {
    setMenu(null);
  }, []);

  const handleMenu = React.useCallback((m, target) => {
    if (m === menu?.moment) {
      handleClose();
    } else {
      setMenu({ moment: { ...m, game: omit('moments', game) }, target });
    }
  }, [handleClose, menu, game]);

  const currentMomentMadeByUser = user && moment?.curator?.pk === user?.pk;

  const handleMomentActionStart = React.useCallback(({ action }) => {
    if (['collection', 'delete', 'publication'].includes(action)) {
      stateTo(false);
    }
  }, [stateTo]);

  const handleMomentActionDecline = React.useCallback(() => {
    stateTo(true);
  }, [stateTo]);

  const handleMomentActionAccept = React.useCallback<MomentActionAcceptCallback>(
    async ({ action, momentPk, review }, status) => {
      if (action === 'edit') {
        onEditMoment(momentPk);
      } else if (action === 'delete') {
        onEditMoment();
      } else if (action === 'collection') {
        stateTo(true);
      } else if (action === 'review') {
        const reset = status('review');
        await saveReview({ uid: momentPk, reviewed: review });
        reset();
      }
    },
    [onEditMoment, saveReview, stateTo],
  );

  const handleScrollTo = React.useCallback((
    elements: HTMLElement[],
    stickToTop?: boolean,
    extraOffset = 40,
  ): void => {
    if (elements.length === 0) {
      return;
    }

    const sticky = document.getElementsByClassName(s.header)[0]?.getBoundingClientRect();
    const offsetTop = (sticky?.bottom || 0) + extraOffset;
    const offsetBottom = 0 + extraOffset;

    if (typeof offsetTop === 'number' && typeof offsetBottom === 'number') {
      // find top/bottom max values
      const [min, max] = elements.reduce(([t, b], e: HTMLElement) => {
        const { top, bottom } = e.getBoundingClientRect();
        return [
          Math.min(t, top),
          Math.max(b, bottom),
        ];
      }, [Infinity, -Infinity]);

      const top = offsetTop;
      const bottom = window.innerHeight - offsetBottom;

      let scrollBy;

      if (min < top || stickToTop === true) {
        scrollBy = min - top;
      } else if (max > bottom || stickToTop === false) {
        scrollBy = max - bottom;
      }

      if (typeof scrollBy === 'number') {
        window.scrollTo({ top: window.scrollY + scrollBy });
      }
    }
  }, []);

  const handleMomentLike = React.useCallback((m: Moment, like: boolean): void => {
    onUnauthorized({ action: 'like', momentPk: m.pk, like } as Action);
  }, [onUnauthorized]);

  const scrollPk = (autoscroll || mode === MODE.EDIT) && (moment?.pk || 'new');

  React.useEffect(() => {
    if (scrollPk) {
      const element = document.querySelector(`[data-moment="${scrollPk}"]`) as HTMLElement;

      if (element) {
        handleScrollTo([element], true);
      }
    }
  }, [handleScrollTo, scrollPk, hub]);

  React.useEffect(() => {
    if (autoscroll && atLiveEdge && (hub || moments)) {
      let $action: GameAction;
      let $moment: Moment;

      if (hub) {
        ([$action, $moment] = hub[hub.length - 1]?.entries[
          hub[hub.length - 1].entries.length - 1
        ] || []);
      }

      if (!$action && !$moment) {
        ($moment = moments[moments.length - 1]);
      }

      const $element: HTMLElement = (
        ($action && document.querySelector(`[data-action="${$action.sequence}"]`))
        || ($moment && document.querySelector(`[data-moment="${$moment.pk}"]`))
      );

      if ($element) {
        handleScrollTo([$element]);
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [atLiveEdge, hub, moments, autoscroll]);

  const handleGoToSegment = React.useCallback(({ currentTarget }: React.MouseEvent): void => {
    const seg = currentTarget.getAttribute('data-segment-to');
    const element = ref.current.querySelector(`[data-segment="${seg}"]`) as HTMLElement;
    setAutoscroll(false);
    handleScrollTo([element], true, 0);
  }, [handleScrollTo]);

  const handleCancelMoment = React.useCallback(() => {
    onEditMoment();
  }, [onEditMoment]);

  const handleSaveMoment = React.useCallback((e: React.FormEvent) => {
    e.preventDefault();
    onMomentCommit();
  }, [onMomentCommit]);

  const handleChangeAutoscroll = React.useCallback(
    ({ currentTarget: { checked } }) => setAutoscroll(checked),
    [],
  );

  const handleDeleteMoment = React.useCallback(() => {
    if (moment?.pk) {
      onMomentDelete(moment);
    }
  }, [moment, onMomentDelete]);

  React.useEffect(() => {
    if (highlighted?.length > 0) {
      const elements = highlighted
        .map((m) => ref.current.querySelector(`[data-moment="${m.pk}"]`) as HTMLElement)
        .filter(Boolean);
      handleScrollTo(elements);
    }
  }, [handleScrollTo, highlighted]);

  const handleSearchPlayer = React.useCallback(
    (term: string, transformFunc, callbackFunc): void => {
      searchPlayer(term, game, transformFunc, callbackFunc);
    },
    [game],
  );

  const handleSearchTag = React.useCallback(
    (term: string, transformFunc, callbackFunc): void => {
      searchTag(term, game.sport.pk, transformFunc, callbackFunc);
    },
    [game],
  );

  const handleSearchTeam = React.useCallback(
    (term: string, transformFunc, callbackFunc): void => {
      const needle = term.toLowerCase().trim();
      const suggestions = [game.team1, game.team2]
        .filter((team: Team) => needle.length !== 0 && (
          team.name.toLowerCase().indexOf(needle) !== -1
        || team.acronym.toLowerCase().indexOf(needle) !== -1));
      callbackFunc(transformFunc(suggestions));
    },
    [game],
  );

  const handleChangeDescription = React.useCallback(
    (description: string, name: string): void => {
      onMoment({ ...moment, description, name });
    },
    [moment, onMoment],
  );

  const hasPivotalMoment = moment?.description?.includes(pivotalMomentTag);

  const handlePivotalMoment = React.useCallback(() => {
    if (moment) {
      if (hasPivotalMoment) {
        const description = moment.description.replace(pivotalMomentTagRe, '');
        const name = moment.name.replace(pivotalMomentTagRe, '');
        handleChangeDescription(description, name);
      } else {
        const separator = moment.description ? ' ' : '';
        const name = `${moment.name}${separator}${pivotalMomentTag}`;
        const description = `${moment.description}${separator}${pivotalMomentTag}`;
        handleChangeDescription(description, name);
      }
    }
  }, [moment, hasPivotalMoment, handleChangeDescription]);

  const handleCreateMoment = React.useCallback((text: string, copied_pbp_id: number) => {
    onCreateMoment(null, text, copied_pbp_id);
  }, [onCreateMoment]);

  const handleJumpTo = React.useCallback((m: Moment): void => {
    onJumpTo(m);
    setAutoscroll(true);
  }, [onJumpTo]);

  const handleLink = React.useCallback((a: GameAction): void => {
    onMoment({
      ...moment,
      copied_pbp_id: a.sequence,
      description: a.summary,
      name: a.summary,
    });
  }, [moment, onMoment]);

  React.useEffect(() => {
    if (hub) {
      const listener = throttle(100, (): void => {
        // since this listener is throttled, we need to
        // check for actual div existence
        if (ref.current) {
          const ss = Array.from(ref.current.querySelectorAll('[data-segment]'));
          const sticky = document.getElementsByClassName(s.header)[0]?.getBoundingClientRect();
          const current = ss.find((e) => e.getBoundingClientRect().bottom > sticky.bottom);
          if (current) {
            const idx = Number(current.getAttribute('data-segment'));
            if (idx !== segment) {
              setSegment(idx);
            }
          }
        }
      });

      document.addEventListener('scroll', listener, true);

      return (): void => {
        document.removeEventListener('scroll', listener, true);
      };
    }
  }, [hub, segment]);

  const createSegment = React.useMemo(
    () => {
      if (mode === MODE.NONE || !moment || moment.pk) {
        return null;
      }

      // without hub structure segment has no sense
      if (!hub) {
        return -1;
      }

      // segments are easier to iterate
      if (segments) {
        return (
          segments.length - 1 - [...segments].reverse().findIndex(({ time }) => time < moment.start)
        ) % segments.length;
      }

      return (hub.length - 1 - [...hub].reverse().findIndex(
        ({ entries }) => entries.find(([, m]) => m && m.start < moment.start),
      )) % hub.length;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [hub, mode, moment?.start, moment?.pk, segments],
  );

  const createPosition = React.useMemo(
    () => {
      // no suitable
      if (createSegment === null) {
        return null;
      }

      // no hub, looking in moments
      if (createSegment === -1 && !hub) {
        const idx = moments?.findIndex((m) => m.start > moment.start) ?? -1;
        return (idx === -1 ? (moments?.length ?? 0) : idx) - 1;
      }

      const idx = hub[createSegment].entries.findIndex(([, m]) => m && m.start > moment.start);
      return (idx === -1 ? hub[createSegment].entries.length : idx) - 1;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [createSegment, hub, moments],
  );

  const [hasPbpEntries, hasMomentAction] = React.useMemo(
    () => [
      outerhub?.some((h) => h.entries.some(([a, m]) => Boolean(a && !m))),
      moment?.copied_pbp_id !== undefined || outerhub?.some(({ entries }) => entries.some(
        ([a]) => moment?.pk && a?.best_moment?.moment_id === moment.pk,
      )),
    ],
    [moment?.copied_pbp_id, moment?.pk, outerhub],
  );

  // no point in memoizing because of huge amount of dependencies
  const renderEntry = curry((seg: number, [a, m]: HubEntry, idx: number): JSX.Element => {
    const isActive = moment && (
      // both moments have the same pk and sequence
      // covers editing existing moment and linking existing moment to pbp entry
      (m && m.pk && m.pk === moment.pk && m.copied_pbp_id === moment.copied_pbp_id)
      // covers case when there is no PBP
      // || (seg === null && !a && m === moment)
      || (!a && m === moment)
      // covers case when creating moment linked to PBP
      || (!m && a && a.sequence === moment.copied_pbp_id)
    );

    const showForm = mode !== MODE.NONE && isActive;
    const key = m?.pk ?? (a && `${seg}-${a.sequence}-${a.clock}-${a.summary ?? ''}`) ?? 'new';
    const allowLink = mode === MODE.EDIT && !m && a && !a.is_nav
      && !moment?.copied_pbp_id && !showForm && !hasMomentAction;

    return (
      <React.Fragment key={key}>
        <GameControlMomentsHubEntry
          onMenu={handleMenu}
          onClick={handleJumpTo}
          onCreateMoment={handleCreateMoment}
          onLike={handleMomentLike}
          onLink={allowLink && handleLink}
          action={a}
          active={isActive || (highlighted && highlighted.includes(m))}
          disabled={mode !== MODE.NONE}
          moment={m ?? (
            (!m && a && a.sequence === moment?.copied_pbp_id) && {
              ...moment,
              name: a.summary,
              description: null,
            }
          )}
          user={user}
        >
          {showForm && (
            <Form onSubmit={handleSaveMoment}>
              <MomentDescription
                moment={moment}
                inEditMode
                textareaPlaceholder={(
                  <span className={s.placeholder}>
                    Create a custom moment with #tags and @athlete mentions
                    {!moment.copied_pbp_id && hasPbpEntries && (
                      <>
                        , or use the &nbsp;
                        <AddButton />
                        &nbsp; next to any of the available play-by-play to
                        connect this moment to a play
                      </>
                    )}
                    .
                  </span>
                )}
                onChanged={handleChangeDescription}
                onSearchPlayer={handleSearchPlayer}
                onSearchTag={handleSearchTag}
                onSearchTeam={handleSearchTeam}
              />
              {user?.is_staff && (
                <div className={s.toolbar}>
                  <Form.Check
                    checked={hasPivotalMoment}
                    id="moment-pivotal-checkbox"
                    label={pivotalMomentTag}
                    onChange={handlePivotalMoment}
                  />
                </div>
              )}

              <div className={s.formButtons}>
                <Button
                  type="submit"
                  variant="primary"
                  loading={saving}
                  disabled={saving}
                >
                  Save
                </Button>
                {moment.can_manage && moment.pk && (
                  <Button
                    onClick={handleDeleteMoment}
                    variant="danger"
                    disabled={saving}
                  >
                    Delete
                  </Button>
                )}
                <Button
                  onClick={handleCancelMoment}
                  variant="secondary"
                  disabled={saving}
                >
                  Cancel
                </Button>
              </div>
            </Form>
          )}
        </GameControlMomentsHubEntry>
        {createSegment !== null
          && ((seg === null && createSegment === -1) || (seg === createSegment))
          && !moment?.copied_pbp_id
          && idx === createPosition
          && renderEntry(seg, [null, moment], -1)}
      </React.Fragment>
    );
  });

  return (
    <MomentActionBarProvider
      ref={momentsAction}
      user={user}
      onStart={handleMomentActionStart}
      onAccept={handleMomentActionAccept}
      onDecline={handleMomentActionDecline}
      onUnauthorized={onUnauthorized}
    >
      <MomentActionDropdown
        context={EventContext.GAME}
        className={s.dropdown}
        showCollectionAction
        showEditAction={menu?.moment.can_manage}
        showDeleteAction={menu?.moment.can_manage}
        showLikingAction={false}
        showGameAction={false}
        showFlagAction={!currentMomentMadeByUser}
        showDownloadAction={menu?.moment.can_download}
        showEmbed={menu?.moment.can_embed}
        showReview={user?.can_publish_content}
        moment={menu?.moment}
        target={menu?.target}
        onClose={handleClose}
      />
      <div
        className={cx(s.root, withStatus && s.withStatus, withScore && s.withScore, className)}
        ref={ref}
        {...props}
      >
        <div className={cx(s.header, headerClassName)}>
          <div className={cx(s.segments, mode !== MODE.NONE && s.disabled)}>
            {hub && (
              <ButtonGroup>
                {hub.map(({ short, entries }, sidx) => (
                  <Button
                    variant="primary"
                    key={short}
                    className={cx(hub.length > 18 && s.small, segment === sidx && s.active)}
                    data-segment-to={sidx}
                    onClick={handleGoToSegment}
                    disabled={entries.length === 0}
                  >
                    {short || getSegmentNameShort(sidx, game.sport, hub.length)}
                  </Button>
                ))}
              </ButtonGroup>
            )}
          </div>
          {user?.is_staff && (
            <select
              onChange={handleSort}
              value={sort}
              className={cx(s.sort, mode !== MODE.NONE && s.disabled)}
            >
              {Object.values(EntriesSortOrder).map((value) => (
                <option value={value} key={value}>{value}</option>
              ))}
            </select>
          )}
          {mode === MODE.NONE && (
            <div className={s.autoscroll}>
              <Form.Check
                id="game-autoscroll"
                checked={autoscroll}
                label="Follow video"
                onChange={handleChangeAutoscroll}
                title="Scroll list to the current moment automatically"
              />
            </div>
          )}
        </div>
        <div className={s.entries}>
          {createSegment === -1 && createPosition === -1 && !moment.copied_pbp_id
            && renderEntry(null, [null, moment], null)}
          {hub?.map(({ name, entries }, sidx) => (entries.length ? (
            <div className={s.segment} key={name} data-segment={sidx}>
              <div className={cx(s.name, entryHeaderClassName)}>
                <h2>{name || getSegmentName(sidx, game.sport, hub.length)}</h2>
              </div>
              {createSegment === sidx && createPosition === -1 && !moment.copied_pbp_id
                && renderEntry(sidx, [null, moment], 0)}
              {entries.map(renderEntry(sidx))}
            </div>
          ) : null))
          || moments?.map((m, idx) => renderEntry(null, [null, m], idx))}
        </div>
      </div>
    </MomentActionBarProvider>
  );
};
