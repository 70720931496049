import cx from 'classnames';

import { PinViewType } from '../../types';
import * as s from './Controls.m.less';
import { PinProps } from './types';

export const Pin = function Pin({
  className,
  disabled,
  hidden,
  loading,
  onClick,
  pin,
}: PinProps): JSX.Element {
  const title = (
    (pin === PinViewType.TEMP && 'Temporary pin, will remain until next top list regeneration')
    || (pin === PinViewType.TIMED && 'Permanent pin')
    || 'Click to pin'
  );

  return (
    <button
      className={cx(
        s.control,
        s.pin,
        className,
        pin !== PinViewType.NONE && s.pinned,
        pin === PinViewType.TEMP && s.temporary,
        loading && s.loading,
        hidden && s.hidden,
      )}
      disabled={disabled}
      onClick={onClick}
      title={title}
      type="button"
    >
      Pin
    </button>
  );
};
