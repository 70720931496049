import cx from 'classnames';
import { debounce, intersection, uniq, without } from 'lodash/fp';
import * as React from 'react';
import { Dropdown, Form, Modal } from 'react-bootstrap';
import * as InfiniteScroll from 'react-infinite-scroller';
import { Link } from 'react-router-dom';
import { Collection, ID } from 'weplayed-typescript-api';

import { Button } from 'common/components/Button';
import { useApplication } from 'common/hooks/useApplication';
import { pickByPk, pkify } from 'common/utils/helpers';
import { format } from 'common/utils/strings';

import { ConfirmationModal } from 'cms/components/ConfirmationModal';

import { CollectionModal } from '../CollectionModal';
import {
  EntitiesListGroup, EntitiesListType, EntitiesSize, Lists,
} from '../Entities';
import * as s from './AddToCollectionModal.m.less';
import { CreatorFilterValues, SortOrderValues } from './constants';
import * as messages from './messages';
import { reducer } from './reducer';
import { AddToCollectionModalProps, CreatorFilter, SortOrder } from './types';
import { useData } from './useData';

const stopPropagation = (e: React.MouseEvent): void => e.stopPropagation();

export const AddToCollectionModal: React.FC<
  AddToCollectionModalProps
> = function AddToCollectionModal({
  collectionIDs,
  moment: outerMoment,
  moments: outerMoments,
  onUpdate,
  onClose,
  updating,
  user,
}) {
  const { broadcast } = useApplication();

  const [sort, setSort] = React.useState<SortOrder>(SortOrder.MODIFIED);
  const [text, setText] = React.useState<string>();
  const [uid, setUid] = React.useState<ID>(null);
  const [changePk, setChangePk] = React.useState<ID>(null);
  const [create, toggleCreate] = React.useReducer((state) => !state, false);

  const [moments, momentIDs] = React.useMemo(
    () => {
      const mm = outerMoments || (outerMoment ? [outerMoment] : []);
      return [mm, mm.map(pkify)];
    },
    [outerMoment, outerMoments],
  );

  const handleFilter = React.useMemo(() => {
    const handler = debounce(500, (v) => setText(v));

    return (
      { currentTarget: { value } }: React.ChangeEvent<HTMLInputElement>,
    ): void => handler(value);
  }, []);

  const handleCreator = React.useCallback((filter) => {
    setUid(filter === CreatorFilter.OWN ? user.pk : null);
  }, [user.pk]);

  const {
    collections: search,
  } = useData({ text, sort, uid });

  const data = React.useMemo<Collection[]>(
    () => search.data?.reduce((p, c) => [...p, ...c], []),
    [search],
  );

  const [mod, setCheck] = React.useReducer(
    reducer,
    null,
    () => ({ add: collectionIDs?.filter(Boolean) || [], remove: [] }),
  );

  const changed = React.useMemo(
    () => [...mod.add, ...mod.remove],
    [mod],
  );

  const handleCheck = React.useCallback(
    ({ pk }: Collection): void => {
      const collection = data.find(pickByPk(pk));

      if (collection.algo_maintained && !changed.includes(pk)) {
        setChangePk(pk);
      } else {
        setCheck({
          collection,
          momentIDs,
        });
      }
    },
    [changed, data, momentIDs],
  );

  const handleConfirmChangePk = React.useCallback(() => {
    setCheck({
      collection: data.find(pickByPk(changePk)),
      momentIDs,
    });
    setChangePk(null);
  }, [changePk, data, momentIDs]);

  const handleSave = React.useCallback(() => {
    onUpdate({ ...mod, moments: momentIDs });
  }, [mod, momentIDs, onUpdate]);

  const collections = React.useMemo(() => (
    data?.map((collection) => (mod.add.includes(collection.pk) && ({
      ...collection,
      moment_ids: uniq([...collection.moment_ids, ...momentIDs]),
    })) || (mod.remove.includes(collection.pk) && ({
      ...collection,
      moment_ids: without(momentIDs, collection.moment_ids),
    })) || collection)
  ), [data, mod, momentIDs]);

  const handleCollectionSuccess = React.useCallback((pk?: string) => {
    if (pk) {
      const message = 'Playlist successfully created.';

      broadcast('success', {
        message,
        jsx: (
          <>
            {message}
            <br />
            <Link to={{ hash: `#playlist=${pk}` }}>View playlist</Link>
          </>
        ),
      });
    }
    onClose();
  }, [onClose, broadcast]);

  const handleLoadMore = React.useCallback(() => search.fetchMore(), [search]);

  /*
   * Contains a list of collections whose selection state have been changed
   * by user
   */
  const selected = React.useMemo(
    () => collections?.filter(({ pk }) => changed.includes(pk)),
    [changed, collections],
  );

  return (
    <>
      <Modal
        backdropClassName="modal-backdrop-blackout"
        keyboard={!create}
        onEscapeKeyDown={onClose}
        onHide={onClose}
        show={Boolean(outerMoments || outerMoment) && !create}
        size="xl"
      >
        <Modal.Header className={s.header}>
          <Modal.Title>
            {format(messages.ADD_MOMENTS, momentIDs.length)}
          </Modal.Title>
          <Button
            type="button"
            onClick={toggleCreate}
            className={s.create}
          >
            Create new playlist
          </Button>
        </Modal.Header>
        <Modal.Body>
          <div className={s.controls}>
            <Form.Control
              as="input"
              className={s.filter}
              onChange={handleFilter}
              placeholder={uid ? 'Search only my playlists' : 'Search all playlists'}
            />
            <Dropdown onSelect={handleCreator}>
              <Dropdown.Toggle as="button" className={s.owner}>
                {CreatorFilterValues[uid ? CreatorFilter.OWN : CreatorFilter.ALL]}
              </Dropdown.Toggle>
              <Dropdown.Menu>
                {Object.values(CreatorFilter).map((cf) => (
                  <Dropdown.Item key={cf} eventKey={cf}>
                    {CreatorFilterValues[cf]}
                  </Dropdown.Item>
                ))}
              </Dropdown.Menu>
            </Dropdown>
            <Dropdown onSelect={setSort as (e: string) => void}>
              <Dropdown.Toggle as="button" className={s.sort}>
                {SortOrderValues[sort]}
              </Dropdown.Toggle>
              <Dropdown.Menu>
                {Object.values(SortOrder).map((order) => (
                  <Dropdown.Item key={order} eventKey={order}>
                    {SortOrderValues[order]}
                  </Dropdown.Item>
                ))}
              </Dropdown.Menu>
            </Dropdown>
          </div>
          <div className={s.collections}>
            <EntitiesListGroup
              type={EntitiesListType.LIST}
              onSelect={handleCheck}
              selected={selected}
            >
              <InfiniteScroll
                element="ul"
                hasMore={search.canFetchMore && !search.isFetchingMore}
                initialLoad
                loadMore={handleLoadMore}
                useWindow={false}
              >
                <Lists.Collections
                  // eslint-disable-next-line react/display-name
                  // eslint-disable-next-line react/no-unstable-nested-components
                  checkbox={({ pk, moment_ids }): React.ReactElement => {
                    const includes = intersection(moment_ids, momentIDs).length;
                    const cs = includes !== 0 && includes !== momentIDs.length ? s.many : null;

                    return (
                      <Form.Check
                        className={cx(s.label, cs)}
                        checked={includes !== 0}
                        custom
                        onClick={stopPropagation}
                        id={`collection-to-add-${pk}`}
                        key={1}
                        title={includes !== 0 && includes !== momentIDs.length
                          ? 'This playlist already includes some of the moments you selected'
                          : null}
                      />
                    );
                  }}
                  created
                  creator
                  edited={false}
                  items={collections}
                  link={false}
                  loading={Boolean(search.isLoading || search.isFetchingMore)}
                  showNotFound
                  size={EntitiesSize.COMPACT}
                  visibility
                />
              </InfiniteScroll>
            </EntitiesListGroup>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            disabled={changed.length === 0}
            loading={updating}
            onClick={handleSave}
            type="button"
          >
            Save
          </Button>
          <Button onClick={onClose} variant="secondary">
            Close
          </Button>
        </Modal.Footer>
      </Modal>
      <CollectionModal
        moments={create ? moments : null}
        onClose={toggleCreate}
        onSuccess={handleCollectionSuccess}
        user={user}
      />
      <ConfirmationModal
        show={Boolean(changePk)}
        cancelText="No, cancel this action"
        confirmText="Yes, continue"
        onConfirm={handleConfirmChangePk}
        onCancel={(): void => setChangePk(null)}
      >
        <p>
          You are attempting to change an algorithmic playlist — playlists that are automatically
          created and maintained by the WePlayed system.
        </p>
        <p>
          <strong>
            Changing playlist will turn this functionality off for this playlist and it will no
            longer be updated automatically.
          </strong>
        </p>
        <p>Would you like to proceed?</p>
      </ConfirmationModal>
    </>
  );
};
