/* eslint-disable no-restricted-globals */
import { ReactComponent as RewindTap } from '@mdi/svg/svg/history.svg';
import { ReactComponent as SkipNext } from '@mdi/svg/svg/skip-next.svg';
import { ReactComponent as SkipPrevious } from '@mdi/svg/svg/skip-previous.svg';
import { ReactComponent as ForwardTap } from '@mdi/svg/svg/update.svg';
import * as cx from 'classnames';
import * as React from 'react';
import videojs from 'video.js';

import { SUBTITLES_KEY, VOLUME_KEY } from 'common/constants';
import { hasTouch } from 'common/utils/features';
import * as FullscreenAPI from 'common/utils/fullscreen';
import { logger } from 'common/utils/logger';
import { persistent } from 'common/utils/persistent';

import { PlayPauseButton } from '../PlayPauseButton';
import { TapButton } from '../TapButton';
import {
  CONTROLS_TIMEOUT, debug, defaultProps, FADE_TIME, imaOptions, MAX_SPEED,
  MIN_FADE, MIN_SPEED, playerOptions, POSITION_UPDATE_MAXWAIT, SHIFT_TIME,
  VideoPlayerContext,
} from './constants';
import {
  AdEvent, Player, VideoPlayerProps, VideoPlayerRefType, VideoPlayerState,
} from './types';
import { getOrientationAngle } from './utils';
import * as s from './VideoPlayer.m.less';
import { VideoPlayerHelp } from './VideoPlayerHelp';

const debugAd = debug.extend('ad');

const isLive = (player: Player): boolean => {
  const duration = player.duration();
  const live = !Number.isFinite(duration) || (videojs.browser.IS_FIREFOX && duration === 0);

  return live;
};

export class VideoPlayer extends React.PureComponent<
  VideoPlayerProps,
  VideoPlayerState
> implements VideoPlayerRefType {
  /**
   * Default component props
   */
  public static defaultProps: OptionalOnly<VideoPlayerProps> = defaultProps;

  /**
   * Reference to the component's root DIV
   */
  protected rootRef = React.createRef<HTMLDivElement>();

  /**
   * Reference to the <video> element
   */
  protected videoRef = React.createRef<HTMLVideoElement>();

  /**
   * VideoJS player instance
   */
  protected player: Player;

  /**
   * Flag indicating that advertisement is ready to render
   */
  protected hasAd = false;

  /**
   * Time update timer, we use requestAnimationFrame for more granular time updates
   */
  protected timeupdateTimer: ReturnType<typeof setTimeout>;

  /**
   * Timer for controlling controls visibility
   */
  protected controlsTimer: ReturnType<typeof setTimeout>;

  /**
   * Last time reported by video player with granularity in mind
   */
  protected time: number;

  protected ratio: React.CSSProperties;

  /**
   * This flag is used to fix vjs player bug under safari
   * If autoplay is prohibited by browser settings, player.play() method
   * raises an exception but still fires 'playing' event
   */
  protected reallyPlayed: boolean;

  /**
   * Constructor
   * Reads part of init parameters like CC visibility and volume from persistent storage
   */
  constructor(props) {
    super(props);

    const volume = persistent.get(VOLUME_KEY);
    const subtitles = persistent.get(SUBTITLES_KEY);

    this.state = {
      ad: false,
      captions: subtitles !== 'off',
      controls: true,
      edge: false,
      fullscreen: false,
      help: false,
      live: false,
      loading: true,
      preloaded: false,
      ready: false,
      playing: false,
      poster: true,
      start: null,
      speed: 1,
      volume: volume ? parseFloat(volume) : 1,
    };
  }

  /**
   * Component is mounted callback
   */
  componentDidMount(): void {
    debug('componentDidMount');
    const {
      handleKeyboard,
      handleTimeUpdate,
      listenerFullscreen,
      listenerOrientation,
      src,
      videoRef: { current: video },
    } = this;

    if (screen.orientation) {
      screen.orientation.addEventListener('change', listenerOrientation);
    } else {
      window.addEventListener('orientationchange', listenerOrientation);
    }

    window.addEventListener('keydown', handleKeyboard);

    FullscreenAPI.bind(listenerFullscreen);

    const htmlId = `player-${new Date().valueOf()}-${Math.round(Math.random() * 10000)}`;
    video.id = htmlId;

    const player: Player = videojs(video, playerOptions);
    player.autoplay(false);

    player.on('durationchange', () => {
      debug('durationchange');

      const { play, props, state } = this;

      const live = isLive(player);

      if (live !== state.live) {
        this.setState(
          ($state) => ({
            live,
            preloaded: live ? true : $state.preloaded,
            ready: live ? true : $state.ready,
          }),
          () => props.playing && live && play(),
        );
      }
    });

    player.on('loadedmetadata', () => {
      debug('loadedmetadata');

      const { state, props, play } = this;

      if (!state.ready) {
        this.setState({ ready: true, loading: props.playing }, () => {
          if (props.playing) {
            play();
          }
        });
      }
    });

    player.on('canplay', () => {
      debug('canplay');

      const { state, props } = this;
      const pos = player.currentTime();
      const start = props.start ?? 0;
      const end = props.end ?? player.duration();
      const position = Math.max(start, Math.min(end, props.position ?? props.start ?? 0));
      const fit = pos >= position - 1 && pos <= position + 1;

      // Initial seek
      if (!state.preloaded && !fit) {
        player.currentTime(position);
      } else if (fit && !state.preloaded) {
        this.setState({ preloaded: true, loading: false }, handleTimeUpdate);
      // check for position range
      } else if (pos < start - 1 || pos > end + 1) {
        player.currentTime(start);
      }
    });

    player.on('timeupdate', () => {
      if (!this.state.playing) {
        this.handleTimeUpdate();
      }
    });

    // disable temporarily, to see how it goes
    // player.on('seeking', () => this.setState({ loading: true }));
    player.on('waiting', () => this.setState({ loading: true }));
    player.on('seeked', () => this.setState({ loading: false }));

    player.on('playing', () => {
      const { reallyPlayed, state, position } = this;

      if (!reallyPlayed) {
        return;
      }

      debug('playing');

      if (state.loading) {
        this.setState({ loading: false });
      }

      if (state.poster) {
        this.setState({ poster: false });
      }

      const live = isLive(player);

      if (live && !player.liveTracker.isTracking()) {
        player.liveTracker.startTracking();
      }

      if (!state.playing) {
        this.setState(
          { playing: true },
          () => {
            const { props } = this;
            this.time = position();

            props.onState?.(true, this.time);
            handleTimeUpdate();
            this.controlsTimer = setTimeout(
              () => this.handleHideControls(),
              CONTROLS_TIMEOUT,
            );
          },
        );
      }
    });

    player.on('pause', () => {
      const { state, position, props } = this;

      if (state.start !== null) {
        this.time = position();
        debug('pause');
        props.onState?.(false, this.time);
        clearTimeout(this.timeupdateTimer);
        clearTimeout(this.controlsTimer);
        this.setState({ playing: false });

        const live = isLive(player);

        if (live && player.liveTracker.isTracking()) {
          player.liveTracker.stopTracking();
        }
      }
    });

    // init ads if google ima is loaded (may be blocked by adblocker)
    // and player ima plugin is initialized
    if (window.google?.ima && player.ima && this.props.adTagUrl) {
      debugAd(`prepare with adTagUrl "${this.props.adTagUrl}"`);

      const adReport = (type: AdEvent) => (): void => {
        this.props.onAd?.(type, this.time);
      };

      const reportStart = adReport('start');
      const reportEnd = adReport('complete');

      const continuePlay = (): void => {
        this.setState({ ad: false }, this.volume);
      };

      const adError = (e): void => {
        debugAd('error %o', e);
        continuePlay();
      };

      player.on('aderror', adError);
      player.on('ads-manager', ({ adsManager }) => {
        adsManager.addEventListener(
          window.google.ima.AdEvent.Type.STARTED,
          () => {
            // set ad volume to the same level as main video
            player.ima.controller.setVolume(this.state.volume);
            player.ima.controller.adUi.onPlayerVolumeChanged(this.state.volume);
            reportStart();
            debugAd('ad');
          },
        );
        adsManager.addEventListener(
          window.google.ima.AdEvent.Type.CLICK,
          adReport('click'),
        );
        adsManager.addEventListener(
          window.google.ima.AdEvent.Type.COMPLETE,
          () => {
            reportEnd();
            debugAd('finished');
            continuePlay();
          },
        );
        adsManager.addEventListener(
          window.google.ima.AdEvent.Type.SKIPPED,
          adReport('skip'),
        );
      });

      player.on('ads-loader', ({ adsLoader }): void => {
        debugAd('loader ready');

        adsLoader.addEventListener(
          window.google.ima.AdErrorEvent.Type.AD_ERROR,
          adError,
        );

        const { adTagUrl } = this.props;

        if (adTagUrl) {
          debugAd(`request ad ${adTagUrl}`);
          const adsRequest = new window.google.ima.AdsRequest();
          adsRequest.adTagUrl = adTagUrl;
          player.ima.setContentWithAdsRequest(null, adsRequest);
          player.ima.requestAds();
        }
      });

      player.ima(imaOptions);

      player.ima.setAdBreakReadyListener(() => {
        debugAd('got ad to play');
        this.hasAd = true;
      });
    }

    this.player = player;

    if (this.props.url) {
      src(this.props.url);
    }
  }

  /**
   * Component props or internal state updated callback
   * @param prevProps Previous component props
   * @param prevState Previous component state
   */
  componentDidUpdate(prevProps: VideoPlayerProps, prevState: VideoPlayerState): void {
    const {
      handleSeek,
      handleVolume,
      player,
      props,
      state,
      src,
    } = this;

    if (prevProps.url !== props.url) {
      this.setState({
        poster: true,
        preloaded: false,
        ready: false,
        start: null,
      }, () => src(props.url));
    } else if (prevProps.start !== props.start && typeof props.start === 'number') {
      player?.currentTime(props.start);
    }

    if (prevState.speed !== state.speed) {
      player?.playbackRate(state.speed);
    }

    if (prevProps.poster !== props.poster) {
      this.setState({ poster: true });
    }

    if (prevProps.position !== props.position && typeof props.position === 'number') {
      handleSeek(props.position);
    }

    if (prevProps.volume !== props.volume && typeof props.volume === 'number') {
      handleVolume(props.volume);
    }

    if (prevProps.playing !== props.playing && typeof props.playing === 'boolean') {
      if (props.playing) {
        this.play();
      } else {
        this.pause();
      }
    }
  }

  /**
   * Component unmount callback
   */
  componentWillUnmount(): void {
    debug('componentWillUnmount');

    clearTimeout(this.timeupdateTimer);
    clearTimeout(this.controlsTimer);

    const {
      listenerFullscreen,
      handleKeyboard,
      listenerOrientation,
      player,
    } = this;

    player?.dispose();
    this.player = null;

    if (screen.orientation) {
      screen.orientation.removeEventListener('change', listenerOrientation);
    } else {
      window.removeEventListener('orientationchange', listenerOrientation);
    }
    window.removeEventListener('keydown', handleKeyboard);
    FullscreenAPI.unbind(listenerFullscreen);
  }

  /**
   * Set player video playlist source URL
   * @param src URL to HLS stream
   */
  src = (src: string): void => {
    debug('src: %s', src);

    this.player?.src({
      src,
      type: 'application/x-mpegURL',
    });
  };

  /**
   * Toggle keyboard help
   */
  handleHelp = (): void => this.setState(({ help }) => ({ help: !help }));

  /**
   * Keyboard events handler
   * @param e Keyboard event
   */
  handleKeyboard = (e: KeyboardEvent): void => {
    const {
      handleCaptions,
      handleFaster,
      handleFullscreen,
      handleHelp,
      handleNext,
      handlePlayPause,
      handlePrevious,
      handleSeek,
      handleShowControls,
      handleSlower,
      handleVolume,
      props,
      state,
    } = this;

    const { key, target, shiftKey, ctrlKey } = e;

    if (!props.keyboard || !props.controls
        || ['INPUT', 'TEXTAREA'].indexOf((target as HTMLElement).tagName) !== -1
        || (target as HTMLElement).getAttribute('contenteditable')) {
      return;
    }

    let actionTaken = true;

    if (!props.onKeyDown?.(key)) {
      switch (key) {
        case ' ': { handlePlayPause(); break; }
        case 'ArrowLeft': { handleSeek(-(SHIFT_TIME / 1000) * (shiftKey ? 3 : 1), true); break; }
        case 'ArrowRight': { handleSeek((SHIFT_TIME / 1000) * (shiftKey ? 3 : 1), true); break; }
        case 'ArrowUp': { handleFaster(); break; }
        case 'ArrowDown': { handleSlower(); break; }
        case 'Tab': { handleShowControls(); break; }
        case '=':
        case '+': { handleVolume(Math.min(1, state.volume + 0.1)); break; }
        case '-': { handleVolume(Math.max(0, state.volume - 0.1)); break; }
        case 'c': { handleCaptions(); break; }
        case 'f': {
          if (!ctrlKey) {
            handleFullscreen();
          } else {
            actionTaken = false;
          }
          break;
        }
        case '.':
        case '>': { handleNext(); break; }
        case ',':
        case '<': { handlePrevious(); break; }
        case '?': { handleHelp(); break; }
        case 'End': { handleSeek(Number.POSITIVE_INFINITY); break; }
        case 'Home': { handleSeek(0); break; }
        default: actionTaken = false;
      }
    }

    if (actionTaken) {
      e.preventDefault();
      e.stopImmediatePropagation();
    }
};

  /**
   * Handle calls for faster playback
   */
  handleFaster = (): void => {
    const { state } = this;
    if (state.playing && state.speed < MAX_SPEED) {
      this.setState({ speed: state.speed * 2 });
    }
  };

  /**
   * Handle calls for slower playback
   */
  handleSlower = (): void => {
    const { state } = this;
    if (state.playing && state.speed > MIN_SPEED) {
      this.setState({ speed: state.speed - state.speed / 2 });
    }
  };

  /**
   * Handles calls for player state change
   * If first argument is boolean, then works as force player state trigger
   * Otherwise toggles current state
   */
  handlePlayPause = (state?: unknown): void => {
    const { player, play, pause } = this;
    const paused = player.paused();

    if (typeof state === 'boolean' ? state === true : paused) {
      play();
    } else if (typeof state === 'boolean' ? state === false : !paused) {
      pause();
    }
  };

  /**
   * Handle calls for changing fullscreen mode
   */
  handleFullscreen = async (): Promise<void> => {
    if (FullscreenAPI.enabled()) {
      if (!this.state.fullscreen) {
        try {
          await FullscreenAPI.request(this.rootRef.current);
        } catch (e) {
          // ignore
        }
      } else {
        FullscreenAPI.exit();
      }
    }
  };

  /**
   * Handle volume changes
   * @param volume value between 0 and 1
   */
  handleVolume = (volume: number): void => {
    persistent.set(VOLUME_KEY, String(volume));
    this.setState({ volume }, this.volume);
  };

  /**
   * Seek video to desired position
   * @param shift Seek value in seconds, relative or absolute depending on `relative` parameter
   * @param relative Treat `shift` value as relative or absolute
   * @returns true if seek has been performed
   */
  handleSeek = (shift: number, relative = false): boolean => {
    debug('handleSeek %d %s', shift, relative);

    if (this.state.ready) {
      const { time, props, state, player, duration } = this;

      if (state.live && shift === Number.POSITIVE_INFINITY) {
        player.liveTracker.seekToLiveEdge();
        return true;
      }

      const start = typeof props.start === 'number' ? props.start : 0;
      const end = typeof props.end === 'number' ? props.end : duration();
      const abs = relative ? time + shift : shift;
      const pos = Math.min(end, Math.max(abs, start));

      if (Math.abs(time - pos) > 0.5) {
        player.currentTime(pos);
        return true;
      }
    }

    return false;
  };

  /**
   * Rewind back for SHIFT_TIME seconds
   * @returns true if successful
   */
  handleRewind = (): boolean => this.handleSeek(-SHIFT_TIME / 1000, true);

  /**
   * Move video forward for SHIFT_TIME value
   * @returns true if successful
   */
  handleForward = (): boolean => this.handleSeek(SHIFT_TIME / 1000, true);

  /**
   * Handles periodic state updates during playback like ad breaks, fade and loop.
   */
  handleTimeUpdate = (): void => {
    const {
      duration,
      handleTimeUpdate,
      hasAd,
      pause,
      player,
      position,
      props,
      state,
      volume,
    } = this;

    if (state.ready && !state.ad) {
      this.time = Math.round(
        position() * POSITION_UPDATE_MAXWAIT,
      ) / POSITION_UPDATE_MAXWAIT;

      if (state.playing) {
        if (hasAd && player.ima?.playAdBreak) {
          debugAd('play ad break');
          this.hasAd = false;
          this.setState({ ad: true }, () => {
            player.ima.initializeAdDisplayContainer();
            player.ima.playAdBreak();
          });
        // somehow this remains active even after clearTimeout in WillUnmount
        // so let's check explicitly
        } else if (this.player) {
          this.timeupdateTimer = setTimeout(handleTimeUpdate, POSITION_UPDATE_MAXWAIT);
        }
      }

      if (!state.live) {
        volume();
      }

      // do check for loop/next only when in playing mode
      if (state.playing && typeof props.end === 'number' && this.time >= props.end) {
        props.onEnd?.(this.time);

        if (props.loop) {
          const pos = typeof props.start === 'number' ? props.start : 0;
          player.currentTime(pos);
        } else {
          pause();
        }
      }

      if (state.live && !props.end && !props.start) {
        const edge = player.liveTracker.atLiveEdge();
        if (state.edge !== edge) {
          this.setState({ edge });

          if (edge && state.speed !== 1) {
            player.playbackRate(1);
          }
        }
      }

      if (props.onPosition && this.state.preloaded) {
        props.onPosition(this.time, duration(), state.live ? state.edge : null);
      }
    }
  };

  /**
   * Handle clicks on `previous` button
   */
  handlePrevious = (): void => (
    typeof this.props.onPrevious === 'function' && this.props.onPrevious(this.time)
  );

  /**
   * Handle clicks on `next` button
   */
  handleNext = (): void => (
    typeof this.props.onNext === 'function' && this.props.onNext(this.time)
  );

  /**
   * Handles call for changing controls to visible state
   */
  handleShowControls = (): void => {
    clearTimeout(this.controlsTimer);

    this.setState({ controls: true });

    if (this.state.playing) {
      this.controlsTimer = setTimeout(
        () => this.handleHideControls(),
        CONTROLS_TIMEOUT,
      );
    }
  };

  /**
   * Handles calls for changing controls to hidden state
   */
  handleHideControls = (): void => {
    clearTimeout(this.controlsTimer);
    this.setState({ controls: false });
  };

  /**
   * Toggles closed captions visibility
   */
  handleCaptions = (): void => {
    this.setState(({ captions }) => {
      persistent.set(SUBTITLES_KEY, captions ? 'off' : 'on');
      return { captions: !captions };
    });
  };

  /**
   * Fullscreen event listener, responsible for toggling fullscreen off
   * when ESC or F11 are pressed.
   */
  listenerFullscreen = (): void => {
    this.setState({ fullscreen: Boolean(FullscreenAPI.isFullscreen()) });
  };

  /**
   * Handles display orientation change
   * Used for switching full screen mode when mobile device is rotated during playback
   */
   listenerOrientation = async (): Promise<void> => {
    const { allowFullscreen } = this.props;
    const { fullscreen, playing } = this.state;

    if (allowFullscreen && FullscreenAPI.enabled()) {
      const landscape = getOrientationAngle() % 180 !== 0;
      debug('orientation changed, landscape: %s', landscape);

      if (playing && !fullscreen && landscape) {
        try {
          await FullscreenAPI.request(this.rootRef.current);
        } catch (e) {
          // ignore
        }
      } else if (fullscreen && !landscape) {
        FullscreenAPI.exit();
      }
    }
  };

  /**
   * Call player to pause video, should be used as main control across component
   * for changing player state
   */
  pause = (): void => {
    const { player, state } = this;

    if (state.playing) {
      player.pause();
    }
  };

  /**
   * Call player to play video, use this method for start video play from
   * inside component
   */
  play = async (): Promise<void> => {
    debug('play: start');

    const { player, props, state, duration, position } = this;

    // sometimes playing state is still true but player is actually
    // paused, this may happen on src change
    if (state.playing && !player.paused()) {
      return;
    }

    const handleSuccessPlay = (): void => {
      if (state.start === null) {
        this.setState({ start: new Date().valueOf(), poster: false });

        if (state.live && !this.props.end && !this.props.start) {
          player.liveTracker.seekToLiveEdge();
        }
      }

      debug('play: succeeded');
    };

    try {
      // safari workaround, sometimes even if player reports
      // it's status as paused, play() may not start playing
      // but preventive call to pause() fixes this bug
      player.pause();

      this.volume();
      this.reallyPlayed = true;

      this.setState({ loading: true });

      // Handle play start close to the end
      const start = props.start ?? 0;
      const end = props.end ?? duration();

      if (end - position() < 1) {
        player.currentTime(start);
      }

      await player.play();

      handleSuccessPlay();
    } catch (e) {
      const text = e.toString();

      if (text.includes('request was interrupted by a call to pause')) {
        handleSuccessPlay();
        return;
      }

      this.reallyPlayed = false;
      this.setState({ loading: false });

      // report to DD
      if (e instanceof DOMException || e.message.includes('NotAllowedError:')) {
        logger.warn(`${e.name}: ${e.message}`);
      } else {
        logger.error(e.message, e);
      }
    }
  };

  /**
   * Changes sound volume during playback, also includes fade control
   */
  volume = (): void => {
    const { state, props, time = 0, player, duration } = this;
    let fade = 1;

    if (!state.ad) {
      if (props.fade === true) {
        const start = props.start ?? 0;
        const end = props.end ?? duration() ?? 0;
        const fadeTime = Math.min(FADE_TIME / 1000, (end - start) / 2);

        // out of destination range
        if (time <= start || time >= end) {
          fade = 0;
        // increasing from 0 to 1
        } else if (time > start && time < start + fadeTime) {
          fade = Math.max(0, (time - start) / fadeTime);
        // decreasing from 1 to 0
        } else if (time > end - fadeTime && time < end) {
          fade = Math.max(0, (end - time) / fadeTime);
        }
      } else if (typeof props.fade === 'function') {
        fade = props.fade(time, FADE_TIME);
      }

      fade = Math.max(fade, MIN_FADE);
    }

    const vol = state.volume * fade;

    if (player && vol !== player.volume()) {
      player.volume(vol);
    }
  };

  /**
   * Video position getter
   */
  position = (): number => {
    const {
      state: { live, edge, playing },
      props: { start, end },
      player,
    } = this;

    return (live && edge && playing && !(typeof start === 'number' && typeof end === 'number')
      ? player?.liveTracker.liveCurrentTime()
      : player?.currentTime()) ?? 0;
  };

  /**
   * Video duration getter
   */
  duration = (): number => (this.state.live
    ? this.player?.liveTracker.liveCurrentTime()
    : this.player?.duration()) ?? 0;

  /**
   * Creates current frame preview in jpeg format
   * @returns base64 encoded image as data URL
   */
  // eslint-disable-next-line react/no-unused-class-component-methods
  public image = (): string => {
    try {
      const { offsetHeight: height, offsetWidth: width } = this.videoRef.current;
      const canvas = document.createElement('canvas');
      canvas.width = width;
      canvas.height = height;
      const ctx = canvas.getContext('2d');
      ctx.drawImage(this.videoRef.current, 0, 0, width, height);
      return canvas.toDataURL('image/jpeg');
    } catch (e) {
      // nothing
    }
  };

  /**
   * Main rendering method
   */
  render(): React.ReactNode {
    const {
      duration,
      handleCaptions,
      handleFaster,
      handleForward,
      handleFullscreen,
      handleHelp,
      handlePlayPause,
      handleRewind,
      handleSeek,
      handleSlower,
      handleShowControls,
      handleVolume,
      position,
      props,
      rootRef,
      state,
      videoRef,
    } = this;

    const played = state.start !== null;

    // eslint-disable-next-line react/jsx-no-constructed-context-values
    const value = {
      captions: state.captions,
      controls: state.controls,
      duration,
      edge: state.edge,
      end: props.end,
      fullscreen: state.fullscreen,
      help: state.help,
      handleCaptions: props.vtt ? handleCaptions : null,
      handleForward,
      handleFaster: state.speed < MAX_SPEED && !state.edge ? handleFaster : null,
      handleFullscreen: props.allowFullscreen && FullscreenAPI.enabled() ? handleFullscreen : null,
      handleHelp,
      handlePlayPause,
      handleRewind,
      handleSeek,
      handleSlower: state.speed > MIN_SPEED && !state.edge ? handleSlower : null,
      handleVolume,
      live: state.live,
      playing: state.playing,
      position,
      speed: state.speed,
      start: props.start,
      volume: state.volume,
    };

    return (
      <VideoPlayerContext.Provider value={value}>
        <div
          ref={rootRef}
          className={cx(
            s.root,
            state.fullscreen && s.fullscreen,
          )}
          role="none"
          data-test-id="videoplayer-root"
        >
          <div data-vjs-player className={s.video}>
            {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
            <video
              // autoPlay
              crossOrigin="anonymous"
              ref={videoRef}
              className="video-js vjs-default-skin"
              // do not remove this attribute, it is vital for
              // ads correct work on iOS
              playsInline
              disablePictureInPicture
              data-test-id="videoplayer-video"
            >
              {/* be careful, safari is not firing `canplay` event if props.vtt is empty */}
              {props.vtt && state.captions && (
                <track
                  src={props.vtt}
                  kind="captions"
                  srcLang="en"
                  label="English"
                  default
                />
              )}
            </video>
            {props.poster && (
              <img
                data-test-id="videoplayer-poster"
                className={cx(s.poster, state.ready && !state.poster && s.hidden)}
                src={props.poster}
                alt={props.title || ''}
                aria-hidden
              />
            )}
            {props.children && (
              <div className={s.children} style={this.ratio}>
                {props.children}
              </div>
            )}
          </div>

          {state.ready && props.controls && (
            // eslint-disable-next-line max-len
            // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
            <div
              className={cx(s.controlsRoot, state.controls && s.visible)}
              onMouseMove={this.handleShowControls}
              onMouseLeave={this.handleHideControls}
              onClick={this.handleShowControls}
            >
              {hasTouch && (
                <>
                  <TapButton
                    aria-hidden
                    className={s.rwdTap}
                    flash="double"
                    onSingleTap={handleShowControls}
                    onDoubleTap={handleRewind}
                    data-test-id="videoplayer-controls-rwd"
                  >
                    <RewindTap />
                  </TapButton>

                  <TapButton
                    aria-hidden
                    className={s.fwdTap}
                    flash="double"
                    onSingleTap={handleShowControls}
                    onDoubleTap={handleForward}
                    data-test-id="videoplayer-controls-fwd"
                  >
                    <ForwardTap />
                  </TapButton>
                </>
              )}

              {props.controls && (
                <div className={s.controls}>
                  {played && (
                    <>
                      {props.onPrevious && (
                        <button
                          aria-hidden="false"
                          title="Previous"
                          className={cx(s.button, s.prev)}
                          data-test-id="prev-button"
                          onClick={this.handlePrevious}
                          type="button"
                        >
                          <SkipPrevious />
                        </button>
                      )}

                      {props.onNext && (
                        <button
                          aria-hidden="false"
                          title="Next"
                          className={cx(s.button, s.next)}
                          data-test-id="next-button"
                          onClick={this.handleNext}
                          type="button"
                        >
                          <SkipNext />
                        </button>
                      )}
                    </>
                  )}
                  <PlayPauseButton
                    playing={state.playing}
                    onClick={handlePlayPause}
                  />
                  {props.controls !== true && played && props.controls}
                </div>
              )}
            </div>
          )}

          {props.loader && (
            <div className={cx(s.loader, !played && s.big, state.loading && s.loading)}>
              <span>Video is loading...</span>
            </div>
          )}

          {state.help && <VideoPlayerHelp {...props} />}
        </div>
      </VideoPlayerContext.Provider>
    );
  }
}
