import {
  ReactComponent as SubtitlesOn,
} from '@mdi/svg/svg/subtitles-outline.svg';
import cx from 'classnames';
import * as React from 'react';

import * as s from './CaptionsControlButton.m.less';
import { ReactComponent as SubtitlesOff } from './subtitles-outline-off.svg';
import { CaptionsControlButtonProps } from './types';

export const CaptionsControlButton: React.FC<
  CaptionsControlButtonProps
> = function CaptionsControlButton({
  className,
  enabled,
  ...props
}) {
  return (
    <button
      className={cx(s.root, className)}
      title="Toggle subtitles"
      type="button"
      {...props}
    >
      {enabled ? (
        <SubtitlesOn />
      ) : (
        <SubtitlesOff />
      )}
    </button>
  );
};
