import {
  Collection, EmbedAdsSpec, ID, Moment, User,
} from 'weplayed-typescript-api';

import { AspectRatio, CropBox } from 'common/types';
import { TemplateProperty } from 'common/utils/overlay';

/**
 * Available player modes
 */
export enum PlayerMode {
  /**
   * Moment edit mode
   */
  MOMENT_EDIT = 'moment.edit',

  /**
   * Moment clone mode
   */
  MOMENT_CLONE = 'moment.clone',

  /**
   * Collection edit mode
   */
  COLLECTION_EDIT = 'collection.edit',

  /**
   * Collection clone mode
   */
  COLLECTION_CLONE = 'collection.clone',
}

/**
 * Mapping between moment ID and appropriate crop box
 */
type CropBoxes = Record<ID, CropBox>;

/**
 * Graphical overlay settings
 */
export interface OverlaySettings {
  /**
   * Image to apply over export
   */
  image: string;

  /**
   * Number of seconds to show overlay
   */
  image_end?: number;

  /**
   * Template path
   */
  template: string;

  /**
   * Settings applied to template
   */
  settings: TemplateProperty[],
}

/**
 * Export settings
 */
export interface ExportSettings {
  /**
   * Aspect ratio
   */
  ratio: AspectRatio,

  /**
   * Moments crop boxes
   */
  boxes: CropBoxes,

  /**
   * Export overlay settings
   */
  overlay: OverlaySettings;
}

/**
 * Popup Player properties
 */
export interface PopupPlayerProps {
  /**
   * Collection ID
   * @default null
   */
  collectionId?: ID;

  /**
   * Disable control
   * @default false
   */
  disabled?: boolean;

  /**
   * Moment/Collection export settings
   */
  exportSettings?: ExportSettings;

  /**
   * Player mode
   * @default null
   */
  mode?: PlayerMode;

  /**
   * Moment ID
   * @default null
   */
  momentId?: ID;

  /**
   * Crop box set for the moment callback
   * @param cropBox Current moment crop box
   * @param separate The dict of individual crop boxes per moment
   *                 (both moments and collection modes)
   */
  onExportSettings?(settings: ExportSettings): void;

  /**
   * Cancel button press callback, for both edit and crop box modes
   */
  onCancel?(): void;

  /**
   * Popup close callback
   */
  onClose(): void;

  /**
   * Crop box done button press callback
   */
  onDone?(): void;

  /**
   * Collection like (add to favorites) callback
   * @param collection Collection
   * @param like like (add to favorites) / unlike (remove from favorites)
   */
  onCollectionLike?(collection: Collection, like: boolean): void;

  /**
   * Request for link button press callback
   * @param collection Collection
   * @param target HTML element which raises event (used for bounding additional UI elements)
   */
  onCollectionLink?(collection: Collection, target: HTMLElement): void;

  /**
   * Request for collection menu callback
   * @param collection Collection
   * @param target HTML element which raises event
   */
  onCollectionMenu?(collection: Collection, target: HTMLElement): void;

  /**
   * Moment edit callback
   * @param moment Moment
   */
  onMomentEdit?(moment: Moment): void;

  /**
   * Moment like (add to favorites) callback
   * @param moment Moment
   * @param like like (add to favorites) / unlike (remove from favorites)
   */
  onMomentLike?(moment: Moment, like: boolean): void;

  /**
   * Request for link button press callback
   * @param moment Moment
   * @param target HTML element which raises event
   */
  onMomentLink?(moment: Moment, target: HTMLElement): void;

  /**
   * Request for moment menu callback
   * @param moment Moment
   * @param target HTML element which raises event
   */
  onMomentMenu?(moment: Moment, target: HTMLElement): void;

  /**
   * Moment save callback
   * @param moment Updated moment
   */
  onMomentSave?(moment: Moment): void;

  /**
   * Current user
   */
  user: User;
}

/**
 * Moment player Properties
 */
export interface MomentPlayerProps {
  /**
   * Disable all controls
   * @default false
   */
  disabled?: boolean;

  /**
   * Is in moment edit mode
   */
  edit: boolean;

  /**
   * Show/hide moment info
   * @default true
   */
  info?: boolean;

  /**
   * Moment to show/edit
   */
  moment: Moment<EmbedAdsSpec>;

  /**
   * Edit/crop box action cancel callback
   */
  onCancel?(): void;

  /**
   * Callback called when moment hits playing end
   */
  onEnd?(): void;

  /**
   * Callback of moment boundaries change event
   * @param moment Moment with updated boundaries/description etc
   */
  onMomentUpdate?(moment: Moment): void;

  /**
   * Next button press event callback
   * @param time Position of video when next event has been fired
   */
  onNext?(time: number): void;

  /**
   * Previous button press event callback
   * @param time Position of video when next event has been fired
   */
  onPrevious?(time: number): void;

  /**
   * Moment save callback
   * @param moment Updated moment
   */
  onSave?(moment: Moment): void;

  /**
   * Overlay passed to base player
   */
  overlay?: JSX.Element;

  /**
   * Pause playback
   * @default false
   */
  paused?: boolean;
}

export interface CropBoxOverlayProps {
  ratio: AspectRatio;
  cropBox?: CropBox | null;
  onChange(box: CropBox): void;
}

export interface OverlayPropsProps {
  props: TemplateProperty[];
  onChange(props: TemplateProperty[]): void;
}
