import { SVGAttributes } from 'react';

import { Base } from './base';
import { Image } from './image';
import { Text } from './text';
import {
  ItemBounds, ItemBox, OverlayProps, TemplateItemBase, TemplateItemBlock,
  TemplateItemImage, TemplateItemText,
} from './types';
import { getLength, getPadding } from './utils';

/**
 * Block template item rendering class
 */
export class Block extends Base<TemplateItemBlock> {
  /**
   * Child item instances
   */
  protected items: Base<TemplateItemBase>[];

  /**
   * Block constructor
   * @param root Overlay (root) instance
   * @param path Current item JSON path
   * @param item Block template item
   */
  constructor(
    root: OverlayProps,
    path: string,
    item: TemplateItemBlock,
  ) {
    super(root, path, item);

    // create children items
    this.items = item.items.map((i, idx) => {
      const $path = this.path(`items[${idx}]`);
      if ('text' in i) {
        return new Text(
          root,
          $path,
          i as TemplateItemText,
        ) as Base<TemplateItemBase>;
      }

      if ('url' in i) {
        return new Image(
          root,
          $path,
          i as TemplateItemImage,
        ) as Base<TemplateItemBase>;
      }

      return new Block(root, $path, i) as Base<TemplateItemBase>;
    });
  }

  /**
   * Measure block size assuming all children elements
   * @param parent Parent box
   * @returns Box dimensions
   */
  public measure(parent: ItemBox): ItemBounds {
    const w = getLength(this.prop('w') ?? '100w', parent);
    const h = getLength(this.prop('h') ?? '100h', parent);

    const bbox = {
      w, h, ...this.position(parent, { w, h }),
    };

    // A tricky one.
    // If no width or height block is provided we need to measure
    // children sizes and then calculate minimal value
    if (this.items?.length && (!this.item.w || !this.item.h)) {
      const pbox = this.pbox(parent, bbox);
      // get the max height/width of all children in relative to
      // current bbox
      const [ww, hh] = this.items.reduce(
        (p, item) => {
          const bb = item.measure(pbox).bbox;
          return [Math.max(p[0], bb.w), Math.max(p[1], bb.h)];
        },
        [-Number.POSITIVE_INFINITY, -Number.POSITIVE_INFINITY],
      );

      // get padding box values (values are always relative to
      // the parent bbox)
      const padding = getPadding(this.item, parent);

      // set size if it is not known
      if (!this.item.w) {
        bbox.w = ww + padding[1] + padding[3];
      }

      if (!this.item.h) {
        bbox.h = hh + padding[0] + padding[2];
      }

      const { x, y } = this.position(parent, bbox);
      bbox.x = x;
      bbox.y = y;
    }

    return { bbox, pbox: this.pbox(parent, bbox) };
  }

  /**
   * Calculate SVG attributes for <path> or <rect> depending on
   * this.item.path value
   * @param box Box to render in
   * @returns SVG attributes
   */
  protected svgAttrs(box: ItemBox): SVGAttributes<SVGElement> {
    const attrs = super.svgAttrs(box);
    const { x, y, w, h } = box;

    // special case, path is set to `rect`
    if (this.item.path === 'rect') {
      attrs.x = x;
      attrs.y = y;
      attrs.width = w;
      attrs.height = h;

      if (this.item.radius) {
        const radius = getLength(this.prop('radius'), box);
        attrs.rx = radius;
        attrs.ry = radius;
      }
    } else if (this.item.path) {
      // transform path relative values into real coordinates
      attrs.d = this.item.path?.replace(
        /%(-?\d+(?:\.\d+)?)([xywh])/g,
        (_, value, type): string => {
          // eslint-disable-next-line no-nested-ternary
          const m = type === 'h' ? h : type === 'w' ? w : type === 'x' ? x : y;
          return String((parseFloat(value) * m) / 100);
        },
      );
    }

    return attrs;
  }

  /**
   * Render block item
   * @param parent Box to fit in
   * @returns Block SVG content
   */
  render(parent: ItemBox): React.ReactNode {
    const { pbox } = this.measure(parent);
    const attrs: SVGAttributes<SVGPathElement> = this.svgAttrs(pbox);

    return (
      <>
        {attrs.d && <path {...attrs} />}
        {attrs.width && <rect {...attrs} />}
        {...this.items.map((i) => i.render(pbox))}
      </>
    );
  }
}
