import { ReactComponent as Help } from '@mdi/svg/svg/help-circle.svg';
import * as cx from 'classnames';
import * as React from 'react';

import * as s from './SectionNav.m.less';
import { SectionNavItem } from './SectionNavItem';
import { SectionNavProps } from './types';

export const SectionNav: React.FC<SectionNavProps> = function SectionNav({
  children,
  className,
  disabled,
  help,
  underline = true,
}) {
  const helpRef = React.useRef<HTMLDivElement>(null);
  const [helpStyle, toggleShowHelp] = React.useReducer(
    (state: React.CSSProperties | null): React.CSSProperties | null => (
      state ? null : {
        maxHeight: `${helpRef.current?.scrollHeight}px`,
      }),
    null,
  );

  return (
    <>
      <ul className={cx(s.root, className, underline && s.underline, disabled && s.disabled)}>
        {children}
        {help && (
          <SectionNavItem
            active={Boolean(helpStyle)}
            className={s.help}
            onClick={toggleShowHelp}
          >
            <Help />
            Help
          </SectionNavItem>
        )}
      </ul>
      <div
        ref={helpRef}
        style={helpStyle}
        className={s.helpContainer}
      >
        <div className={s.helpContent}>
          {help}
        </div>
      </div>
    </>
  );
};
