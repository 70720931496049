import {
  curry, fromPairs, isNull, isUndefined, map, omitBy, pipe, toPairs,
} from 'lodash/fp';
import { BaseEntityWithPk, ID } from 'weplayed-typescript-api';

export const preventDefault = (e: { preventDefault(): void }): void => e.preventDefault();

export const ucFirst = (text: string): string => `${text.slice(0, 1).toUpperCase()}${text.slice(1)}`;

export const trimObject = <T>(
  fields: Array<StringKeys<T>>,
  obj: T,
): T => pipe(
  toPairs,
  map(
    (pair: string[]): string[] => (
      fields.includes(pair[0] as StringKeys<T>)
        ? [pair[0], pair[1].trim()]
        : pair),
  ),
  fromPairs,
)(obj);

export const getTransitionEndEventName = (): string => {
  const transitions = {
    transition: 'transitionend',
    OTransition: 'oTransitionEnd',
    MozTransition: 'transitionend',
    WebkitTransition: 'webkitTransitionEnd',
  };
  const { style } = document.body;

  return Object.entries(transitions).find(([prop]) => style[prop] !== undefined)?.pop();
};

export const pkify = ({ pk }: BaseEntityWithPk): ID => pk;

export const pickByPk: (search: ID) => (entity: BaseEntityWithPk) => boolean = curry(
  (search: ID, { pk }: BaseEntityWithPk) => pk === search,
);

export const omitNullUndef: (
  v: Record<string, unknown>,
) => Record<string, unknown> = omitBy((v) => isNull(v) || isUndefined(v));
