/* eslint-disable react-hooks/exhaustive-deps */
import { uniq } from 'lodash/fp';
import * as React from 'react';
import { exprts, ID } from 'weplayed-typescript-api';

import { useApplication } from 'common/hooks/useApplication';

import { INTERVAL } from './constants';
import { reducer } from './reducer';
import { ExportsContextType, UseCreateExportsContextType } from './types';

export const useCreateExportsContext:
UseCreateExportsContextType = function useCreateExportsContext() {
  const [state, dispatch] = React.useReducer(reducer, []);
  const timer = React.useRef<ReturnType<typeof setInterval>>(null);
  const pending = React.useRef<ID[]>([]);
  const { events } = useApplication();

  // cleanup state on logout
  React.useEffect(() => {
    const listener = (): void => dispatch({ action: 'purge' });
    events.addEventListener('logout', listener);

    return (): void => events.removeEventListener('logout', listener);
  }, []);

  pending.current = uniq(
    state.filter(({ url, errors }) => !url && !errors).map(({ jobUid }) => jobUid),
  );

  // Internal update timer flag
  const enabled = Boolean(pending.current.length);

  // Toggle on/off state update timer
  React.useEffect(() => {
    if (enabled) {
      timer.current = setInterval(async () => {
        const update = await exprts.status({ uid: pending.current });
        dispatch({ action: 'update', state: update });
      }, INTERVAL);

      return (): void => {
        clearInterval(timer.current);
      };
    }
  }, [enabled]);

  // Create download export task
  const download: ExportsContextType['download'] = React.useCallback(
    async (type, uid, email = true) => {
      const notification = [exprts.NotificationType.DOWNLOAD];

      if (email) {
        notification.push(exprts.NotificationType.EMAIL);
      }

      const result = await exprts.create({ uid, type, notification });
      dispatch({ action: 'update', state: [result] });
    },
    [],
  );

  // Create share export task
  const share: ExportsContextType['share'] = React.useCallback(
    async (type, uid, target, description, settings) => {
      const notification = [exprts.NotificationType.SOCIAL];

      const result = await exprts.create({
        uid,
        type,
        notification,
        share: { target, description },
        settings,
      });
      dispatch({ action: 'update', state: [result] });
    },
    [],
  );

  // Dismiss particular job/uid combination from state
  const dismiss: ExportsContextType['dismiss'] = React.useCallback(
    (jobUid, uid) => dispatch({ action: 'dismiss', jobUid, uid }),
    [],
  );

  // Clear state
  const clear: ExportsContextType['clear'] = React.useCallback(
    (notification: exprts.NotificationType) => dispatch({ action: 'clear', notification }),
    [],
  );

  return {
    clear,
    dismiss,
    download,
    share,
    state,
  };
};
