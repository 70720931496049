import 'cms/styles/index.less';

import * as React from 'react';
import { hot } from 'react-hot-loader/root';
import { BrowserRouter } from 'react-router-dom';
import * as topbar from 'topbar';

import { ApplicationProvider } from 'common/components/ApplicationProvider';
import { ErrorBoundary } from 'common/components/ErrorBoundary';

import { CMS } from 'cms/containers/CMS';
import * as s from 'cms/styles/exports.m.less';

topbar.config({ barColors: { 0: s.accent, 1: s.primary } });

const loader = (on: boolean): void => (on ? topbar.show() : topbar.hide());

export const App: React.FC = hot(() => (
  <BrowserRouter basename={process.env.URL_PREFIX}>
    <ErrorBoundary>
      <ApplicationProvider loader={loader}>
        <CMS />
      </ApplicationProvider>
    </ErrorBoundary>
  </BrowserRouter>
));
