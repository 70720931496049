import cx from 'classnames';

import { HTMLSwitch } from 'cms/components/HTMLSwitch';

import * as s from './Controls.m.less';
import { BlockProps } from './types';

export const Block = function Block({
  className,
  block,
  disabled,
  hidden,
  label,
  loading,
  onClick,
}: BlockProps): JSX.Element {
  return (
    <HTMLSwitch
      checked={!block}
      className={cx(s.block, className, hidden && s.hidden)}
      disabled={disabled}
      label={label && block && 'Published'}
      loading={loading}
      onClick={onClick}
    />
  );
};
